import { showInfo, ot } from 'utils';
import { ERROR } from 'constants';
import { SET_ORDER_DATA, MERGE_DATA, FETCH_ORDER_DATA, SELECT_COR_SUG } from '../../constant';
import { getDistance } from '../../tool';
import { convertSugToMemberData } from '../../fee/collect/tool';
import { setAttachments } from '../tool';

function onChange(page, store, next, key, val) {
  const state = store.getState();
  switch (key) {
    case 'rcvStn': {
      const p1 = state.corAddrInfo && state.corAddrInfo.poi;
      const p2 = val.poi;
      const { mileRatio } = state;
      getDistance(p1, p2, mileRatio).then(distance => {
        (state.corPickDist || distance) &&
          store.dispatch({ type: SET_ORDER_DATA, payload: { key: 'corPickDist', val: distance } });
      });
      break;
    }
    case 'corAddrInfo': {
      const companyPoi = state.companyInfo && state.companyInfo.address && state.companyInfo.address.poi;
      const rcvStnPoi = state.rcvStn ? state.rcvStn.poi : '';
      const p1 = val.poi;
      const p2 = state.rcvStnShow ? rcvStnPoi || companyPoi : companyPoi;
      const { mileRatio } = state;
      const { address_filling } = window.company_setting;
      getDistance(p1, p2, mileRatio).then(distance => {
        (state.corPickDist || distance) &&
          store.dispatch({ type: SET_ORDER_DATA, payload: { key: 'corPickDist', val: distance } });
      });
      // 如果系统设置勾选并且发站没有编辑过，将发货地址的值带入到发站中
      if (state.startInfoShow && !page.edited('start') && address_filling?.values?.includes('start')) {
        page.set('startInfo', val);
      }
      break;
    }
    case 'pickup': {
      // 提货必填发货地址
      !state.corAddrInfoSettingRequired &&
        state.needCorAddress &&
        store.dispatch({ type: MERGE_DATA, payload: { corAddrInfoRequired: !!val } });
      break;
    }
    case 'coPickupDate': {
      if (val) {
        const coPickupDate = val.split(',');
        const time1 = new Date(coPickupDate[0]);
        const time2 = new Date(coPickupDate[1]);
        const clearVal = () => setTimeout(() => page.set('coPickupDate', ''));
        if (time1 > time2) {
          showInfo(ERROR, '预约提货开始时间不能大于结束时间');
          clearVal();
        } else if (time1.getDay() !== time2.getDay()) {
          showInfo(ERROR, `${ot('预约提货时间')}不能跨天`);
          clearVal();
        }
      }
      break;
    }
    default:
      break;
  }
}

const consignorMiddleware = page => store => next => action => {
  switch (action.type) {
    case SET_ORDER_DATA:
      onChange(page, store, next, action.payload.key, action.payload.val);
      break;
    case FETCH_ORDER_DATA: {
      setTimeout(() => {
        const state = store.getState();
        if (state.isCreate && state.corAddrInfo && state.corAddrInfo.poi) {
          page.set('corAddrInfo', state.corAddrInfo);
        }
        if (state.isCreate && state.corMaintain) {
          page.set('corMaintain', state.corMaintain);
        }
      });
      break;
    }
    case SELECT_COR_SUG: {
      const { data } = action.payload;
      const state = store.getState();
      const { set } = page;
      const { merge } = page;
      // 特殊处理
      const mebKeys = ['cardHolder', 'memberCode', 'contactPhone', 'bankCardNum'];
      // http://w-luodp.chemanman.com:6005//Frontend/hongyan/yundan/start.html#g=1&p=%E5%BC%80%E5%8D%95%E9%A1%B5%E9%80%89%E4%BC%9A%E5%91%98%E5%8F%B7%E4%B8%8D%E6%9B%B4%E6%96%B0%E5%8F%91%E8%B4%A7%E4%BA%BA
      const isSpecialGroupId = [2078, 1000, 226].includes(+window.group_id);
      // 发货人为空 或者 是不需要特殊处理的集团且key不是memberCode 时更新cor
      const canSetCor =
        !state.corName || (isSpecialGroupId && !mebKeys.includes(action.payload.key)) || !isSpecialGroupId;
      // 非特殊处理集团 或 key 等于 会员相关字段 时更新会员
      const canSetMem = !isSpecialGroupId || mebKeys.includes(action.payload.key);
      if (canSetCor) {
        const frequentGoods = data.ext && JSON.parse(data.ext);
        const corMaintainCalc = state.basicSetting.cor_maintain_calc || {};
        const maintain = data.maintain_fee;
        // eslint-disable-next-line no-nested-ternary
        const parsedMaintain = Array.isArray(maintain) ? maintain : maintain ? JSON.parse(maintain) || [] : [];
        const alarmMoney = (data.alarm_money === '0.00' ? '' : data.alarm_money) || '';
        const lockMoney = (data.lock_money === '0.00' ? '' : data.lock_money) || '';
        const hasMaintainRule =
          data.maintain_rule &&
          ((+data.maintain_rule === 2 && data.maintain_ratio) ||
            (+data.maintain_rule === 1 && parsedMaintain.some(Boolean)));
        set('corId', data.id || '');
        set('corCate', data.type);
        merge('corCateStr', data.type_name);
        set('corIsMonth', data.is_month === '是');
        set('corName', data.name || '');
        set('corMobile', data.telephone || '');
        set('corAddrInfo', data.address || '');
        set('corCom', data.customer_cname || '');
        set('corPhone', data.phone || '');
        set('corIdNum', data.payee_id_card || '');
        set('corCustomerNo', data.customer_no || '');
        set('corIndustry', data.industry || '');
        set('corAddrRemark', data.address_remark || '');
        set('corRemark', data.remark || '');
        set('corMaintainByFormula', +(hasMaintainRule ? data.maintain_rule : corMaintainCalc.maintain_rule) === 2);
        // 使用 单价 计算时跟新 单价 触发维护费
        set('corMaintain', hasMaintainRule ? parsedMaintain : corMaintainCalc.maintain_fee);
        // 维护费系数
        set('corMaintainRatio', +(hasMaintainRule ? data.maintain_ratio : corMaintainCalc.maintain_ratio) || 0);
        set('corInsuranceRatio', data.co_insurance_ratio || '');
        frequentGoods && set('frequentGoods', frequentGoods);
        merge('corSalesmen', data.salesmen);
        merge('alarmThreshold', alarmMoney || data.alarm_threshold || ''); // 开启提示 设置预警
        merge('lockValue', lockMoney || data.lock_value || ''); // 开启提示 设置锁机
        merge('needAlarm', +!!(alarmMoney || lockMoney) || +data.need_alarm || 2); // 锁机值开关
        merge('corCompanyId', data.company_id);
        merge('corLockTime', data.update_lock_time);
        merge('todoDays', data.todo_days);
        merge('todoTime', data.update_todo_time);
        merge('corIsProject', +data.is_proj === 1);

        // 下拉发货人sug 都会获取新的发货人信息
        if (state.corAttachmentShow) {
          // 联系人上的所有附件信息：货物发生变更后，需要根据联系人常发货物和附件计算附件
          set('corAllAttachment', {
            goods_claims: data.goods_claims || [], // 联系人常发货物
            attachment: data.attachment || [], // 联系人附件
          });
          // 计算并赋值
          setAttachments({
            page,
            type: 'consignor',
          });
        }
      }
      if (canSetMem) {
        set('memberCode', convertSugToMemberData(data));
      }

      break;
    }
    default:
      break;
  }
  next(action);
};

export default consignorMiddleware;
