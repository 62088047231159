import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';
import { applyMiddleware, createStore } from 'redux';
import { PopUp, ModalDialog, Table, Button, Label, TextArea, LodopFunc, PrintManager, DialogDetail } from 'components';
import {
  fetchApi as fetch,
  formatCamelCase,
  typeIs,
  confirm,
  alert,
  validateFieldsOf,
  showInfo,
  opTips,
  customDetail,
  hasApproval,
  isset,
  buildShowTip,
  camelToDash,
  throttle,
  ot,
  emitListBtnOp,
  postJAVA,
  fetchJAVA,
} from 'utils';
import { checkSend, checkMsgHistory } from 'utils/checkMsgSend';
import { dataAnalyticTrack } from '@/utils/dataAnalytic';
import { CHECK, WARN, ERROR, BUTTON_ICON } from 'constants';
import actions from 'actions';
import scanReceiptCore from 'components/commoncomponents/scanReceiptCore';
import insureOrder from 'components/commoncomponents/insurance/insureOrder';
import popUpWithRemark from 'components/commoncomponents/popUpWithRemark';
import Popover from 'components/utilcomponents/tips/Tooltip/popover';
import PathSettingModal from 'components/commoncomponents/pathSetting';
import { checkLODOP } from 'utils/business/print/checkLODOP';
import { directToSetting } from 'utils/business/setting/direct';
import initShortcuts from 'utils/business/order/initShortcuts';
import { withI18n, defaultI18n } from 'utils/i18n/context';
import _ from 'lodash';

import {
  initPath,
  getErrorMsg,
  userInputPrintCount,
  getPrintInfo,
  switchWeight,
  proxyListPage,
  sendMsg,
  buildPrintItem,
  invalidTransInfo,
  isCollectPrint,
  consignorAlarm,
  numSectionCheck,
  setPerson,
  calcField,
} from './tool';
import commonErrorTip from './public/commonErrorTip';
import priceErrorTip from './public/priceErrorTip';
import ledgerErrorTip from './public/ledgerErrorTip';
import getCreateOrderReq from './public/getCreateOrderReq';
import getModifyOrderReq from './public/getModifyOrderReq';
import {
  FETCH_ORDER_DATA,
  RESTORE_ORDER_DATA,
  SET_ORDER_DATA,
  MERGE_DATA,
  SET_SUG_DATA,
  tableFeeKeys,
  tableNames,
  goodsKeys,
  continueTxt,
  distributeKeys,
  payModeKeys,
  OUTER_TRANS,
  CALC_OUTER_PRICE_IMMEDIATELY,
  tableKeysMap,
} from './constant';
import { pathMap } from './pathSetting/constant';
import { getInnerPriceError } from './info/tool';

import renderHeader from './header';
import renderTraffic from './traffic';
import renderStateStamp from './stateStamp';
import renderPerson from './person';
import renderGoods from './goods';
import renderValueAddedService from './valueAddedService';
import renderFee from './fee';
import renderExtra from './extra';
import renderInfo from './info';
import renderProfit from './profit';
import renderMap from './map';
import renderFooter from './footer';
import renderPre from './pre';
import renderScan from './scan';
import renderImg from './img';
import MessageTplModal from './msg';
import PersonSettingModal from './personSetting';
import DistributeModal from './fee/collect/distribute';
import { AsyncOrderPreview } from './preview/dynamicImport';

import middleware from './middleware';
import reducer from './reducer';

import { prefixCls } from './index.scss';
import { DangerousFlagEnum } from 'constants/setting/rosSetting';

class OrderEditor extends PureComponent {
  static propTypes = {
    usedFor: PropTypes.string,
    data: PropTypes.object,
    asService: PropTypes.bool,
    onSaveSuccess: PropTypes.func,
    i18n: PropTypes.object,
  };

  static defaultProps = {
    // 开单、详情、改单 create/detail/modify/pre/tags/updatePre
    usedFor: 'create',
    i18n: defaultI18n,
  };

  constructor(props) {
    super(props);
    const initialState = {};
    const store = createStore(reducer, initialState, applyMiddleware(...middleware.map(bind => bind(this))));

    this.store = store;
    this.renderHeader = renderHeader(this);
    this.renderTraffic = renderTraffic(this);
    this.renderStateStamp = renderStateStamp(this);
    this.renderPerson = renderPerson(this);
    this.renderGoods = renderGoods(this);
    this.renderValueAddedService = renderValueAddedService(this);
    this.renderFee = renderFee(this);
    this.renderExtra = renderExtra(this);
    this.renderInfo = renderInfo(this);
    this.renderProfit = renderProfit(this);
    this.renderMap = renderMap(this);
    this.renderFooter = renderFooter(this);
    this.renderPre = renderPre(this);
    this.renderScan = renderScan(this);
    this.renderImg = renderImg(this);
    this.count = 0;
    this.printerHostId = window.globalPrintHostId;
    this.printerHostName = window.globalPrintHostName;
    this._btnModifyRef = null;
    // eslint-disable-next-line react/no-unused-state
    store.subscribe(() => this.setState({ count: this.count++ }));
    if (window.CLODOP) this.LodopObj = LodopFunc.getLodop();
    if (props.asService) {
      this.initStore(props);
    }
  }

  UNSAFE_componentWillMount() {
    const { usedFor } = this.props;
    const needGetPrintData = usedFor === 'updatePre';
    const { data } = this.props;
    if (data) {
      this.initStore(this.props);
    }
    data &&
      setTimeout(() => {
        const state = this.store.getState();
        if (state.isCreate) {
          this.initShortcuts();
          this.path = initPath(this.wrap, state.path.show, state.goodsRelate.length);
        }
        if (data.editedFields) {
          const inputs = [...this.wrap?.querySelectorAll?.('.fn-input-pure')];
          inputs.forEach((item, i) => data.editedFields[inputs[i].dataset.path] && (inputs[i].dataset.isEdited = '1'));
          if (data.originValue && state.isModify) {
            inputs.forEach(
              (item, index) =>
                inputs[index].dataset.path in data.originValue &&
                (inputs[index].dataset.originValue = data.originValue[inputs[index].dataset.path]),
            );
            this.diff();
          }
        }
        this.initTips();
      }, 20);
    needGetPrintData && data && setTimeout(this.getPrintData, 20);
    // 网点中转接收相当于开单 触发计算外部价格
    if (data && this.props.usedFor === 'pointTrans') {
      setTimeout(this.pointTransAutoDispatch, 100);
    }
  }

  componentDidMount() {
    if (
      !this.printerHostId &&
      this.LodopObj &&
      this.LodopObj !== 'undefined' &&
      this.LodopObj.VERSION &&
      this.LodopObj.VERSION !== 'undefined'
    ) {
      setTimeout(() => {
        actions.printActions.getHostId().then(printerHostId => {
          this.printerHostId = printerHostId;
          actions.printActions.getHostName().then(printerHostName => (this.printerHostName = printerHostName));
        });
      }, 100);
    }
    // 网点中转接收需要计算提货/送货里程
    if (this.props.usedFor === 'pointTrans') {
      const state = this.store.getState();
      this.set('ceeAddrInfo', state.ceeAddrInfo);
      this.set('corAddrInfo', state.corAddrInfo);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      const { usedFor } = this.props;
      const needGetPrintData = usedFor === 'detail' || usedFor === 'updatePre';
      setTimeout(() => {
        const state = this.store.getState();
        if (state.isCreate) {
          this.path = initPath(this.wrap, state.path.show, state.goodsRelate.length);
          if (
            usedFor === 'scan' &&
            state.arrInfo &&
            (state.arrInfo.show_val || (state.arrInfo.addr && state.arrInfo.addr.show_val))
          )
            this.path.next();
        }
        if (state.isCreate || state.isModify) {
          const inputs = [
            ...this.wrap?.querySelectorAll?.('.fn-input-pure'),
            ...this.wrap?.querySelectorAll?.('.ant-input'),
          ];
          const dataKey = ['isEdited', 'isChanged', 'originValue'];
          inputs.forEach((item, index) => dataKey.forEach(key => delete inputs[index].dataset[key]));
          state.isModify && inputs.forEach((item, index) => (inputs[index].dataset.originValue = inputs[index].value));
          this.initShortcuts();
        }
        if (needGetPrintData) this.getPrintData();
        this.initTips();
      }, 20);

      this.initStore(nextProps);
      // 退货时默认修改逻辑
      if (usedFor === 'modify' && nextProps.data.req.back) {
        setTimeout(this.backAutoDispatch, 100);
      }
      // 网点中转接收相当于开单 触发计算外部价格
      if (this.props.usedFor === 'pointTrans') {
        setTimeout(this.pointTransAutoDispatch, 100);
      }
    }

    if (nextProps.usedFor === 'create' && nextProps.data?.res?.order_data?.order_num?.value) {
      dataAnalyticTrack(['订单创建', '创建', nextProps.data.res.order_data.order_num.value]);
    } else if (nextProps.usedFor === 'modify' && nextProps.data?.res?.order_data?.order_num?.value) {
      dataAnalyticTrack(['订单修改', '修改', nextProps.data.res.order_data.order_num.value]);
    }
  }

  componentDidUpdate() {
    const { usedFor } = this.props;
    usedFor === 'modify' && this.diff();
  }

  componentWillUnmount() {
    this.unbindShortcuts && this.unbindShortcuts();
  }

  set = (key, val, src) => {
    console.log(key, val, src, 'nnnnnn');
    this.store.dispatch({ type: SET_ORDER_DATA, payload: { key, val }, src });
  };

  setInput = (key, e) => this.set(key, e.target.value);
  setCheckbox = (key, e) => this.set(key, e.target.checked);

  setTable = (key, index, val, src, noCalPrice) => {
    const { store } = this;
    const state = store.getState();
    const data = [...(state[key] || [])];
    val ? (data[index] = { ...(data[index] || {}), ...val }) : (val = data.splice(index, 1)?.[0]);
    store.dispatch({ type: SET_ORDER_DATA, payload: { key, val: data, noCalPrice }, index, changes: val, src });
  };

  setSug = (key, data) => this.store.dispatch({ type: SET_SUG_DATA, payload: { key, data } });

  merge = (key, val, src) => {
    this.store.dispatch({ type: MERGE_DATA, payload: { [key]: val, key, val }, src });
  };

  beginSave = (key, btnType) => this.store.dispatch({ type: MERGE_DATA, payload: { [`${key}Saving`]: true, btnType } });

  endSave = key => this.store.dispatch({ type: MERGE_DATA, payload: { [`${key}Saving`]: false, btnType: '' } });

  edited = (key, tableKey, i = 0) => {
    if (this.props.asService) return false;
    const dataPath = tableKey !== undefined ? `${tableKey}_${key}_${i}` : key;
    const ele = this.wrap?.querySelector?.(`[data-path="${dataPath}"]`);
    return ele && ele.dataset.isEdited;
  };

  editedThisFocus = el => 'userInput' in el.dataset;

  refWrap = wrap => (this.wrap = wrap);

  getEle = path => (this.props.asService ? null : this.wrap?.querySelector?.(`[data-path="${path}"]`));

  focus = path => !this.props.asService && this.getEle(path).focus();

  initStore = props => {
    if (props.data.storeData) {
      this.store.dispatch({ type: RESTORE_ORDER_DATA, payload: props.data.storeData });
    } else {
      this.store.dispatch({
        type: FETCH_ORDER_DATA,
        payload: { data: props.data, usedFor: props.usedFor, i18n: props.i18n },
      });
    }
  };

  initShortcuts = () => {
    const state = this.store.getState();
    this.unbindShortcuts = initShortcuts(state.shortcuts, this.wrap);
  };

  diff = throttle(() => {
    if (this.wrap) {
      const inputs = [
        ...this.wrap?.querySelectorAll?.('.fn-input-pure'),
        ...this.wrap?.querySelectorAll?.('.ant-input'),
      ];
      inputs.forEach((item, i) => {
        const changed = item.dataset.originValue !== item.value;
        changed ? (inputs[i].dataset.isChanged = '1') : delete inputs[i].dataset.isChanged;
      });
    }
  }, 100);
  // 退货时根据设置自动修改字段
  backAutoDispatch = () => {
    const state = this.store.getState();
    if (!state || !state.runSetting || !state.runSetting.back_modify) {
      return;
    }
    // 修改的key统计
    const changeKeys = [];
    const change = (key, val, pathKey = key) => {
      this.set(key, val);
      changeKeys.push(pathKey);
    };
    const { back_modify: bm, back_remark: br } = state.runSetting;
    const autoModify = bm.value || [];
    const backRemark = br.value;
    // eslint-disable-next-line prefer-const
    let { payMode, payCoDelivery, payArrival, remark } = state;
    let newRemark = remark ? [remark] : [];
    backRemark.forEach(item => {
      switch (item) {
        case 'static_words':
          return newRemark.push(br.static_words || '');
        case 'cee_name':
          return newRemark.push(`原收货人：${state.ceeName || ''}`);
        case 'cee_phone':
          return newRemark.push(`原收货手机：${state.ceeMobile || ''}`);
        default:
          console.log('wtf');
      }
    });
    newRemark = newRemark.join('，');
    // 修改备注
    if (backRemark !== remark) {
      change('remark', newRemark);
    }
    const { effectTotalPriceKeys, payModeEnum } = state;
    // 设置退货费默认值
    // 货费的默认值=原始运单的合计运费-货款手续费-制单费（如果合计运费组成中不包含这两项，则不用减）
    const coDeliveryFee = effectTotalPriceKeys.coDeliveryFee ? +state.coDeliveryFee || 0 : 0;
    const coMakeF = effectTotalPriceKeys.coMakeF ? +state.coMakeF || 0 : 0;
    // 退货费默认值
    const backF = (+state.totalPrice || 0) - coDeliveryFee - coMakeF;
    if (!+payArrival) {
      payArrival = 0;
    }
    payArrival += backF;
    // 货款扣变成到付
    if (autoModify.includes('delivery_to_arrival') && +payCoDelivery) {
      payArrival += +payCoDelivery || 0;
      payCoDelivery = 0;
      change('payCoDelivery', payCoDelivery);
      if (payMode === 'pay_co_delivery') {
        if (!payModeEnum.find(item => item.key === 'pay_arrival')) {
          payModeEnum.push({ key: 'pay_arrival', name: '到付' });
        }
        payMode = 'payArrival';
      }
    }
    // 付款方式不是到付检查 是否有 到付，多笔付付款方式
    if (
      payMode !== 'pay_arrival' &&
      payMode !== 'pay_multi' &&
      !(autoModify.includes('delivery_to_arrival') && +payCoDelivery)
    ) {
      if (!payModeEnum.find(item => item.key === 'pay_arrival')) {
        payModeEnum.push({ key: 'pay_arrival', name: '到付' });
      }
      // 不是免费时检查是否有多笔付付款方式
      if (payMode !== 'pay_free') {
        if (!payModeEnum.find(item => item.key === 'pay_multi')) {
          payModeEnum.push({ key: 'pay_multi', name: '多笔付' });
        }
        payMode = 'pay_multi';
      } else {
        payMode = 'pay_arrival';
      }
    }
    // 多笔付时需要手动计算到付，否则middleware会计算
    if (payMode === 'pay_multi') {
      change('payArrival', payArrival.toFixed(2));
    }
    change('payModeEnum', payModeEnum);
    if (backF) {
      change('payMode', payMode);
    }
    change('backF', backF.toFixed(2));
    autoModify.forEach(k => {
      // 修改路由为非必填，不然可能无法保存
      switch (k) {
        case 'info_change': {
          // 到站变成发站
          change('arrInfo', state.startInfo, 'arr');
          this.set('routeRequired', false);
          break;
        }
        case 'point_change': {
          // 目的网点变成开单网点
          const { startPointEnum, enums } = state;
          const arrPoint = {
            company_id: startPointEnum[0].id,
            display: startPointEnum[0].display,
            name: startPointEnum[0].display,
            short_name: startPointEnum[0].short_name,
          };
          enums.arr_point = enums.arr_point || {};
          enums.arr_point[arrPoint.company_id] = arrPoint;
          // 解决diff时不显示的bug
          this.set('enums', enums);
          change('arrPoint', arrPoint);
          this.set('routeRequired', false);
          break;
        }
        case 'delivery_clear': {
          // 代收货款&手续费清空
          change('coDelivery', 0);
          change('coDeliveryFee', 0);
          break;
        }
        case 'person_change': {
          // 收货人变成发货人
          change('ceeId', state.corId);
          change('ceeId', state.corId);
          change('ceeCate', state.corCate);
          change('ceeIsMonth', state.corIsMonth);
          change('ceeName', state.corName);
          change('ceeMobile', state.corMobile);
          change('ceeAddrInfo', state.corAddrInfo);
          change('ceeCom', state.corCom);
          change('ceePhone', state.corPhone);
          change('ceeIdNum', state.corIdNum);
          change('ceeCustomerNo', state.corCustomerNo);
          change('ceeIndustry', state.corIndustry);
          change('ceeAddrRemark', state.corAddrRemark);
          change('ceeRemark', state.corRemark);
          this.merge('ceeSalesmen', state.corSalesmen);
          break;
        }
        default:
          console.log('wtf');
      }
    });
    // 修改内容红色加粗
    const selectors = changeKeys.map(item => `[data-path=${camelToDash(item, '_')}]`).join(',');
    const inputs = this.wrap?.querySelectorAll?.(selectors);
    setTimeout(() =>
      inputs.forEach(item => {
        const iitem = item;
        iitem.style.color = 'red';
        iitem.style.fontWeight = 'bold';
      }),
    );
  };
  continueTip = (res, caption = '操作提示：') => {
    let ret;
    if (typeof res.res.failed_detail[0] === 'object') {
      ret = new Promise(resolve =>
        opTips({
          resData: res,
          orderType: res.res.order_type,
          ext: { specialTips: res.errmsg, failedCallback: () => resolve(false) },
          continueCallback: (ids, cb) => {
            resolve(true);
            cb();
          },
        }),
      );
    } else {
      const msg = res.errmsg;
      const title = (
        <div className="dialog_detail">
          <p className="notice">{msg}</p>
        </div>
      );
      const detail = (
        <div className="dialog_detail order-continue-tip">
          <div className="checkinfo-detail">
            <p className="notice_title">{caption}</p>
            <div className="detail-content">
              {res.res.failed_detail.map(item => (
                <p className="notice_list">
                  <em>●</em>
                  {item}
                </p>
              ))}
            </div>
          </div>
        </div>
      );
      ret = confirm(WARN, title, continueTxt, '操作确认', detail);
    }

    res.res.focus &&
      ret.then(result => {
        if (!result) {
          const el = this.getEle(res.res.focus);
          el && el.focus();
        }
      });
    return ret;
  };

  getPrintData = async () => {
    const { store } = this;
    const state = store.getState();
    const { setting, buttons, groupId } = state;
    if (!buttons.print) return false;
    const res =
      !this.printData &&
      (await fetch('/Order/Order/getPrintBtn4Oinfo', { method: 'POST', body: { req: { group_id: groupId } } }));
    const printData = this.printData || res.res.print_buttons;
    const allPrintItems = setting.print_options ? setting.print_options.printItems : {};
    const sort = setting.order_print_sort && setting.order_print_sort.show ? setting.order_print_sort.show : [];
    const printItems = buildPrintItem(allPrintItems, printData, sort);
    const newButtons = { ...buttons, print: { ...buttons.print, children: printItems } };
    this.printData = printData;
    store.dispatch({ type: MERGE_DATA, payload: { printBtn: printData, printItems, buttons: newButtons } });
  };

  initTips = () => {
    // 产品要求 开单改单时 tips 覆盖显示。
    const state = this.store.getState();
    const { ext, keyMap, setting } = state;
    const showTableKeys = Object.keys(tableKeysMap).filter(item => state[`${item}Header`]);
    // const opTextMap = { cancel_settle: '取消结算', cancel_cash: '取消收银' }
    const clickHandler = (ext.promp || []).reduce((pre, item) => {
      const ele = this.getEle(item.key);
      if (ele && +item.status === 20) {
        ele.style.color = '#23AC38';
        ele.style.background = '#FFFDE3';
      }
      if (ele && +item.status === 15) {
        ele.style.color = '#FF8800';
        ele.style.background = '#FFFDE3';
      }
      // const op = item.op.map(k => <a key={k} onClick={() => this.tipOp(item)}>{opTextMap[k]}</a>)
      const content = (
        <div>
          提示：
          <br />
          {item.tip}
        </div>
      );
      return { ...pre, [item.key]: buildShowTip({ content, type: 'info' }) };
    }, {});
    // 权限隐藏字段的处理
    Object.entries(ext.perm_hide || {}).forEach(([key, val]) => {
      const { usedFor } = this.props;
      // 9901-如果是复制订单进入,不进行敏感字段赋值***
      if (usedFor === 'copy') {
        return;
      }
      let path = '';
      const hiddenFieldDisplaySetting = ext.hidden_field_display || setting.hidden_field_display;
      const hiddenFieldDisplayAsterisk = hiddenFieldDisplaySetting && hiddenFieldDisplaySetting.value === '***';
      const hiddenClass = hiddenFieldDisplayAsterisk ? ['hidden-value', 'hidden-value--asterisk'] : ['hidden-value'];
      if (keyMap[key]) {
        path = key;
        if (goodsKeys.includes(key)) {
          const ele = this.wrap?.querySelectorAll?.(`[data-path^=${path}]`);
          ele && Array.from(ele).forEach(item => item.classList.add(...hiddenClass));
        } else {
          const ele = this.getEle(path);
          ele && ele.classList.add(...hiddenClass);
        }
      } else {
        const table = showTableKeys.find(item => state[`${item}Header`].find(h => h.key === key));
        if (table) {
          path = `${tableKeysMap[table]}_${key}`;
          const ele = this.wrap?.querySelectorAll?.(`[data-path^=${path}]`);
          ele && Array.from(ele).forEach(item => item.classList.add(...hiddenClass));
        }
      }
      if (!path || !hiddenFieldDisplayAsterisk) return;
      const content = <div>您没有查看[{val}]的权限！</div>;
      clickHandler[path] = buildShowTip({ content, type: 'info' });
    });
    this.wrap.onmouseover = e => {
      const path = e.target.dataset.path || '';
      const handler = clickHandler[path] || clickHandler[path.replace(/_\d$/, '')];
      handler && handler(e);
    };

    this.wrap.onmouseout = e => {
      const path = e.target.dataset.path || '';
      const handler = clickHandler[path] || clickHandler[path.replace(/_\d$/, '')];
      handler && Popover.hide();
    };
  };

  async cancel(btnType) {
    this.props.onSaveSuccess && this.props.onSaveSuccess(btnType);
  }

  preview = () => {
    if (this.previewModal) return;
    const onClose = () => delete this.previewModal;
    const props = {
      data: this.store.getState(),
      onClose,
      handleHideCallback: onClose,
      onClickBtn: this.handleSaveBtnClick,
    };
    this.previewModal = new PopUp(AsyncOrderPreview, props);
    this.previewModal.show();
  };

  handleSaveBtnClick = async btnType => {
    const { usedFor } = this.props;
    this.beginSave(usedFor, btnType);
    this.previewModal && this.previewModal.close();
    await this.outerPriceCalcComplete();
    !(await this.save(btnType)) && this.endSave(usedFor);
  };
  outerPriceCalcComplete = () => {
    const { store } = this;
    store.dispatch({ type: CALC_OUTER_PRICE_IMMEDIATELY, payload: {} });
    return new Promise(resolve => {
      const state = store.getState();
      if (state.outerPriceCalcInProcess) {
        const unsubscribe = store.subscribe(() => {
          if (!store.getState().outerPriceCalcInProcess) {
            unsubscribe();
            resolve();
          }
        });
        return;
      }
      resolve();
    });
  };

  getSaveData = async () => {
    await this.outerPriceCalcComplete();
    const inputs = this.props.asService ? [] : this.wrap?.querySelectorAll?.('[data-is-edited]');
    const editedFields = Array.from(inputs).reduce((pre, item) => ({ ...pre, [item.dataset.path]: 1 }), {});
    const state = this.store.getState();
    const { isModify } = state;
    const req = getCreateOrderReq(state);
    req.is_co_check = false;
    if (isModify) {
      const inputs1 = this.props.asService ? [] : this.wrap?.querySelectorAll?.('[data-origin-value]');
      const originValue = Array.from(inputs1).reduce(
        (pre, item) => ({ ...pre, [item.dataset.path]: item.dataset.originValue }),
        {},
      );
      const modifyDiff = this.getDiff(req);
      const { diffData } = modifyDiff;
      return {
        diff: diffData,
        req: getModifyOrderReq(state, req, modifyDiff),
        storeData: state,
        editedFields,
        originValue,
      };
    }
    return { storeData: state, editedFields, req };
  };

  // 网点中转接收-触发价格计算
  pointTransAutoDispatch = () => {
    this.set('pointTransInit');
  };

  savePriceSnapshot(id) {
    const {
      priceSnapshot = {},
      priceSnapshotOrigin,
      priceSnapshotDelete = {},
      useJAVAPrice,
      isModify,
    } = this.store.getState();
    if (useJAVAPrice) {
      const url = '/cmm-pricesys/Basic/PriceSystemV2/saveProcessSnapshot';
      const price = {};
      const deleteFees = Object.keys(priceSnapshotDelete).filter(key => !priceSnapshot[key]);
      const snapshot = { type: 'yd', biz_id: id, delete_fees: isModify ? deleteFees : undefined };
      // 开单时price 与 process_snapshot结构一致 删除多余的
      if (priceSnapshotOrigin && priceSnapshotOrigin.process_snapshot && priceSnapshotOrigin.process_snapshot[0]) {
        Object.entries(priceSnapshotOrigin.process_snapshot[0]).forEach(([pt, data]) =>
          Object.keys(data).forEach(key => {
            if (isModify || priceSnapshot[key]) {
              price[pt] = price[pt] || {};
              price[pt][key] = priceSnapshotOrigin.price[0][pt][key];
              snapshot[pt] = snapshot[pt] || { calc_type: priceSnapshotOrigin.process_snapshot[0][pt].calc_type };
              snapshot[pt][key] = priceSnapshotOrigin.process_snapshot[0][pt][key];
            }
          }),
        );
      }
      if ((isModify && deleteFees.length) || Object.keys(price).length) {
        postJAVA(url, { price: [price], process_snapshot: [snapshot] });
      }
    }
  }
  async save(btnType) {
    const state = this.store.getState();
    const { usedFor } = this.props;

    const isCreate = usedFor === 'create';
    const isCopy = usedFor === 'copy';
    const isModify = usedFor === 'modify';
    const isPre = usedFor === 'pre';
    const isTags = usedFor === 'tags';
    const isScan = usedFor === 'scan';
    const isUpdatePre = usedFor === 'updatePre';
    const isPointTrans = usedFor === 'pointTrans' || usedFor === 'pointTransInfo';
    const { profitLt0Limit, profitShow, profit, odLinkId, msgTypeList, priceMode, oldPriceMode, innerPriceError } =
      state;
    const { keyMap, textMap, msgParam, goodSn = [], isLongKun } = state;
    const { coImgRequired, coImgShow, coImg, ext } = state;
    const { setting, runSetting, payArrival, payBilling, insured, scanReceipt, coDelivery, memberCode, transPayList } =
      state;
    const runSetData = runSetting || window.company_setting;
    const { obPlanLoadT, obPlanTruckT, obPlanArrT } = state;
    const orderDict = this.props.i18n.get('order', '运单');

    // 收/发货人附件信息
    const {
      corAttachmentShow,
      corAttachmentRequired,
      corAttachment,
      ceeAttachmentShow,
      ceeAttachmentRequired,
      ceeAttachment,
    } = state;

    if (btnType === 'transReject') {
      popUpWithRemark({
        title: `拒绝${orderDict}`,
        labelText: `您确认拒绝当前${orderDict}吗？`,
        sureCallback: async (reason, dialogRef) => {
          const url = isPointTrans ? '/Order/Trans/rejectInner' : '/Order/Trans/reject';
          const rejectData = { trans_type: 3, od_link_id: odLinkId, reason };
          const conf = { method: 'POST', body: { req: { orders: [rejectData] }, from: 'pc' } };
          const res = await fetch(url, conf);
          if (+res.errno === 0) {
            showInfo(CHECK, res.errmsg || '保存成功');
            this.props.onSaveSuccess && this.props.onSaveSuccess(btnType, res);
          } else if (+res.errno === 212) {
            showInfo(ERROR, getErrorMsg(res), true);
          } else {
            showInfo(ERROR, getErrorMsg(res));
          }
          dialogRef.handleHide();
        },
      });
      return false;
    }

    if (
      (isCreate || isCopy || isPre || isTags || isUpdatePre) &&
      ([1, 2].includes(+state.ext.co_company_info.type) || +state.ext.co_company_info.to_transit_shed)
    ) {
      const reason = {
        customFailDesc: '当前账号属于职能机构，无法开单，请登录网点账号开单！',
        reason: (
          <p>
            新注册客户，请先到“组织架构-组织”里点击“添加组织”，在“组织架构-员工”里点击“添加员工”，再登录网点员工账号创建
            {orderDict}。
            <br />
            <br />
            如需总部替下级网点开单，可以点击页面右上角的员工头像，在“切换组织”
            弹窗里选择您要开单的网点，切换到该网点再创建{orderDict}。
          </p>
        ),
      };
      alert(WARN, <DialogDetail noticeTitle="操作提示：" succedLength={0} ext={reason} />);
      return false;
    }

    if (!(await validateFieldsOf(this.wrap))) return false;

    if (coImgShow && coImgRequired && !coImg.length) {
      alert(ERROR, '照片为必填，请上传后再点击保存');
      return;
    }

    if (corAttachmentShow && corAttachmentRequired && !corAttachment.length) {
      alert(ERROR, '发货人附件为必填，请上传后再点击保存');
      return;
    }

    if (ceeAttachmentShow && ceeAttachmentRequired && !ceeAttachment.length) {
      alert(ERROR, '收货人附件为必填，请上传后再点击保存');
      return;
    }

    if (
      setting.cor_name_black_list &&
      setting.cor_name_black_list.selc_value.some(item => item.value === state.corName)
    ) {
      showInfo(ERROR, `${ot('发货人', '发货人姓名')}已加入黑名单！`);
      return false;
    }
    if (
      setting.cor_mobile_black_list &&
      setting.cor_mobile_black_list.selc_value.some(item => item.value === state.corMobile)
    ) {
      showInfo(ERROR, `${ot('发货人手机', '发货人手机号')}已加入黑名单！`);
      return false;
    }

    if ((isCreate || isCopy) && insured && !state.hasInsurancePermission) {
      showInfo(ERROR, '请先开通投保权限!');
      return false;
    }
    if (obPlanTruckT && obPlanTruckT < obPlanLoadT) {
      showInfo(ERROR, '预计发车时间不得早于预计装货时间');
      return false;
    }

    if (obPlanArrT && obPlanArrT < obPlanTruckT) {
      showInfo(ERROR, '预计到达时间不得早于预计发车时间');
      return false;
    }

    if (obPlanArrT && obPlanArrT < obPlanLoadT) {
      showInfo(ERROR, '预计到达时间不得早于预计装货时间');
      return false;
    }

    const requiredGoodsFiledWhenInsure = ['name'];

    const req = getCreateOrderReq(state);

    const { point_cost_info: pointCostInfo, goods } = req;

    const weightMin = setting.goods_weight_min && setting.goods_weight_min.value;
    const invalidGoods =
      !isPre &&
      !isTags &&
      goods.some((row, index) => {
        if (index > 0 && !row.name) return false;
        if (!row.num && !row.weight && !row.volume && !row.suit) {
          showInfo(
            ERROR,
            `货物第${index + 1}行${ot('件数')}/${ot('重量')}/${ot('体积')}/${ot('套数')}至少有一个不能为0/空`,
          );
          return true;
        }
        if (weightMin && !Number.isNaN(+weightMin) && row.weight && +row.weight < weightMin) {
          showInfo(ERROR, `货物第${index + 1}行${ot('重量')}不能小于${weightMin}kg`);
          return true;
        }
        if ((isCreate || isCopy) && insured && !isLongKun) {
          return requiredGoodsFiledWhenInsure.some(k => {
            const kVal = row[k];
            const noValue = typeIs(kVal, 'object') ? !kVal.value : !kVal;
            noValue && showInfo(ERROR, `勾选投保后，${textMap[k]}为必填项`);
            return noValue;
          });
        }
        return false;
      });

    if (invalidGoods) return false;

    if (insured) {
      if (isLongKun) {
        const offlineGoods = goods.filter(item => state.longKunOfflineGoods.includes(item.name));
        const goods_declare_no_over = window.company_setting.goods_declare_no_over.money;
        if (isModify) {
          if (ext.insured_status_code === 20) {
            showInfo(ERROR, `当前${orderDict}“投保中”，不允许改单`);
            return false;
          } else if (ext.insured_status_code === 30) {
            const oldGoodsNames = state.origin.order_data.goods.map(item => item.name);
            const goodsNames = goods.map(item => item.name);
            const goodsNameChange =
              goodsNames.length > oldGoodsNames.length || goodsNames.some(name => !oldGoodsNames.includes(name));
            if (offlineGoods.length && goodsNameChange) {
              showInfo(ERROR, `当前${orderDict}“已投保”，禁止将货物名称修改为“不允许线上投保货物”的名称`);
              return false;
            }
          }
        }
        if (ext.insured_status_code !== 30) {
          if (offlineGoods.length) {
            const detail = customDetail({
              caption: '操作提示',
              detail: [`${offlineGoods.map(item => `“${item.name}”`).join('、')}设置“不允许线上投保货物”`],
              hasDot: true,
            });
            if (
              !(await confirm(
                WARN,
                '当前货物不允许线上投保，是否线下投保？',
                { confirm: '线下投保', cancel: '返回修改' },
                undefined,
                detail,
              ))
            ) {
              return false;
            }
          } else if (+goods_declare_no_over && +goods_declare_no_over < +state.declaredValue) {
            if (
              !(await confirm(
                WARN,
                `声明价值超过线上投保的最大值${goods_declare_no_over}，请选择线下投保或修改声明价值。`,
                { confirm: '线下投保', cancel: '返回修改' },
              ))
            ) {
              return false;
            }
          }
        }
      } else if ((isCreate || isCopy) && (+state.declaredValue < 1000 || +state.declaredValue > 3000000)) {
        showInfo(ERROR, '勾选投保后,声明价值金额须在1000-300万之间！');
        return false;
      } else if ((isCreate || isCopy) && !/^[1-9]\d*00$/.test(state.declaredValue)) {
        showInfo(ERROR, '勾选投保后,声明价值须精确到百位（个位十位为0）!');
        return false;
      }
    }

    // 有代收货款 代收发放信息必填
    if (
      +coDelivery > 0 &&
      memberCode &&
      memberCode.co_delivery_mode === '银行卡' &&
      setting.co_delivery_required.value.some(k => !memberCode[k])
    ) {
      const requiredText = setting.co_delivery_required.value.map(k => `[${textMap[keyMap[k]]}]`).join('、');
      const reason = {
        customFailDesc: `${requiredText}是必填项`,
        reason: `已设置“有代收货款时，${requiredText}必填。”如果以上字段在开单页未显示，请联系系统管理员修改开单页显示字段设置。修改地址：系统设置-运单设置-开单页显示设置-开单页字段设置。`,
      };
      alert(ERROR, <DialogDetail succedLength={0} ext={reason} />).then(() =>
        new PopUp(DistributeModal, { page: this }).show(),
      );
      return false;
    }

    // 有现付时 支付方式必填
    if (+payBilling > 0 && setting.co_freight_f_required.value.some(k => !state[keyMap[k]])) {
      const requiredText = setting.co_freight_f_required.value.map(k => `[${textMap[keyMap[k]]}]`).join('、');
      const reason = {
        customFailDesc: `${requiredText}是必填项`,
        reason: `已设置“有现付时，${requiredText}必填。”如果以上字段在开单页未显示，请联系系统管理员修改开单页显示字段设置。修改地址：系统设置-运单设置-开单页显示设置-开单页字段设置。`,
      };
      alert(ERROR, <DialogDetail succedLength={0} ext={reason} />);
      return false;
    }

    const checkInnerPrice = setting.when_inner_price_error && setting.when_inner_price_error.value;

    // 验证价格计算
    if (
      innerPriceError &&
      checkInnerPrice === 'confirm_tip' &&
      !(await confirm(WARN, getInnerPriceError(innerPriceError)))
    ) {
      return false;
    }

    if (innerPriceError && checkInnerPrice === 'err_tip') {
      alert(ERROR, getInnerPriceError(innerPriceError, false));
      return false;
    }

    // 验证单票毛利
    if (
      !profitLt0Limit &&
      profitShow &&
      +profit < 0 &&
      !(await confirm(WARN, `当前${ot('单票毛利', '运单毛利')}为负数，确定要保存${orderDict}吗？`))
    )
      return false;

    const profitLt0LimitReason = {
      customFailDesc: `无法保存${orderDict}，请检查运费！`,
      reason: (
        <div className="profit-lt-0-limit-reason-wrap">
          <span className="profit-lt-0-limit-reason">●</span>
          {`${ot('单票毛利')} < 0`}
        </div>
      ),
    };
    if (
      profitLt0Limit &&
      profitShow &&
      +profit < 0 &&
      !(await alert(ERROR, <DialogDetail succedLength={0} ext={profitLt0LimitReason} />))
    )
      return false;

    const transFilter = item => item.dn_mgr_id || item.dn_com_id || item.trans_dn_mgr_id;

    const transInfo = req.trans_info && req.trans_info.filter(transFilter);

    if (invalidTransInfo(transInfo, isModify, state)) return false;

    if (!isModify && transInfo && transInfo.length === 2 && +transInfo[0].trans_type === +transInfo[1].trans_type) {
      showInfo(ERROR, '只支持同时员工中转和外部中转！');
      return false;
    }

    // 验证自动提货装车  配载优化3期。需求七和八
    const hasInnerTrans =
      transInfo && transInfo.length ? transInfo.filter(item => +item.trans_type === 2).length : false;
    const hasOuterTrans =
      transInfo && transInfo.length ? transInfo.filter(item => +item.trans_type === 1).length : false;
    const pickLoadFlag =
      pointCostInfo &&
      pointCostInfo[0] &&
      pointCostInfo[0].pickup_dr_name &&
      pointCostInfo[0].pickup_dr_phone &&
      pointCostInfo[0].pickup_tr_num;
    if (!isModify && runSetData.pickup_batch && runSetData.pickup_batch.checked === 'manual' && pickLoadFlag) {
      if (
        !(await confirm(WARN, `未设置提货批次号自动生成规则，无法自动提货装车，是否继续保存${orderDict}`, continueTxt))
      )
        return;
    }
    if (!runSetData.trans_in_a && runSetData.trans_in_a.checked && pickLoadFlag && hasInnerTrans) {
      showInfo(ERROR, '无法同时进行员工中转和提货装车！');
      return false;
    }

    // 验证中转和干线装车。transOutFlag 外部中转出库的设置（外部中转后扣减库存）
    const transArtery = (transOutFlag = false) => {
      if (hasInnerTrans) {
        showInfo(ERROR, '无法同时员工中转和干线装车！');
        return false;
      }
      if (hasOuterTrans && runSetData.trans_out_m.checked && transOutFlag) {
        showInfo(ERROR, '外部中转会出库，无法同时进行干线装车');
        return false;
      }
      return true;
    };

    // 验证自动配载装车  http://w-luodp.chemanman.com:6005//Frontend/zhangyoumu/45.%E4%B8%93%E4%B8%9A%E7%89%88%E4%B8%9A%E5%8A%A1/%E5%93%A5%E4%BC%A6%E5%B8%83%E4%B8%9A%E5%8A%A1PRD/start.html#p=配载优化3期
    if (!isModify && setting.co_auto_load.checked && pointCostInfo && pointCostInfo[0] && pointCostInfo[0].b_tr_num) {
      const transOutFlag = runSetData.trans_out_m.checked; // 外部中转出库(外部中转后扣减库存）
      if (
        runSetData.artery_batch.checked === 'manual' &&
        !(await confirm(WARN, `未设置发车批次号自动生成规则，无法自动干线装车，是否继续保存${orderDict}?`, continueTxt))
      )
        return;
      // 勾选了此设置项，且 填了干线姓名和干线电话，才会进行 transArtery 校验
      if (!(pointCostInfo && pointCostInfo[0] && pointCostInfo[0].b_dr_name && pointCostInfo[0].b_dr_phone)) {
        if (!transArtery(transOutFlag)) return false;
      } else if (!transArtery(transOutFlag)) {
        return false;
      }
    }

    // 验证多笔费用和合计中转费是否相同(1: 免费不需要验证 2：有到付不需要验证 )
    if (
      transInfo &&
      transInfo.length &&
      transInfo.some(row => {
        const transFee = +row.trans_f || 0;
        const totalPay = transPayList.reduce((total, key) => total + (+row[key] || 0), 0);
        const transType = +row.trans_type;
        // 外部中转特殊逻辑
        // 先去掉到付和多笔付的限制
        // 含到付时的非免费付款方式 和必须相等
        if (
          transType === 1 &&
          row.trans_pay_mode !== 'pay_free' &&
          ((row.trans_pay_mode !== 'pay_arrival' && row.trans_pay_mode !== 'pay_multi') || !+payArrival) &&
          transFee !== totalPay
        ) {
          showInfo(ERROR, `外部${ot('中转费合计')}与${ot('中转费')}付款方式的合计值不相等！`);
          return true;
        } else if (+payArrival || row.trans_pay_mode === 'pay_free') {
          return false;
        } else if (isModify && transType === OUTER_TRANS && transFee !== totalPay) {
          showInfo(ERROR, `${ot('中转费合计')}不等于各${ot('中转费')}付款方式的合计值，不能中转`);
          return true;
        }
        return false;
      })
    )
      return false;

    if (isTags || isPre) {
      delete req.total_price;
      !odLinkId && Object.entries(req).forEach(([key, val]) => (val === '' || val === null) && delete req[key]);
      req.type = isPre ? 1 : 2;
      isPre && (req.count = state.count);
      isPre && (req.order_num = state.orderNumRange && state.orderNumRange[0]);
      isPre && delete req.goods_num;
      !req.pay_mode && (req.pay_mode = '');
      !req.order_num && (req.order_num = req.sys_order_num);
      req.mgr_id = +req.mgr_id || +state.mgrId.id || '';
      req.price_mode = priceMode || 0;

      const modifyPreDiff = odLinkId ? this.getDiff(req) : {};

      if (priceMode !== ext.price_mode && odLinkId && modifyPreDiff.orderData) {
        // 确保是修改，而不是新增
        modifyPreDiff.orderData.price_mode = priceMode;
      }

      pointCostInfo && (req.point_cost_info = [{ pickup_f: pointCostInfo[0].pickup_f }]);
      const save = async isContinue => {
        const url = odLinkId ? '/Order/OrderModify/modifyPre' : '/Order/Order/coPreHandle/';
        const preReq = odLinkId
          ? { od_link_id: odLinkId, order_data: modifyPreDiff.orderData }
          : { ...req, is_co_check: !isContinue };
        const conf = { method: 'POST', body: { req: preReq } };
        const res = await fetch(url, conf);
        if (+res.errno === 0 || +res.errno === 280 || +res.errno === 325) {
          // errno为280时黄色弹框提示 "账户余额不足,短信通知未发送成功"
          if (+res.errno === 280) {
            showInfo(WARN, res.msg || '保存成功');
          } else if (+res.errno === 325) {
            res.res.success_id = ['1'];
            await new Promise(resolve =>
              opTips({ resData: res, ext: { specialTips: res.errmsg, failedCallback: resolve } }),
            );
          } else {
            showInfo(CHECK, res.errmsg || '保存成功');
          }
          const isPrePrint = ['pre_new_print', 'pre_save_print', 'tags_new_print', 'tags_save_print'].includes(btnType);
          if (isPrePrint) {
            const resSucc = res.res.succ;
            for (const item of resSucc) {
              const odLinkIdPre = item.od_link_id || item.od_link;
              this.set('odLinkId', odLinkIdPre);
              await this.print(false);
            }
          }
          // eslint-disable-next-line no-nested-ternary
          const btnTypePre = ['pre_new_print', 'tags_new_print'].includes(btnType)
            ? btnType
            : isPre
            ? 'pre_save'
            : 'tags_save';
          this.props.onSaveSuccess && this.props.onSaveSuccess(btnTypePre, res);
          return true;
        } else if (res.errno === 320) {
          return (await this.continueTip(res)) && (await save(true));
        } else if (+res.errno === 403) {
          // 货物尺寸超标
          return opTips({
            resData: res,
          });
        }

        if (+res.errno === 351) {
          // 合计运单非0 且 付款方式为免费
          const handle = {
            onClose: () => {
              this.focus('pay_mode');
            },
          };
          showInfo(ERROR, res.errmsg, true, handle, false);
        } else if (+res.errno === -2207) {
          numSectionCheck(res);
        } else {
          showInfo(ERROR, getErrorMsg(res));
        }
        return false;
      };
      return await save();
    }

    if (isModify) {
      const settledPayModeText = [];
      payModeKeys.forEach(key => {
        req[key] = state[keyMap[key]];
        const settled = +ext[`${key}_settle_am_d`];
        const oldData = state.origin.order_data;
        if (settled && settled > req[key] && (+req[key] || 0) !== ((oldData[key] && +oldData[key].value) || 0)) {
          settledPayModeText.push(textMap[keyMap[key]]);
        }
      });
      if (settledPayModeText.length > 0) {
        const payModeText = settledPayModeText.join('，');
        const simpleText = { confirm: '返回修改', cancel: '继续保存' };
        const result = await confirm(
          WARN,
          `您修改的“${payModeText}”金额小于${payModeText}已结算金额，为保证数据一致请先取消结算再进行改单。`,
          simpleText,
        );
        if (result) {
          if (state.lastEffectTotalPriceKey) {
            this.focus(keyMap[state.lastEffectTotalPriceKey]);
          }
          return false;
        }
      }
      this.renderModifyModal(req, btnType);
      return false;
    }

    if (isUpdatePre && odLinkId) {
      const updatePreDisableReason = {
        customFailDesc: `预开单填写的运费/回扣，在补录${orderDict}时不允许修改！`,
        reason: `您当前填写的信息会触发运费重新计算，但补录${orderDict}时不允许修改运费。为避免开单运费与实际不一致。请您在保存${orderDict}后，申请改单，重新计算运费。`,
      };
      if (state.freightChange) await alert(WARN, <DialogDetail succedLength={0} ext={updatePreDisableReason} />);
      const save = async isContinue => {
        const url = `/Order/Order/supplOrder?co_logid=${state.logid}`;
        const conf = {
          method: 'POST',
          body: { req: { order_data: req, od_link_id: odLinkId, is_co_check: !isContinue }, from: 'pc' },
        };
        const res = await fetch(url, conf);
        if (+res.errno === 0) {
          showInfo(CHECK, res.errmsg || '保存成功');
          if (btnType === 'update_pre_save_print') {
            await this.print(false, res.res.point_trans_info);
          }
          this.savePriceSnapshot(odLinkId);
          this.props.onSaveSuccess && this.props.onSaveSuccess(btnType, res);
          return true;
        }
        if (res.errno === 160 || res.errno === 169) {
          consignorAlarm(state, res);
        } else if (res.errno === 178) {
          return (await consignorAlarm(state, res)) && (await save(true));
        } else if (res.errno === 320) {
          return (await this.continueTip(res)) && (await save(true));
        } else if (+res.errno === 351) {
          // 当设置了“合计运费>0时提示且限制开单”,如果付款方式免费提示
          const handle = {
            onClose: () => {
              this.focus('pay_mode');
            },
          };
          showInfo(ERROR, res.errmsg, true, handle, false);
        } else if (+res.errno === 350) {
          priceErrorTip(res);
        } else if (+res.errno === -2207) {
          numSectionCheck(res);
        } else if (res.errno === 353) {
          commonErrorTip({ title: res.errmsg, caption: '原因说明：', detail: res.res.failed_detail });
        } else if (+res.errno === 403) {
          // 货物尺寸超标
          opTips({
            resData: res,
          });
        } else {
          showInfo(ERROR, getErrorMsg(res));
        }
        return false;
      };
      return await save();
    }

    if (btnType === 'transAccept') {
      req.price_mode = priceMode;
      req.old_price_mode = oldPriceMode;
      payModeKeys.forEach(key => (req[key] = state[keyMap[key]]));
      const url = isPointTrans ? '/Order/Trans/acceptInner' : '/Order/Trans/accept';
      const { orderData } = isPointTrans ? this.getDiff(req) : {};
      req.od_link_id = odLinkId;
      req.billing_date = state.billingDate;
      // req.sys_order_num = state.origin.order_data.order_num.value
      const conf = {
        method: 'POST',
        body: { req: { orders: [req], diffData: orderData, is_batch: false }, from: 'pc' },
      };
      const res = await fetch(url, conf);
      if (+res.errno === 0) {
        showInfo(CHECK, res.errmsg || '保存成功');
        this.props.onSaveSuccess && this.props.onSaveSuccess(btnType, res);
        const { printItems } = state;
        const printChecked = printItems.filter(item => item.checked);
        if (printChecked && printChecked.length > 0) {
          this.set('odLinkId', res.res.od_link_id);
          await this.print();
        }
        return true;
      } else if (res.errno === 168) {
        const resData = res;
        resData.res.ext = {
          specialTips: res.errmsg,
          reason: resData.res.failed_detail[0].msg,
        };
        opTips({
          resData,
        });
        return false;
      }
      showInfo(ERROR, getErrorMsg(res));
      return false;
    }

    if ((isCreate || isCopy) && !msgParam && msgTypeList && msgTypeList.length) {
      const msgUrl = '/Basic/ShortMessage/showTemplate';
      const msgReq = { typeList: ['BUS_TRACK_SEND', 'BUS_TRACK_RECEIVE'] };
      const msgConf = { method: 'POST', body: { req: msgReq } };
      const res = await fetch(msgUrl, msgConf);
      if (res.errno !== 0) return false;
      const msgData = res.res.data.templateList || [];
      const tplId = msgTypeList.map(item => msgData.filter(dataItem => dataItem.type === item)[0].tpl_id);
      req.msg_param = [{ tpl_ids: tplId, phone_list: this.msgPhoneList() }];
    } else if ((isCreate || isCopy) && msgParam) {
      req.msg_param = msgParam.map(item => ({ ...item, phone_list: this.msgPhoneList() }));
    }

    const isUpdatePreSaveCreate = isUpdatePre && !odLinkId;

    if (isCreate || isCopy || isUpdatePreSaveCreate || isScan) {
      state.reservationNum && (req.reservation_num = state.reservationNum);
      state.reservationNum && (req.entrust_num = state.entrustNum);
      req.is_merge = Array.isArray(state.reservationNum);
      isScan && (req.good_sn = goodSn.map(item => item.number));
      isScan && (req.co_mode = 1);
      msgTypeList && (req.msgTypeList = msgTypeList);
      const save = async isContinue => {
        const url = `/Order/Order/coHandle?btn_type=${btnType}&co_logid=${state.logid}`;
        const conf = { method: 'POST', body: { req: { ...req, is_co_check: !isContinue }, from: 'pc' } };
        const res = await fetch(url, conf);
        if (+res.errno === 0 || +res.errno === 280) {
          // error=280是提示信息"账户余额不足， 短信通知未发送成功"
          const odLinkIdNew = res.res.order_data.od_link_id;
          let insureResult;
          if (+res.errno === 280) showInfo(WARN, res.errmsg || '保存成功');
          else showInfo(CHECK, res.errmsg || '保存成功');
          this.savePriceSnapshot(odLinkIdNew);
          insured && !isLongKun && (insureResult = await insureOrder(odLinkIdNew));
          if (btnType === 'co_save_print' || btnType === 'co_new_print' || btnType === 'update_pre_save_print') {
            this.set('odLinkId', odLinkIdNew);
            await this.print(false, res.res.point_trans_info);
            dataAnalyticTrack(['订单打印', '创建订单页面-打印']);
          }
          if (scanReceipt) {
            await scanReceiptCore([odLinkIdNew], { isCo: true });
          }
          this.props.onSaveSuccess && this.props.onSaveSuccess(btnType, res, insured && !isLongKun, insureResult);

          dataAnalyticTrack(['订单创建', '保存', res.res?.order_data?.order_num]);

          return true;
        }
        if (res.errno === 320) {
          return (await this.continueTip(res)) && (await save(true));
        }

        if (res.errno === 321) {
          const result = await new Promise(resolve =>
            opTips({
              resData: res,
              orderType: res.res.order_type,
              ext: { specialTips: res.errmsg, failedCallback: () => resolve(false) },
              extBtn: [
                {
                  key: 'cancel',
                  name: '取消',
                  btnType: 'default',
                  callBack: () => resolve(false),
                },
                ...(res.res.success_id && res.res.success_id.length
                  ? [
                      {
                        key: 'confirm',
                        name: '继续扫码',
                        btnType: 'primary',
                        callBack: () => resolve(true),
                      },
                    ]
                  : []),
                {
                  key: 'update',
                  name: '更新扫码',
                  btnType: 'primary',
                  callBack: () => resolve('update'),
                },
              ],
            }),
          );
          if (result === 'update') {
            req.is_scan_co_force = true;
            return await save();
          }
          return result && (await save(true));
        }
        if (res.errno === 160 || res.errno === 169) {
          consignorAlarm(state, res);
        } else if (res.errno === 178) {
          return (await consignorAlarm(state, res)) && (await save(true));
        } else if (+res.errno === 350) {
          priceErrorTip(res);
        } else if (+res.errno === 351) {
          const handle = {
            onClose: () => {
              this.focus('pay_mode');
            },
          };
          showInfo(ERROR, res.errmsg, true, handle, false);
        } else if (res.errno === 353) {
          commonErrorTip({ title: res.errmsg, caption: '原因说明：', detail: res.res.failed_detail });
        } else if (+res.errno === -2207) {
          numSectionCheck(res);
        } else if (+res.errno === 403) {
          // 货物尺寸超标
          opTips({
            resData: res,
          });
        } else {
          showInfo(ERROR, getErrorMsg(res));
        }
        return false;
      };
      return await save();
    }
    return true;
  }
  getDiff = newData => {
    const diffData = [];
    const addrKeys = { start_info: 1, arr_info: 1, cor_addr_info: 1, cee_addr_info: 1 };
    const boolKeys = {
      tax_inc: 1,
      rebate_paid: 1,
      pay_billing_paid: 1,
      co_pay_adv_paid: 1,
      cashreturn_paid: 1,
      notice_delivery: 1,
      pickup: 1,
      pkg_service: 1,
      need_dispatch: 1,
      need_insured: 1,
      matched_cor_attachment: 1,
      matched_cee_attachment: 1,
    }; // 勾选项集合
    const state = this.store.getState();
    const { origin, textMap, keyMap, validateMap, ext, enums, weightUnit } = state;
    const diff = (key, text, oldVal, newVal, force) => {
      if (force || (text && oldVal !== newVal)) {
        if (ext.perm_hide && ext.perm_hide[key]) {
          diffData.push({ key, text, oldVal: '***', newVal: '***' });
        } else {
          diffData.push({ key, text, oldVal, newVal });
        }
        return true;
      }
      return false;
    };
    const oldData = origin.order_data;
    const orderData = { sys_order_num: oldData.order_num.value };
    const tables = {};
    Object.keys(newData).forEach(key => {
      const oldVal = oldData[key] && oldData[key].value;
      const newVal = newData[key];
      const validate = validateMap[keyMap[key]];
      const text = textMap[keyMap[key]];
      let diffNormal = false;
      if (key === 'total_price' || (validate && validate !== 'poi')) {
        diffNormal = diff(key, text, +oldVal || '0', +newVal || '0');
      } else if (boolKeys[key]) {
        // 需要配安 字段历史数据为 -1，所以此处根据逻辑值是否等于1 来判断是否。
        diffNormal = diff(key, text, +oldVal === 1 ? '是' : '否', +newVal === 1 ? '是' : '否');
      } else if (addrKeys[key]) {
        const oldShowVal = oldVal ? oldVal.show_val || '' : '';
        const newShowVal = newVal ? newVal.show_val || '' : '';
        diffNormal = (oldVal ? oldVal.poi || '' : '') !== (newVal ? newVal.poi || '' : '') || oldShowVal !== newShowVal;
        diff(key, text, oldShowVal, newShowVal, diffNormal);
      } else if (key === 'route') {
        if (
          (oldData.route_id && oldData.route_id.value) !== newData.route_id ||
          (oldData.route_nick && oldData.route_nick.value) !== newData.route_nick
        ) {
          orderData.route_id = newData.route_id;
          orderData.route_name = newData.route_name;
          orderData.route_nick = newData.route_nick;
          orderData.route = newData.route;
          orderData.route_time = state.routeTime;
          orderData.trans_hour = state.transHour;
          orderData.is_through = state.isThrough;
          orderData.old_is_through = state.oldIsThrough;
          diff(key, text, oldData.route_nick.value || '', newData.route_nick || '', true);
        }
      } else if (key === 'value_add_service') {
        diffNormal = diff(key, text, (oldVal && oldVal.join('/')) || '', (newVal && newVal.join('/')) || '');
        if (diffNormal) orderData.value_add_service = newData.value_add_service;
      } else if (key === 'product_type') {
        diffNormal = diff(key, text, oldVal || '', newVal);
        if (diffNormal) orderData.product_key = newData.product_key;
      } else if (key === 'product_line') {
        const oldProductLine = oldVal && oldVal[0];
        const newProductLine = newVal && newVal[0];
        diffNormal = diff(
          key,
          text,
          oldProductLine ? oldProductLine.name : '',
          newProductLine ? newProductLine.name : '',
        );
        if (diffNormal) orderData.product_line = newData.product_line;
      } else if (key === 'rcv_stn') {
        if ((+oldVal || 0) !== (+newVal || 0)) {
          const oldSelected = state.rcvStnOld;
          const oldText = oldSelected ? oldSelected.short_name : '';
          const newText = newData.rcv_stn_name || '';
          diffNormal = true;
          diff(key, text, oldText, newText, true);
        }
      } else if (key === 'ob_project_id') {
        if ((+oldVal || 0) !== (+newVal || 0)) {
          const oldText = _.get(oldData, 'base_enum.ob_project_id.name', '');
          const newText = _.get(state, 'obProjectId.name', '');
          diffNormal = true;
          diff(key, text, oldText, newText, true);
        }
      } else if (key === 'ob_customer_id') {
        if ((+oldVal || 0) !== (+newVal || 0)) {
          const oldText = _.get(oldData, 'base_enum.ob_customer_id.name', '');
          const newText = _.get(state, 'obCustomerId.name', '');
          diffNormal = true;
          diff(key, text, oldText, newText, true);
        }
      } else if (key === 'arr_point') {
        if ((+oldVal || 0) !== (+newVal || 0)) {
          const oldSelected = enums[key][oldVal];
          const oldText = oldSelected ? oldSelected.display : '';
          const newText = state.arrPoint ? state.arrPoint.short_name : '';
          diffNormal = true;
          diff(key, text, oldText, newText, true);
        }
      } else if (key === 'trsp_mode') {
        diffNormal = diff(key, text, oldVal || '', newVal || '');
      } else if (key === 'device_id') {
        // GPS编号
        const deviceIds = state.deviceIdEnum || [];
        const oldText = deviceIds
          .filter(item => (oldVal || []).includes(item.id))
          .map(item => item.device_num)
          .join(',');
        const newText = deviceIds
          .filter(item => (newVal || []).includes(item.id))
          .map(item => item.device_num)
          .join(',');
        diffNormal = diff(key, text, oldText, newText);
      } else if (enums[key]) {
        if ((newVal || oldVal) && oldVal != newVal) {
          // eslint-disable-line
          diffNormal = true;
          let oldText = enums[key][oldVal];
          const newText = enums[key][newVal];
          if (key === 'receipt_cat' && !oldText) {
            oldText = (oldVal && oldVal.value) || oldVal;
            diff(key, text, oldText, newText ? newText.display : '', true);
          } else {
            diff(key, text, oldText ? oldText.display : '', newText ? newText.display : '', true);
          }
        }
      } else if (distributeKeys[key]) {
        const oldVal1 = ext.member_info[key] || '';
        diffNormal = diff(key, distributeKeys[key], oldVal1, newVal);
      } else if (key === 'goods') {
        const newGoods = newData.goods;
        const oldGoods = oldData.goods;
        const goods = [];
        const len = Math.max(newGoods.length, oldGoods.length);
        for (let index = 0; index < len; index++) {
          const newRow = newGoods[index] || {};
          const oldRow = oldGoods[index] || {};
          const row = {};
          goodsKeys.forEach(k => {
            const tUnitP = k === 'unit_p' && weightUnit === '吨';
            const validate1 = validateMap[keyMap[k]];
            const newVal1 = tUnitP && newRow.unit_p_unit === 'per_w' ? (+newRow[k] * 1000).toFixed(6) : newRow[k];
            const oldVal1 =
              oldRow[k] &&
              (tUnitP && oldRow.unit_p_unit.value === 'per_w' ? (+oldRow[k].value * 1000).toFixed(6) : oldRow[k].value);
            const text1 = `货物${index + 1}_${textMap[keyMap[k]]}`;
            let diffGoods = false;
            const whMap = {
              wh_name: 'wh_id',
              wh_area_name: 'wh_area_id',
              wh_location_name: 'wh_location_id',
            };
            if (!state[`${keyMap[whMap[k] || k]}Show`]) return;
            if (validate1 && validate1 !== 'poi') {
              diffGoods = diff(k, text1, +oldVal1 || '0', +newVal1 || '0');
            } else if (boolKeys[k]) {
              diffGoods = diff(k, text1, oldVal1 ? '是' : '否', newVal1 ? '是' : '否');
            } else if (k === 'unit_p_unit') {
              const { unitEnum } = state;
              const u1 = unitEnum.find(item => item.key === oldVal1);
              const u2 = unitEnum.find(item => item.key === newVal1);
              diffGoods = diff(k, text1, u1 ? u1.name : '', u2 ? u2.name : '');
            } else if (k === 'special') {
              const u1 = Array.isArray(oldVal1) ? oldVal1.join(',') : oldVal1;
              const u2 = newVal1 && newVal1.join(',');
              diffGoods = diff(k, text1, u1 || '', u2 || '');
            } else if (k === 'calc_info') {
              const oldCalc = oldVal1 || [];
              const newCalc = newVal1 || [];
              const isChange =
                state.groupId === 68544 &&
                (oldCalc.length !== newCalc.length ||
                  newCalc.some((r, i) => ['l', 'w', 'h', 'n', 'v'].some(k1 => +r[k1] !== oldCalc[i][k1])));
              diffGoods = diff(k, text1, '', '', isChange);
            } else if (k === 'id' || k === 'wh_id' || k === 'wh_area_id' || k === 'wh_location_id') {
              // 货物的id相关信息不展示在diff表格中
            } else if (k === 'wh_name') {
              diffGoods = diff(k, `货物${index + 1}_仓库`, oldVal1 || '', newVal1 || '');
            } else if (k === 'wh_area_name') {
              diffGoods = diff(k, `货物${index + 1}_库区`, oldVal1 || '', newVal1 || '');
            } else if (k === 'wh_location_name') {
              diffGoods = diff(k, `货物${index + 1}_货位`, oldVal1 || '', newVal1 || '');
            } else if (k === 'dangerous_flag') {
              const cm = v => {
                if (['1', 1].includes(v)) return '是';
                if (['0', 0].includes(v)) return '否';
                return v;
              };
              const dic1 = DangerousFlagEnum.find(item => item.key === oldVal1);
              const d1 = dic1 ? dic1.name : cm(oldVal1);
              const dic2 = DangerousFlagEnum.find(item => item.key === newVal1);
              const d2 = dic2 ? dic2.name : cm(newVal1);
              diffGoods = diff(k, text1, d1, d2);
            } else if (k === 'dangerous_cat_name') {
              diffGoods = diff(k, text1, oldVal1?.join?.('，') ?? oldVal1, newVal1?.join?.('，') ?? newVal1);
            } else if (k === 'attachments') {
              diffGoods = diff(
                k,
                text1,
                oldVal1?.map?.(v => v.showName || v.name).join('，') ?? oldVal1,
                newVal1?.map?.(v => v.showName || v.name).join('，') ?? newVal1,
              );
            } else {
              diffGoods = diff(k, text1, oldVal1 || '', newVal1 || '');
            }
            diffGoods && (row[k] = newVal1);
          });
          newGoods[index] && (goods[index] = newGoods[index]);
        }
        orderData.goods = goods;
      } else if (tableNames[key] && newVal) {
        const tableBoolKeys = {
          pickup_f_paid: '单票提货费已付',
          b_info_f_paid: '信息费费已付',
          trans_settle_money_paid: '结算金额已付',
          trans_f_paid: '中转合计费已付',
        };
        const tableName = tableNames[key];
        const oldTable = (origin[key] && origin[key].data) || [];
        const table = [];
        const originHeader = Object.entries((origin[key] && origin[key].header) || {}).map(([k, v]) => ({
          ...v,
          key: k,
        }));
        let header = key === 'trans_info' ? originHeader : state[`${formatCamelCase(key)}Header`];
        if (key === 'std_cost' && header && !header.find(item => item.key === 'std_mgr_id'))
          header = [...header, { key: 'std_mgr_id', title: '发货业务员' }];
        newVal.forEach((newRow, index) => {
          const oldRow = oldTable[index] || {};
          const row = {};
          header &&
            header.forEach(item => {
              const k = item.key;
              const newVal1 = newRow[k];
              const oldVal1 = oldRow[k];
              const text1 = `${tableName}${index + 1}_${item.title}`;
              let diffTable = false;
              if (tableFeeKeys[k]) {
                diffTable = diff(k, text1, +oldVal1 || '0', +newVal1 || '0');
              } else if (k === 'trans_w' && weightUnit === '吨') {
                diffTable = diff(k, text1, +oldVal1 || 0, +switchWeight(newVal1) || 0);
              } else if (k === 'trans_v_detail') {
                diffTable = diff(k, text1, (oldVal1 || []).join(','), (newVal1 || []).join(','));
              } else if (k === 'trans_w_detail') {
                diffTable = diff(
                  k,
                  text1,
                  (oldVal1 || []).join(','),
                  (newVal1 || []).map(w => (weightUnit === '吨' ? switchWeight(w) : w)).join(','),
                );
              } else if (k === 'dn_com_id' || k === 'dn_mgr_id' || k === 'address' || k === 'trans_route') {
                return;
              } else if (k === 'std_mgr_id') {
                if ((newVal1 || oldVal1) && (oldVal1 ? +oldVal1.id || 0 : 0) !== (+newVal1 || 0)) {
                  diffTable = true;
                  diff(k, text1, (oldVal1 && oldVal1.name) || '', newRow.std_mgr_name || '', true);
                }
              } else if (k === 'std_cee_mgr_id') {
                if ((newVal1 || oldVal1) && (oldVal1 ? +oldVal1.id || 0 : 0) !== (+newVal1 || 0)) {
                  diffTable = true;
                  diff(k, text1, (oldVal1 && oldVal1.name) || '', newRow.std_cee_mgr_name || '', true);
                }
              } else if (enums[k]) {
                if ((newVal1 || oldVal1) && oldVal1 != newVal1) {
                  // eslint-disable-line
                  diffTable = true;
                  const oldText = enums[k][oldVal1];
                  const newText = enums[k][newVal1];
                  diff(k, text1, oldText ? oldText.display : '', newText ? newText.display : '', true);
                }
              } else {
                diffTable = diff(k, text1, oldVal1 || '', newVal1 || '');
              }
              if (diffTable) row[k] = newVal1;
            });
          // 表格勾选diff
          Object.entries(tableBoolKeys).forEach(([k, title]) => {
            const text1 = `${tableName}${index + 1}_${title}`;
            if (diff(k, text1, oldRow[k] ? '是' : '否', newRow[k] ? '是' : '否')) row[k] = newRow[k];
          });
          if (Object.keys(row).length) {
            if (key !== 'std_cost') row.od_link_id = oldRow ? oldRow.od_link_id : '';
            if (key === 'trans_info') row.b_link_id = oldRow ? oldRow.b_link_id : '';
            table[index] = row;
          }
        });
        table.length && (tables[key] = table);
      } else if (key === 'co_img') {
        // 订单原只支持图片上传，origin_name 为真实文件名，现改为uploadFile上传，showName 为真实的文件名
        const oldValText = oldVal.map(item => item.origin_name || item.showName || item.name).join(',');
        const newValText = newVal.map(item => item.origin_name || item.showName || item.name).join(',');
        diffNormal = diff(key, text, oldValText, newValText);
      } else if (['cor_attachment', 'cee_attachment'].includes(key)) {
        // 把收发货人的匹配值，传入接口
        orderData.matched_cor_attachment = !!newData.matched_cor_attachment;
        orderData.matched_cee_attachment = !!newData.matched_cee_attachment;
        const oldValText = oldVal?.map?.(item => item.showName || item.name)?.join?.(',') || '';
        const newValText = newVal?.map?.(item => item.showName || item.name)?.join?.(',') || '';
        diffNormal = diff(key, text, oldValText, newValText);
      } else {
        diffNormal = diff(key, text, oldVal || '', newVal || '');
      }
      if (diffNormal) orderData[key] = newVal || '';
    });
    return {
      diffData,
      tables,
      orderData,
    };
  };
  async renderModifyModal(newData, btnType) {
    const state = this.store.getState();
    const { origin, ext, modifyFrom, odLinkId, isBack } = state;
    if (isBack && !odLinkId) {
      showInfo(ERROR, '退货请先选择运单');
      return false;
    }
    const modifyDiff = this.getDiff(newData);
    const { diffData, orderData } = modifyDiff;
    const opType = isBack ? '退货' : '修改';
    const oldData = origin.order_data;
    if (!diffData.length) {
      showInfo(ERROR, '没有修改信息');
      return false;
    }
    if (ext.ledger_fields && ext.ledger_fields.length && orderData.route_id) {
      const ledgerFieldsChanges = diffData.filter(item => ext.ledger_fields.includes(item.key));
      if (ledgerFieldsChanges.length) {
        ledgerErrorTip('无法申请改单！', ledgerFieldsChanges);
        return false;
      }
    }
    const req = getModifyOrderReq(state, newData, modifyDiff);

    let modal;
    let directToDetail =
      window.psn_setting.modify_redirect_oinfo &&
      window.psn_setting.modify_redirect_oinfo.__meta &&
      window.psn_setting.modify_redirect_oinfo.__meta.checked;
    const header = {
      text: { title: '修改项', type: 'Text', display: 'show', width: 460 / 3 },
      oldVal: { title: '改前信息', type: 'Text', display: 'show', width: 460 / 3 },
      newVal: { title: '改后信息', type: 'Text', display: 'show', width: 460 / 3 },
    };

    const reasonRe = isBack || (state.setting.reason_re ? state.setting.reason_re.checked : false);

    const save = async isContinue => {
      let url = '/Order/OrderModify/orderModify';
      req.is_co_check = !isContinue;
      const back = this.store.getState().isBack;
      req.back = back;
      if (isSyncGoodsToTask) {
        req.modify_order_sync_batch = 1;
      }
      const conf = { method: 'POST', body: { req, from: 'pc' } };
      const operation = modifyFrom === 'list' ? 'mod_list' : 'submit_om';
      const approval = modifyFrom === 'list' ? 'orderListApproval' : 'orderModifyApproval';
      if (hasApproval(approval) && !back) {
        // 开通改单审批流
        url = 'Basic/Approval/apply';
        conf.body = {
          req: {
            apply_data: { ...req, from: 'pc' },
            operation,
          },
        };
      }

      this.beginSave('modify', btnType);

      const res = await fetch(url, conf);

      if (+res.errno === 0) {
        showInfo(CHECK, res.errmsg || '保存成功');
        this.savePriceSnapshot(odLinkId);
        const tip = '本次改单修改的字段会影响交易改单字段的值发生变化，请前往交易改单进行修改！';
        const directToTradeModify = state.pointTransChange && (await confirm(WARN, tip, { confirm: '前往修改' }));
        this.props.onSaveSuccess &&
          this.props.onSaveSuccess(btnType, { ...res.res, directToTradeModify, directToDetail });

        dataAnalyticTrack(['订单修改', '保存', res.res?.order_num]);
      } else if (+res.errno === 161) {
        // bug19214 提示信息过长，且只有161采用这种提示 +26960
        showInfo(ERROR, <div className="max-tip">{getErrorMsg(res)}</div>, true, false, false);
      } else if (res.errno === 160 || res.errno === 169) {
        consignorAlarm(state, res);
      } else if (res.errno === 178) {
        return (await consignorAlarm(state, res)) && (await save(true));
      } else if (res.errno === 320) {
        (await this.continueTip(res)) && (await save(true));
      } else if (+res.errno === 350) {
        priceErrorTip(res);
      } else if (res.errno === 340) {
        // 拆单装车后修改实际运费提示
        res.errno = 0;
        this.endSave('modify');
        opTips({
          resData: res,
          continueCallback(sucId, cb) {
            cb();
            req.is_split_check = 0;
            save();
          },
        });
        return;
      } else if (+res.errno === 351) {
        // 运费大于0且付款方式为免费
        const handle = {
          onClose: () => {
            this.focus('pay_mode');
          },
        };
        showInfo(ERROR, res.errmsg, true, handle, false);
      } else if (res.errno === 353) {
        commonErrorTip({ title: res.errmsg, caption: '原因说明：', detail: res.res.failed_detail });
      } else if (+res.errno === -2207) {
        numSectionCheck(res);
      } else if (+res.errno === 173 || +res.errno === 174 || +res.errno === 175) {
        // 使用opTips弹框来进行提示错误信息。错误码的含义可以去找对应的后端查看。需求: 改单优化1.2
        this.endSave('modify');
        opTips({
          resData: res,
          className: `order_modify_errno_${res.errno}`,
        });
      } else if (+res.errno === 240) {
        this.endSave('modify');
        opTips({
          resData: res,
          orderType: '字段',
        });
      } else if (+res.errno === 403) {
        opTips({
          resData: res,
        });
      } else {
        showInfo(ERROR, getErrorMsg(res));
      }

      this.endSave('modify');
    };

    let isSyncGoodsToTask = true;
    const onChangeSyncGoodsToTask = e => {
      isSyncGoodsToTask = e.target.checked;
    };

    // 是否允许将货物同步到配载的运单
    const isAllowSyncGoodsToTask = window.company_setting.modify_order_sync_batch?.checked;

    // 该订单不可以同步货物到配载的运单时的错误提示
    const makeSyncGoodsErrorText = msgArr => {
      if (Array.isArray(msgArr)) {
        return msgArr.map(item => item.msg).join('，');
      }
      return '';
    };

    const check = async () => {
      if (!req.modify_reason && reasonRe) {
        showInfo(ERROR, `${opType}原因必填`);
      } else {
        // 若勾选了同步货物复选框，则先验证订单，通过后才可保存
        if (isSyncGoodsToTask && isAllowSyncGoodsToTask) {
          this._btnModifyRef?.loadingOn();
          const { odBasicId } = this.store.getState();
          const res = await fetchJAVA('/cmm-batch/order/syncBatchWithCheck', {
            method: 'GET',
            body: { od_basic_id: odBasicId },
          }).finally(() => {
            this._btnModifyRef?.loadingOff();
          });
          if (res?.errno !== 0) {
            showInfo(ERROR, res.errmsg || '');
            return;
          } else {
            const errorText = makeSyncGoodsErrorText(res.res?.failed_detail);
            if (errorText) {
              showInfo(ERROR, errorText);
              return;
            }
          }
        }
        modal.handleHide();
        save();
      }
    };

    const onChangeRedirect = e => {
      setPerson({ modify_redirect_oinfo: { __meta: { checked: e.target.checked } } });
      directToDetail = e.target.checked;
    };
    const reasonLabel = `${opType}原因：`;
    const content = (
      <div className="order_modify_dialog">
        <div>
          <Label classname="point">{`申请网点：${ext.req_company_name}`}</Label>
          <Label classname="person">{`申请人：${ext.req_user_name}`}</Label>
        </div>
        <div className="remark">
          <Label classname="reason" isRequired={reasonRe}>
            {reasonLabel}
          </Label>
          <TextArea classname="reason_text" maxLength="300" onChange={e => (req.modify_reason = e.target.value)} />
        </div>
        <Table
          width={460}
          height={270}
          showScrollbarX={false}
          isAutoSize={false}
          isShowTotalRow={false}
          isShowFilterRow={false}
          header={header}
          data={diffData}
        />
      </div>
    );

    const bottom = (
      <Fragment>
        {btnType !== 'co_update_new' && (
          <label className="fl" style={{ marginTop: '2px' }}>
            <input
              type="checkbox"
              className="fn-checkbox-pure"
              defaultChecked={directToDetail}
              onChange={onChangeRedirect}
            />
            打开{this.props.i18n.get('order', '运单')}详情页
          </label>
        )}
        {isAllowSyncGoodsToTask ? (
          <label className="fl" style={{ marginTop: '2px', marginLeft: '5px' }}>
            <input
              type="checkbox"
              className="fn-checkbox-pure"
              defaultChecked={isSyncGoodsToTask}
              onChange={onChangeSyncGoodsToTask}
            />
            同步货物信息到运单
          </label>
        ) : null}
        <Button type="primary" ref={ref => (this._btnModifyRef = ref)} onClick={check}>
          确定
        </Button>
        <Button onClick={() => modal.handleHide()}>取消</Button>
      </Fragment>
    );
    const title = isBack ? `退货确认<${oldData.order_num.value}>` : '修改信息';
    new PopUp(ModalDialog, {
      content,
      bottom,
      title,
      contentStyle: { width: '500px' },
      isShow: true,
      autoDestroy: true,
      ref: r => (modal = r),
    }).show();
  }
  // 定制短信调取的接口
  sendMsgTpl = async params => {
    const res = await actions.msgTplServer.sendTplMsg(params);
    if (res.data.errno === 0) {
      showInfo(CHECK, '发送成功');
    } else {
      res.data.errmsg && showInfo(ERROR, res.data.errmsg);
    }
    return res.data;
  };
  // 获取短信发送的手机号列表
  msgPhoneList = msgType => {
    const phoneList = [];
    const state = this.store.getState();
    const { corMobile, corPhone, ceeMobile, ceePhone, msgTypeList } = state;
    const typeList = msgType || msgTypeList || [];
    if (typeList.includes('BUS_TRACK_SEND')) {
      const phone = corMobile || corPhone || '';
      phoneList.push(phone);
    }
    if (typeList.includes('BUS_TRACK_RECEIVE')) {
      const phone = ceeMobile || ceePhone || '';
      phoneList.push(phone);
    }
    return phoneList;
  };

  async msgModel(elem) {
    // 短信模版
    const { usedFor } = this.props;
    const state = this.store.getState();
    const { msgTypeList } = state;
    const isDetail = usedFor === 'detail';
    const url = '/Basic/ShortMessage/showTemplate';
    const req = isDetail
      ? { typeList: elem.split(','), from: 'oinfo', od_link_id: state.odLinkId }
      : { typeList: elem.split(',') };
    const conf = { method: 'POST', body: { req } };
    const res = await fetch(url, conf);
    if (res.errno !== 0) return false;

    const data = res.res.data.templateList || [];
    let msgList = [];
    if (msgTypeList) {
      msgList = msgTypeList.map(item => data.filter(dataItem => dataItem.type === item)[0]);
    }

    const submitMsg = async (selected, msgModelDialog) => {
      const els = selected.map(row => row.type);
      const tplId = selected.map(row => row.tpl_id);
      const detailParams = isDetail ? { order_ids: [state.odLinkId] } : { phone_list: this.msgPhoneList(els) };
      const params = [{ tpl_ids: tplId, ...detailParams }];
      // 只有开单页进下面的逻辑
      if (usedFor === 'create') {
        this.set('msgTypeList', els);
        this.set('msgParam', params);
        msgModelDialog.handleHide();
        return;
      }
      if (isDetail && tplId.length && els.length) {
        // 详情页下，校验短信模板是否被停用，或者说未审核短信模板
        const checkTplParam = {
          sign: 'Order',
          req: {
            category: 'Order',
            tab: 'co',
            orderIds: params[0].order_ids,
            tplIds: params[0].tpl_ids,
            typeList: els,
          },
          url: 'Basic/ShortMessage/checkOrderMsgHistory',
        };
        const canSend = await checkMsgHistory(checkTplParam);
        if (!canSend) return false;
        if (canSend && canSend.tpl_ids) params[0].tpl_ids = canSend.tpl_ids;
      }
      // 详情、改单、补录运单等还是走之前的弹框逻辑
      if (!(await checkSend(params))) return;
      this.set('msgTypeList', els);

      if (!isDetail) {
        msgModelDialog.handleHide();
        return;
      }
      const msgRes = isDetail ? await this.sendMsgTpl(params[0]) : await sendMsg(els, state.odLinkId);
      if (+msgRes.errno === 0) {
        msgModelDialog.handleHide();
      }
    };

    new PopUp(MessageTplModal, { data, selected: msgList, onConfirm: submitMsg }).show();
  }

  async print(printItem, asyncData = {}, preview) {
    const state = this.store.getState();
    const { usedFor } = this.props;
    const { printItems, odLinkId, collectPrintTypes, setting, groupId, comId } = state;
    const collectPrint = isCollectPrint(state);
    const printChecked = printItem
      ? [printItem]
      : printItems.filter(item => item.checked && !(collectPrint && collectPrintTypes[item.type]));
    const copies = state.isPointTrans
      ? (state.runSetting && state.runSetting.trans_a_print_copies.value) || 1
      : (state.personSetting && state.personSetting.co_def_val.print_copies.__meta.value) || 1;
    const maxCopies = (setting.print_max && setting.print_max.value) || Infinity;
    const goodsNum = calcField(state.goods, 'num');
    const trayCount = calcField(state.goods, 'tray_count');
    const printTagSerial = setting.print_tag_serial && setting.print_tag_serial.checked;

    // bug 46309
    const minCopies = Math.min(goodsNum, maxCopies);
    const defaultCount = [88892, 349].includes(+window.group_id) ? minCopies : trayCount || minCopies;

    let tagCount;
    let multiTagCount;
    let position = 1;
    let serial = 1;
    const checkLODOPRes = await checkLODOP();
    if (!checkLODOPRes) return false;
    if (!printChecked.length && usedFor === 'tags') printChecked.push({ key: '20', type: 'tag' });
    if (!printChecked.length && !(collectPrint && collectPrintTypes.order))
      printChecked.push({ key: '10', type: 'order' });
    const printFn = async (type, printInfo) => {
      const tplSetting = { ...printInfo.tpl_setting, printCopies: copies };
      const tplData = { ...printInfo.tpl_data[0], ...asyncData };
      const isUTD = tplSetting.category === 0; // 非套打
      if (type === 'tag') {
        if (!tagCount) {
          const result = await userInputPrintCount(type, defaultCount, printTagSerial);
          if (!result) return false;
          tagCount = result.count;
          serial = result.serial;
        }
        // let tagNumStart = serial
        tplData.startIndex = serial;
        tplData.printCopies = tagCount;
        await PrintManager.printTags(window.globalLodopObj, [tplData], tplSetting, tplSetting.detail, false);
      } else if (type === 'multiple_tag') {
        if (!multiTagCount) {
          const result = await userInputPrintCount(type, defaultCount, printTagSerial);
          if (!result) return false;
          multiTagCount = result.count;
          position = result.position;
        }
        tplSetting.startIndex = position || 1;
        tplSetting.printCount = multiTagCount || 1;
        tplData.startIndex = 1;
        tplData.printCopies = multiTagCount || 1;
        await PrintManager.printMultipleTags(window.globalLodopObj, [tplData], tplSetting, tplSetting.detail, false);
      } else if (preview) {
        PrintManager.popUpPrintPreview(window.globalLodopObj, tplData, tplSetting, tplSetting.detail, false);
      } else if (isUTD) {
        const printProxy = {
          LODOP: window.globalLodopObj,
          data: tplData,
          printSetting: tplSetting,
          tplDetail: tplSetting.detail,
          needCheck: false,
          dataSum: printChecked.length,
        };
        await PrintManager.untdTablePrint(printProxy);
      } else {
        const printProxy = {
          LODOP: window.globalLodopObj,
          data: tplData,
          printSetting: tplSetting,
          tplStr: tplSetting.detail,
          needCheck: false,
          dataSum: printChecked.length,
        };
        await PrintManager.print(printProxy);
      }
    };
    for (const item of printChecked) {
      const printInfo = await getPrintInfo(
        this.printerHostId,
        this.printerHostName,
        item,
        odLinkId,
        printFn,
        groupId,
        comId,
      );
      printInfo && (await printFn(item.type, printInfo));
    }
    return true;
  }

  setting(menuItem) {
    if (!menuItem) return false;
    const btnKey = menuItem.key;
    if (btnKey === 'orderSet') {
      directToSetting();
    }
    if (btnKey === 'coPath') {
      const { path: pathSetting } = this.store.getState();
      const settingKey = 'coPath';
      new PopUp(PathSettingModal, { pathSetting, pathMap, settingKey, onSaveSuccess: this.props.onSaveSuccess }).show();
    }
    if (btnKey === 'psnSet') {
      new PopUp(PersonSettingModal, { onSaveSuccess: this.props.onSaveSuccess }).show();
    }
    if (btnKey.includes('skin-')) {
      setPerson({ order_skin: { __meta: { value: btnKey } } });
      this.forceUpdate();
    }
  }

  toggleButton = (btnKey, disabled = true) => {
    const { buttons } = this.store.getState();
    if (!buttons?.[btnKey]) return;
    disabled ? this.beginSave(btnKey, btnKey) : this.endSave(btnKey, btnKey);
  };

  detailOp = async (selected = {}, name, sublist = [], buttonData) => {
    let { key } = selected;
    const { isSublistClick } = selected;
    const len = sublist.length;
    if (isSublistClick && isset(selected, 'menuBtnItemParent.key') === 'switch_tpl') return false;
    if (len && !key) return false;
    if (!len) key = name;
    if (key === 'notice_message') return this.msgModel('BUS_TRACK_SEND,BUS_TRACK_RECEIVE');
    if (key === 'print' || name === 'print')
      return this.print({ ...selected, type: selected.tpl_type, key: selected.tpl_key }, {});
    if (name === 'preview_order' || name === 'order_preview') {
      if (selected.tpl_key === 'default_preview_tpl') {
        this.preview();
      } else {
        this.print({ ...selected, type: selected.tpl_type, key: selected.tpl_key }, {}, true);
      }
      return;
    }
    if (key === 'add_abnormals') key = 'add_abnormal';

    const {
      odLinkId,
      ext: { ol_create_t: createTime },
    } = this.store.getState();
    const pageKey = this.pageKey || (this.pageKey = `order_${+new Date()}`);
    this.toggleButton(key, !this.proxyListPagePromise);
    const proxyListPagePromise = this.proxyListPagePromise || (this.proxyListPagePromise = proxyListPage(odLinkId));
    const listPageProxy =
      this.listPageProxy ||
      (this.listPageProxy = await proxyListPagePromise.finally(() => {
        this.toggleButton(key, false);
      }));
    const listPage = listPageProxy(this, key);
    if (!listPage) return false;
    emitListBtnOp({ listPage, space: pageKey, key, index: 0, col: '', selected, buttonData, name });
  };

  showContextMenu = e => {
    if (!this.contextMenu || e.target.tagName === 'INPUT') return;
    e.preventDefault();
    const filteredButtons = Object.entries(this.store.getState().buttons).filter(([k, v]) => k && v);
    const listData = filteredButtons.map(([key, val]) => {
      const subList = val.children && Object.values(val.children);
      const handler = item => this.detailOp(item, key, subList, val.data);
      return { key, name: { icon: BUTTON_ICON[val.title].iconType, title: val.title }, handler, subList };
    });
    this.contextMenu.handleShow(listData, e.pageX, e.pageY, 171);
  };

  refContextMenu = r => (this.contextMenu = r);

  render() {
    const state = this.store.getState();
    const skin =
      (window.psn_setting.order_skin &&
        window.psn_setting.order_skin.__meta &&
        window.psn_setting.order_skin.__meta.value) ||
      '';
    if (!state.setting) return null;
    if (this.props.usedFor === 'pre' || this.props.usedFor === 'tags') return this.renderPre();
    if (this.props.usedFor === 'scan') return this.renderScan();

    return (
      <div
        className={`${prefixCls} ${this.props.usedFor} ${skin}`}
        ref={this.refWrap}
        onContextMenu={this.showContextMenu}
      >
        <div className={`main-content${state.showMap ? ' show-map' : ''}`}>
          <div className="left">
            {this.renderHeader()}
            <div className="order-content skin-border">
              {this.renderTraffic()}
              {this.renderStateStamp()}
              {this.renderPerson()}
              {this.renderGoods()}
              {state.valueAddedServiceCardShow && this.renderValueAddedService()}
              {this.renderFee()}
              {this.renderExtra()}
              {this.renderInfo()}
              {this.renderProfit()}
              {this.renderImg()}
            </div>
          </div>
          {this.renderMap()}
        </div>
        {this.renderFooter()}
      </div>
    );
  }
}

export default withI18n(OrderEditor);
