import _ from 'lodash';
import { throttle } from 'utils';
import { PAY_MODE_SAME_TO_ORDER } from 'constants';
import {
  SET_ORDER_DATA,
  FETCH_ORDER_DATA,
  SELECT_COR_SUG,
  SELECT_CEE_SUG,
  OUTER_TRANS,
  POINT_TRANS,
  INNER_TRANS,
  onlyShowWhenPickup,
} from '../constant';
import { calcMaintainByUnitPrice, calcMaintainByFormula, calcField, getDistance, weightKG } from '../tool';
import {
  transPayModeRelate,
  getTransPayModeEnum,
  calcTax,
  calcTransFreight,
  calcFeeTotal,
  feeRelate,
  dnComRelate,
  getDefaultTransRoute,
  getInnerRemark,
} from './tool';

const infoMiddleware = page => store => {
  const calcCorMaintain = throttle(() => {
    const state = store.getState();
    const { corMaintainKeys, corMaintainKeyMap, corMaintainByFormula } = state;
    if (!corMaintainByFormula) return;
    const maintain = calcMaintainByFormula(state, corMaintainKeys, corMaintainKeyMap, 'corMaintainRatio');
    page.setTable('stdCost', 0, { std_mn_f: maintain });
  }, 200);

  const calcCeeMaintain = throttle(() => {
    const state = store.getState();
    const { ceeMaintainKeys, ceeMaintainKeyMap, ceeMaintainByFormula } = state;
    if (!ceeMaintainByFormula) return;
    const maintain = calcMaintainByFormula(state, ceeMaintainKeys, ceeMaintainKeyMap, 'ceeMaintainRatio');
    page.setTable('stdCost', 0, { std_cee_mn_f: maintain });
  }, 200);

  const onChange = (next, key, val, changeIndex, changes) => {
    const state = store.getState();
    if (
      (state.corMaintainKeys[key] ||
        key === 'pointCostInfo' ||
        key === 'corMaintainRatio' ||
        key === 'corMaintainByFormula') &&
      !page.edited('std_mn_f', 'std_cost')
    ) {
      calcCorMaintain();
    }
    if (
      (state.ceeMaintainKeys[key] ||
        key === 'pointCostInfo' ||
        key === 'ceeMaintainRatio' ||
        key === 'ceeMaintainByFormula') &&
      !page.edited('std_cee_mn_f', 'std_cost')
    ) {
      calcCeeMaintain();
    }
    switch (key) {
      case 'payMode': {
        // 退货不更新中转付款方式&中转到付
        if (!state.transInfo || state.isBack) return;
        // if (val === 'pay_arrival') return
        const { transInfo } = state;
        transInfo.forEach((row, i) => {
          const transType = +row.trans_type;
          if (!((transType === OUTER_TRANS && !state.isModify) || transType === POINT_TRANS)) {
            if (transType === POINT_TRANS) page.merge('pointTransChange', true);
            return;
          }
          // 手动更新过的不更新, 到付为强制逻辑，不处理是否手动改动
          if (row.isHandle && val !== 'pay_arrival') return;
          // comDefaultPayMode: 承运商的默认付款方式
          const defaultTransPayMode = state.defaultTransPayMode[transType];
          const dnCompany = row.dn_com_id;
          const defaultVal =
            transType === OUTER_TRANS
              ? (dnCompany && dnCompany.default_pay_mode) || defaultTransPayMode
              : defaultTransPayMode;
          if (defaultVal !== PAY_MODE_SAME_TO_ORDER) return;
          const transPayModeEnum = getTransPayModeEnum(state, row);
          // 付款方式不不在可选范围的不处理
          if (!transPayModeEnum.find(item => item.key === val)) return;
          page.setTable('transInfo', i, transPayModeRelate(state, i, val, row, false));
        });
        break;
      }
      case 'payArrival': {
        if (!state.transInfo || state.isBack) return;
        // 更新中转到付
        const { transInfo } = state;
        const payArrival = +val;
        const { transPayList } = state;

        transInfo.forEach((row, i) => {
          const newVal = {};
          const transType = +row.trans_type;
          const transPayMode = row.trans_pay_mode;
          const transFee = +row.trans_f || 0;
          // 运单付款方式非到付 中转付款方式也不能为到付
          if (transType === POINT_TRANS && state.isModify) {
            page.merge('pointTransChange', true);
            return;
          }
          if (
            (transType === INNER_TRANS || transType === POINT_TRANS) &&
            transPayMode === 'pay_arrival' &&
            !payArrival
          ) {
            newVal.trans_pay_mode = '';
            transPayList.forEach(k => k !== 'trans_pay_arrival' && (newVal[k] = ''));
          }

          // 运单付款方式到付 中转付款方式只能为到付
          if (
            (transType === INNER_TRANS || transType === POINT_TRANS) &&
            transPayMode &&
            transPayMode !== 'pay_multi' &&
            transPayMode !== 'pay_arrival' &&
            payArrival
          ) {
            newVal.trans_pay_mode = '';
            transPayList.forEach(k => k !== 'trans_pay_arrival' && (newVal[k] = ''));
          }
          if (transType !== OUTER_TRANS || (transPayMode !== 'pay_multi' && !state.isModify)) {
            newVal.trans_pay_arrival = payArrival;
          }
          const payTotal = transPayList.reduce((total, k) => total + (+newVal[k] || 0), 0);
          if (transType === OUTER_TRANS) {
            newVal.trans_settle_money = payArrival - transFee;
          } else {
            newVal.trans_settle_money = (payTotal - transFee).toFixed(2);
          }
          page.setTable('transInfo', i, newVal);
        });
        break;
      }
      case 'totalPrice': {
        // 重新计算转出方毛利
        const { transInfo } = state;
        transInfo &&
          transInfo.forEach((row, i) => {
            const transFee = +row.trans_f || 0;
            const totalPrice = +val || 0;
            page.setTable('transInfo', i, { trans_profit: totalPrice - transFee });
          });
        if (state.pointCostInfo && state.autoCalcTax && !page.edited('tax', 'point_cost_info')) {
          page.setTable('pointCostInfo', 0, {
            tax: calcTax(state.pointCostInfo[0].tax_r, val, state.taxInc, state.maxRate),
          });
        }
        break;
      }
      case 'taxInc': {
        if (state.pointCostInfo && state.autoCalcTax && !page.edited('tax', 'point_cost_info')) {
          page.setTable('pointCostInfo', 0, {
            tax: calcTax(state.pointCostInfo[0].tax_r, state.totalPrice, val, state.maxRate),
          });
        }
        break;
      }
      case 'corId': {
        // corId 从有值变为空时 手动输入corName的情况 清空业务员
        const { stdCost } = state;
        !val && stdCost && page.setTable('stdCost', 0, { std_mgr_id: '' });
        !val && page.merge('salesmenOfCor', undefined);
        break;
      }
      case 'arrInfo': {
        // 更改中转到站
        const { transInfo } = state;
        const arrInfoAddress = val && val.addr ? val.addr : val;
        transInfo &&
          transInfo.forEach((item, num) => {
            // 中转到站
            const transType = +item.trans_type;
            if (transType === POINT_TRANS && state.isModify) {
              page.merge('pointTransChange', true);
              return;
            }
            let arrivals =
              item.dn_com_id && item.dn_com_id.arrival_infos ? JSON.parse(item.dn_com_id.arrival_infos) : [];
            arrivals = arrivals.map(v => ({ ...v, key: item.reference, name: `${v.city.show_val}` }));
            const citys = arrivals.map(arr => arr.city);
            const { province, city, district, street } = (item.addressCustomized ? item.address : arrInfoAddress) || {};
            const index = citys.findIndex(_.matches({ province, city, district, street }));
            const newVal = {};
            newVal.addressCustomized = item.addressCustomized && index > -1; // 重新定义是否是手动选择的
            if (index > -1) {
              newVal.address = arrivals[index];
              newVal.trans_arr_address = arrivals[index].address && arrivals[index].address.show_val;
              newVal.trans_arr_address_remark = arrivals[index].address_mark;
              newVal.trans_arr_site = arrivals[index].site;
              newVal.trans_arr_phone = arrivals[index].contract_phone;
              newVal.trans_arr_cs_phone = arrivals[index].cs_phone;
            }
            page.setTable('transInfo', num, newVal);
          });
        break;
      }
      case 'goods': {
        // 重量、体积变化，更新标准费用里面的维护费
        // corMaintainByFormula & ceeMaintainByFormula 使用公式计算 见 consignor/middleware
        if (val.length !== state.goods.length || 'weight' in changes || 'volume' in changes) {
          const editedStdMnF = page.edited('std_mn_f', 'std_cost');
          const editedCeeStdMnF = page.edited('std_cee_mn_f', 'std_cost');
          if (editedStdMnF && editedCeeStdMnF) return;
          const weight = weightKG(calcField(val, 'weight'), state.weightUnit);
          const volume = calcField(val, 'volume');
          if (!editedStdMnF && !state.corMaintainByFormula && page.props.usedFor !== 'pointTrans') {
            const stdCorMaintain = calcMaintainByUnitPrice(state.corMaintain, weight, volume);
            page.setTable('stdCost', 0, { std_mn_f: stdCorMaintain });
          }

          if (!editedCeeStdMnF && !state.ceeMaintainByFormula && page.props.usedFor !== 'pointTrans') {
            const stdCeeMaintain = calcMaintainByUnitPrice(state.ceeMaintain, weight, volume);
            page.setTable('stdCost', 0, { std_cee_mn_f: stdCeeMaintain });
          }
        }
        if (state.transInfo) {
          state.transInfo.forEach((row, index) => {
            const newVal = {};
            if (
              !row.trans_type ||
              (state.isModify && (+row.trans_type === POINT_TRANS || +row.trans_type === OUTER_TRANS))
            ) {
              if (+row.trans_type === POINT_TRANS) page.merge('pointTransChange', true);
              return;
            }
            if (changes && (changes.weight || val.length > state.goods.length)) {
              const detail = row.trans_w_detail || [];
              newVal.trans_w_detail = val.map((item, i) =>
                typeof detail[i] === 'string' ? detail[i] : +item.weight || 0,
              );
              newVal.trans_w = +newVal.trans_w_detail
                .reduce((total, w) => total + (+w || 0), 0)
                .toFixed(state.weightUnit === 'kg' ? 3 : 6);
            }
            if (changes && (changes.volume || val.length > state.goods.length)) {
              const ratio = state.transVolumeRatio;
              const detail = row.trans_v_detail || [];
              newVal.trans_v_detail = val.map((item, i) =>
                typeof detail[i] === 'string' ? detail[i] : +((+item.volume || 0) * ratio).toFixed(6),
              );
              newVal.trans_v = +newVal.trans_v_detail.reduce((total, v) => total + (+v || 0), 0).toFixed(6);
            }
            if (changes && (changes.num || val.length > state.goods.length)) {
              newVal.trans_n = calcField(val, 'num');
            }
            if (val.length < state.goods.length) {
              newVal.trans_w_detail = [...(row.trans_w_detail || [])];
              newVal.trans_w_detail.splice(changeIndex, 1);
              newVal.trans_v_detail = [...(row.trans_v_detail || [])];
              newVal.trans_v_detail.splice(changeIndex, 1);
              newVal.trans_w = +newVal.trans_w_detail
                .reduce((total, w) => total + (+w || 0), 0)
                .toFixed(state.weightUnit === 'kg' ? 3 : 6);
              newVal.trans_v = +newVal.trans_v_detail.reduce((total, v) => total + (+v || 0), 0).toFixed(6);
              newVal.trans_n = calcField(val, 'num');
            }
            if (!page.edited('trans_freight_f', 'transInfo', index) && row.billing_method && row.trans_unit_price) {
              const newRow = { ...row, ...newVal };
              newVal.trans_freight_f = newRow.trans_freight_f = calcTransFreight(newRow);
              newVal.trans_freight_f_src = 'goods';
              if (state.transFeeKeys[row.trans_type].trans_freight_f) {
                const transFee = calcFeeTotal(state, newRow);
                const relate = feeRelate(state, newRow, transFee);
                Object.assign(newVal, relate);
              }
            }
            page.setTable('transInfo', index, newVal);
          });
        }
        break;
      }
      case 'corSalesmen': {
        state.stdCost && !val && page.setTable('stdCost', 0, { std_mgr_id: '' });
        break;
      }
      case 'ceeSalesmen': {
        state.stdCost && !val && page.setTable('stdCost', 0, { std_cee_mgr_id: '' });
        break;
      }
      case 'corMaintain': {
        // 重量、体积变化，更新标准费用里面的维护费
        // corMaintainByFormula & corMaintainByFormula 使用公式计算 见 consignor/middleware
        if (page.edited('std_mn_f', 'std_cost')) return;
        const { corMaintainByFormula } = state;
        if (!corMaintainByFormula) {
          const weight = weightKG(calcField(state.goods, 'weight'), state.weightUnit);
          const volume = calcField(state.goods, 'volume');
          const stdCorMaintain = calcMaintainByUnitPrice(val, weight, volume);
          page.setTable('stdCost', 0, { std_mn_f: stdCorMaintain });
        }
        break;
      }
      case 'ceeMaintain': {
        // 重量、体积变化，更新标准费用里面的维护费
        if (page.edited('std_cee_mn_f', 'std_cost')) return;
        const { ceeMaintainByFormula } = state;
        if (!ceeMaintainByFormula) {
          const weight = weightKG(calcField(state.goods, 'weight'), state.weightUnit);
          const volume = calcField(state.goods, 'volume');
          const stdCeeMaintain = calcMaintainByUnitPrice(val, weight, volume);
          page.setTable('stdCost', 0, { std_cee_mn_f: stdCeeMaintain });
        }
        break;
      }
      case 'route': {
        const pointTransIndex = state.transInfo && state.transInfo.findIndex(item => +item.trans_type === POINT_TRANS);
        if (pointTransIndex > -1) {
          if (state.isModify) {
            page.merge('pointTransChange', true);
          } else {
            const row = state.transInfo[pointTransIndex];
            const sup = state.ext.co_distribute_info;
            const dnCom = val.node2 || (sup.length === 1 ? sup[0] : '');
            getDefaultTransRoute(val, sup, state).then(
              route => route && page.setTable('transInfo', pointTransIndex, { trans_route: route }),
            );
            page.setTable('transInfo', pointTransIndex, dnComRelate(dnCom));
            if (dnCom && row.trans_pick_type === 'from_com') {
              const endPoi = state.companyInfo.address && state.companyInfo.address.poi;
              const startPoi = dnCom && dnCom.address && JSON.parse(dnCom.address).poi;
              getDistance(startPoi, endPoi, state.mileRatio).then(
                distance => (state.pointTransCorPickDist || distance) && page.set('pointTransCorPickDist', distance),
              );
            } else {
              state.pointTransCorPickDist && page.set('pointTransCorPickDist', 0);
            }
          }
        }
        break;
      }
      case 'pickup': {
        if (state.isCreate && state.groupId === 4586) {
          const { originPointCostInfoHeader } = state;
          page.merge(
            'pointCostInfoHeader',
            val
              ? originPointCostInfoHeader
              : originPointCostInfoHeader.filter(item => !onlyShowWhenPickup.includes(item.key)),
          );
        }
        break;
      }
      default:
        break;
    }
  };
  return next => action => {
    // console.log(action.payload)
    switch (action.type) {
      case SET_ORDER_DATA:
        onChange(next, action.payload.key, action.payload.val, action.index, action.changes);
        break;
      case SELECT_COR_SUG: {
        const state = store.getState();
        const { data } = action.payload;
        // 选择发货人带出税率
        if (state.pointCostInfo) {
          const newVal = {};
          const taxRate =
            data.invoice_ratio !== undefined && data.invoice_ratio !== null ? data.invoice_ratio : state.taxRate;
          newVal.tax_r = taxRate;
          !page.edited('tax', 'point_cost_info') &&
            state.autoCalcTax &&
            (newVal.tax = calcTax(taxRate, state.totalPrice, state.taxInc, state.maxRate));
          page.setTable('pointCostInfo', 0, newVal);
        }
        // 特殊处理
        const mebKeys = ['cardHolder', 'memberCode', 'contactPhone', 'bankCardNum'];
        // http://w-luodp.chemanman.com:6005//Frontend/hongyan/yundan/start.html#g=1&p=%E5%BC%80%E5%8D%95%E9%A1%B5%E9%80%89%E4%BC%9A%E5%91%98%E5%8F%B7%E4%B8%8D%E6%9B%B4%E6%96%B0%E5%8F%91%E8%B4%A7%E4%BA%BA
        const isSpeaialGroupId = [2078, 1000, 226].includes(+window.group_id);
        // 选择发货人带出业务员
        const { stdCost } = state;
        const salesmen = data.salesmen && data.salesmen.length === 1 ? data.salesmen[0] : '';
        // 特殊集团 选择 会员相关字段时 不更新业务员
        if (!(isSpeaialGroupId && mebKeys.includes(action.payload.key) && state.corName)) {
          stdCost &&
            page.setTable('stdCost', 0, { std_mgr_id: salesmen && { id: salesmen, name: data.salesmen_name[0] } });
        }
        break;
      }
      case SELECT_CEE_SUG: {
        const state = store.getState();
        const { data } = action.payload;

        // 选择收货人带出业务员
        const { stdCost } = state;
        const salesmen = data.salesmen && data.salesmen.length === 1 ? data.salesmen[0] : '';
        stdCost &&
          page.setTable('stdCost', 0, { std_cee_mgr_id: salesmen && { id: salesmen, name: data.salesmen_name[0] } });

        break;
      }
      case FETCH_ORDER_DATA: {
        setTimeout(() => {
          const { tableTabs, innerRemarkInfoHeader } = store.getState();
          if (tableTabs && tableTabs[0] === 'inner_remark' && innerRemarkInfoHeader && innerRemarkInfoHeader.length) {
            getInnerRemark(page);
          }
        });
        break;
      }
      default:
        break;
    }
    next(action);
  };
};

export default infoMiddleware;
