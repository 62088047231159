/**
 * Created by wangnaihe on 2017/6/19.
 */
import React from 'react';
import { PureInput as Input, PureCheckbox as Checkbox } from 'components';
import { FLOAT, MOBILE, FLOAT_P_2, WARN } from 'constants';
import { buildTipMixin, confirm, ot } from 'utils';
import history from 'routes/history';
import PriceSnapshotIcon from 'components/commoncomponents/PriceSnapshotIcon';
import { CO_DELIVERY_FEE_BLUR, paidTip, returnedTip } from '../../constant';
import { required } from '../../tool';
import { calcInsurance } from '../tool';
import { prefixCls } from './index.scss';
import UpgradeAlert from 'components/commoncomponents/upgradeGuideComponents/alert';
import UpgradeMoreButton from 'components/commoncomponents/upgradeGuideComponents/moreButton';
import { calcPriceFee } from 'components/commoncomponents/orderEditor/info/constant';
import { isShowSnapshotIcon } from 'components/commoncomponents/orderEditor/info/tool';

const payAdvPaidDisabledTip = buildTipMixin(`${ot('垫付费')}已员工交账或已收银，无法修改`);

function renderFreightTo(page) {
  const insure = e => {
    const checked = e.target.checked ? 1 : 0;
    !checked && page.merge('needAutoInsure', false);
    page.set('insured', checked);
  };
  const toContract = () => {
    history.pushWithQuery({
      pathname: '/Company/contractContent',
      query: { tabName: '投保须知', name: 'longkun' },
    });
  };
  return function () {
    const { store } = page;
    const state = store.getState();
    const { isCreate, keyMap } = state;
    const {
      totalPrice,
      taxInc,
      taxIncShow,
      taxIncEditable,
      useJAVAPrice,
      priceSnapshot,
      priceSnapshotDelete,
      feeSource,
      odLinkId,
    } = state;
    const { insured, insuredShow, insuredEditable, isLongKun, valueAddedServiceCardShow } = state;
    const { coFreightF, coFreightFShow, coFreightFRequired, coFreightFEditable } = state;
    const { rebate, rebateShow, rebateRequired, rebateEditable } = state;
    const { rebatePaid, rebatePaidShow, rebatePaidEditable } = state;
    const { rebateName, rebateNameShow, rebateNameRequired, rebateNameEditable } = state;
    const { rebatePhone, rebatePhoneShow, rebatePhoneRequired, rebatePhoneEditable, validateRebatePhone } = state;
    const { cashreturn, cashreturnShow, cashreturnRequired, cashreturnEditable, cashOwnBrokerMiddle } = state;
    const { cashreturnPaid, cashreturnPaidShow, cashreturnPaidEditable, scanReceipt } = state;
    const { cashreturnName, cashreturnNameShow, cashreturnNameRequired, cashreturnNameEditable } = state;
    const {
      cashreturnPhone,
      cashreturnPhoneShow,
      cashreturnPhoneRequired,
      cashreturnPhoneEditable,
      validateCashreturnPhone,
    } = state;
    const { discount, discountShow, discountRequired, discountEditable } = state;
    const { discountName, discountNameShow, discountNameRequired, discountNameEditable } = state;
    const { discountPhone, discountPhoneShow, discountPhoneRequired, discountPhoneEditable, validateDiscountPhone } =
      state;
    const { coDeliveryF, coDeliveryFShow, coDeliveryFRequired, coDeliveryFEditable } = state;
    const { coPickupF, coPickupFShow, coPickupFRequired, coPickupFEditable } = state;
    const { coReceiptF, coReceiptFShow, coReceiptFRequired, coReceiptFEditable } = state;
    const { coHandlingF, coHandlingFShow, coHandlingFRequired, coHandlingFEditable } = state;
    const { coUpstairsF, coUpstairsFShow, coUpstairsFRequired, coUpstairsFEditable } = state;
    const { declaredValue, declaredValueShow, declaredValueRequired, declaredValueEditable } = state;
    const {
      coInsurance,
      coInsuranceShow,
      coInsuranceRequired,
      coInsuranceEditable,
      insurSet,
      insurCalcIcon,
      maxDecimal,
    } = state;
    const { coPkgF, coPkgFShow, coPkgFRequired, coPkgFEditable } = state;
    const { coTransF, coTransFShow, coTransFRequired, coTransFEditable } = state;
    const { coInWhF, coInWhFShow, coInWhFRequired, coInWhFEditable } = state;
    const { coPayAdv, coPayAdvShow, coPayAdvRequired, coPayAdvEditable } = state;
    const { coPayAdvPaid, coPayAdvPaidShow, coPayAdvPaidEditable, isModify } = state;
    const { coDeliveryAdv, coDeliveryAdvShow, coDeliveryAdvRequired, coDeliveryAdvEditable } = state;
    const { coStorageF, coStorageFShow, coStorageFRequired, coStorageFEditable } = state;
    const { coInstallF, coInstallFShow, coInstallFRequired, coInstallFEditable } = state;
    const { coMakeF, coMakeFShow, coMakeFRequired, coMakeFEditable } = state;
    const { coDeclareF, coDeclareFShow, coDeclareFRequired, coDeclareFEditable } = state;
    const { coBuildF, coBuildFShow, coBuildFRequired, coBuildFEditable } = state;
    const { coArrServiceF, coArrServiceFShow, coArrServiceFRequired, coArrServiceFEditable } = state;
    const { coMiscF, coMiscFShow, coMiscFRequired, coMiscFEditable } = state;
    const { backF, backFShow, backFEditable } = state;

    const set = (key, val, src) => {
      page.set(key, val, src);

      if (calcPriceFee[keyMap[key]]) {
        feeSource.setSource(keyMap[key], 1);
      }
    };

    const setCashreturnPaid = val => {
      if (val && scanReceipt) {
        confirm(WARN, '此费用项勾选收银复选框后此运单将不可扫码收款，是否确定勾选？').then(result => {
          if (result) {
            set('cashreturnPaid', true);
            const { payBilling, payBillingPaid, payBillingPaidShow, payArrival, payCoDelivery } = state;
            const autoCollect = window.company_setting.auto_collect_v2.checked;
            if (
              !(
                ((!payBillingPaid || (!payBillingPaidShow && !autoCollect)) && +payBilling) ||
                +payArrival ||
                +payCoDelivery
              )
            ) {
              set('scanReceipt', false);
            }
          }
        });
      } else {
        set('cashreturnPaid', val);
      }
    };

    const maxInsuranceBlur = val => {
      if (maxDecimal && +val > +maxDecimal) {
        return `保价费上限${maxDecimal}元！`;
      }
      return '';
    };

    const calcCoInsurance = () => {
      const coInsurEle = page.getEle('co_insurance');
      delete coInsurEle.dataset.isEdited;
      const calcInsurVal = calcInsurance({ declaredValue }, insurSet, maxDecimal, state.corInsuranceRatio);
      calcInsurVal !== coInsurance && set('coInsurance', calcInsurVal);
    };

    const onCoDeliveryFeeBlur = e => store.dispatch({ type: CO_DELIVERY_FEE_BLUR, payload: e.target.value });

    return (
      <div className={prefixCls}>
        <h3>
          <label className="fn-label">{ot('合计运费')}</label>
          <span className="total-price" data-path="total_price">
            {totalPrice}
          </span>
          {taxIncShow && (
            <label className="fn-label tax-inc">
              <Checkbox
                data-path="tax_inc"
                checked={taxInc}
                disabled={!taxIncEditable}
                onChange={e => set('taxInc', e.target.checked)}
              />
              &nbsp;含税
            </label>
          )}
          {insuredShow && isLongKun && (
            <label className="long-kun-insure fn-checkbox-label">
              <Checkbox data-path="insured" checked={insured} disabled={!insuredEditable} onChange={insure} />
              我已阅读并同意<a onClick={toContract}>投保须知</a>
            </label>
          )}
        </h3>
        {coFreightFShow && (
          <div>
            <label className={`fn-label${required(coFreightFRequired)}`}>{ot('开单运费', '运费')}</label>
            <Input
              data-path="co_freight_f"
              value={coFreightF}
              required={coFreightFRequired}
              disabled={!coFreightFEditable}
              onChange={e => set('coFreightF', e.target.value)}
              pattern={FLOAT}
            >
              {!!(useJAVAPrice && !!isShowSnapshotIcon(state, 'coFreightF')) && (
                <PriceSnapshotIcon
                  className="input-icon"
                  data={priceSnapshot.co_freight_f}
                  fee="co_freight_f"
                  type="yd"
                  bizID={odLinkId}
                />
              )}
            </Input>
          </div>
        )}
        {rebateShow && cashOwnBrokerMiddle && (
          <div>
            <label className={`fn-label${required(rebateRequired)}`}>{ot('回扣')}</label>
            <Input
              data-path="rebate"
              value={rebate}
              required={rebateRequired}
              disabled={!rebateEditable}
              onChange={e => set('rebate', e.target.value)}
              pattern={FLOAT}
            >
              {rebatePaidShow && (
                <Checkbox
                  data-path="rebate_paid"
                  checked={rebatePaid}
                  disabled={!rebatePaidEditable}
                  onChange={e => set('rebatePaid', e.target.checked)}
                  {...paidTip}
                />
              )}
            </Input>
          </div>
        )}
        {rebateNameShow && (
          <div>
            <label className={`fn-label${required(rebateNameRequired)}`}>{ot('回扣人')}</label>
            <Input
              data-path="rebate_name"
              value={rebateName}
              required={rebateNameRequired}
              disabled={!rebateNameEditable}
              onChange={e => set('rebateName', e.target.value)}
            />
          </div>
        )}
        {rebatePhoneShow && (
          <div>
            <label className={`fn-label${required(rebatePhoneRequired)}`}>{ot('回扣手机')}</label>
            <Input
              data-path="rebate_phone"
              pattern={validateRebatePhone ? MOBILE : undefined}
              value={rebatePhone}
              required={rebatePhoneRequired}
              disabled={!rebatePhoneEditable}
              onChange={e => set('rebatePhone', e.target.value)}
            />
          </div>
        )}
        {!!((cashreturnShow || cashreturnPaidShow) && cashOwnBrokerMiddle) && (
          <div>
            <label className={`fn-label${required(cashreturnRequired)}`}>{ot('现返')}</label>
            <Input
              data-path="cashreturn"
              required={cashreturnRequired}
              value={cashreturn}
              disabled={!cashreturnEditable}
              onChange={e => set('cashreturn', e.target.value)}
              pattern={FLOAT}
            >
              {cashreturnPaidShow && (
                <Checkbox
                  data-path="cashreturn_paid"
                  checked={cashreturnPaid}
                  disabled={!cashreturnPaidEditable}
                  onChange={e => setCashreturnPaid(e.target.checked)}
                  {...returnedTip}
                />
              )}
              {!!(useJAVAPrice && isShowSnapshotIcon(state, 'cashreturn')) && (
                <PriceSnapshotIcon
                  className="input-icon"
                  data={priceSnapshot.cashreturn}
                  fee="cashreturn"
                  type="yd"
                  bizID={odLinkId}
                />
              )}
            </Input>
          </div>
        )}
        {cashreturnNameShow && (
          <div>
            <label className={`fn-label${required(cashreturnNameRequired)}`}>{ot('现返人')}</label>
            <Input
              data-path="cashreturn_name"
              value={cashreturnName}
              required={cashreturnNameRequired}
              disabled={!cashreturnNameEditable}
              onChange={e => set('cashreturnName', e.target.value)}
            />
          </div>
        )}
        {cashreturnPhoneShow && (
          <div>
            <label className={`fn-label${required(cashreturnPhoneRequired)}`}>{ot('现返手机')}</label>
            <Input
              data-path="cashreturn_phone"
              pattern={validateCashreturnPhone ? MOBILE : undefined}
              value={cashreturnPhone}
              required={cashreturnPhoneRequired}
              disabled={!cashreturnPhoneEditable}
              onChange={e => set('cashreturnPhone', e.target.value)}
            />
          </div>
        )}
        {discountShow && cashOwnBrokerMiddle && (
          <div>
            <label className={`fn-label${required(discountRequired)}`}>{ot('欠返')}</label>
            <Input
              data-path="discount"
              value={discount}
              required={discountRequired}
              disabled={!discountEditable}
              onChange={e => set('discount', e.target.value)}
              pattern={FLOAT}
            >
              {!!(useJAVAPrice && isShowSnapshotIcon(state, 'discount')) && (
                <PriceSnapshotIcon
                  className="input-icon"
                  data={priceSnapshot.discount}
                  fee="discount"
                  type="yd"
                  bizID={odLinkId}
                />
              )}
            </Input>
          </div>
        )}
        {discountNameShow && (
          <div>
            <label className={`fn-label${required(discountNameRequired)}`}>{ot('欠返人')}</label>
            <Input
              data-path="discount_name"
              value={discountName}
              required={discountNameRequired}
              disabled={!discountNameEditable}
              onChange={e => set('discountName', e.target.value)}
            />
          </div>
        )}
        {discountPhoneShow && (
          <div>
            <label className={`fn-label${required(discountPhoneRequired)}`}>{ot('欠返手机')}</label>
            <Input
              data-path="discount_phone"
              pattern={validateDiscountPhone ? MOBILE : undefined}
              value={discountPhone}
              required={discountPhoneRequired}
              disabled={!discountPhoneEditable}
              onChange={e => set('discountPhone', e.target.value)}
            />
          </div>
        )}
        {coDeliveryFShow && (
          <div>
            <label className={`fn-label${required(coDeliveryFRequired)}`}>{ot('开单送货费', '送货费')}</label>
            <Input
              data-path="co_delivery_f"
              value={coDeliveryF}
              required={coDeliveryFRequired}
              disabled={!coDeliveryFEditable}
              onChange={e => set('coDeliveryF', e.target.value)}
              onBlur={onCoDeliveryFeeBlur}
              pattern={FLOAT}
            >
              {!!(useJAVAPrice && isShowSnapshotIcon(state, 'coDeliveryF')) && (
                <PriceSnapshotIcon
                  className="input-icon"
                  data={priceSnapshot.co_delivery_f}
                  fee="co_delivery_f"
                  type="yd"
                  bizID={odLinkId}
                />
              )}
            </Input>
          </div>
        )}
        {coPickupFShow && (
          <div>
            <label className={`fn-label${required(coPickupFRequired)}`}>{ot('开单提货费', '提货费')}</label>
            <Input
              data-path="co_pickup_f"
              value={coPickupF}
              required={coPickupFRequired}
              disabled={!coPickupFEditable}
              onChange={e => set('coPickupF', e.target.value)}
              pattern={FLOAT}
            >
              {!!(useJAVAPrice && isShowSnapshotIcon(state, 'coPickupF')) && (
                <PriceSnapshotIcon
                  className="input-icon"
                  data={priceSnapshot.co_pickup_f}
                  fee="co_pickup_f"
                  type="yd"
                  bizID={odLinkId}
                />
              )}
            </Input>
          </div>
        )}
        {coReceiptFShow && (
          <div>
            <label className={`fn-label${required(coReceiptFRequired)}`}>{ot('开单回单费', '回单费')}</label>
            <Input
              data-path="co_receipt_f"
              value={coReceiptF}
              required={coReceiptFRequired}
              disabled={!coReceiptFEditable}
              onChange={e => set('coReceiptF', e.target.value)}
              pattern={FLOAT}
            >
              {!!(useJAVAPrice && isShowSnapshotIcon(state, 'coReceiptF')) && (
                <PriceSnapshotIcon
                  className="input-icon"
                  data={priceSnapshot.co_receipt_f}
                  fee="co_receipt_f"
                  type="yd"
                  bizID={odLinkId}
                />
              )}
            </Input>
          </div>
        )}
        {coHandlingFShow && (
          <div>
            <label className={`fn-label${required(coHandlingFRequired)}`}>{ot('开单装卸费', '装卸费')}</label>
            <Input
              data-path="co_handling_f"
              value={coHandlingF}
              required={coHandlingFRequired}
              disabled={!coHandlingFEditable}
              onChange={e => set('coHandlingF', e.target.value)}
              pattern={FLOAT}
            >
              {!!(useJAVAPrice && isShowSnapshotIcon(state, 'coHandlingF')) && (
                <PriceSnapshotIcon
                  className="input-icon"
                  data={priceSnapshot.co_handling_f}
                  fee="co_handling_f"
                  type="yd"
                  bizID={odLinkId}
                />
              )}
            </Input>
          </div>
        )}
        {coUpstairsFShow && (
          <div>
            <label className={`fn-label${required(coUpstairsFRequired)}`}>{ot('开单上楼费', '上楼费')}</label>
            <Input
              data-path="co_upstairs_f"
              value={coUpstairsF}
              required={coUpstairsFRequired}
              disabled={!coUpstairsFEditable}
              onChange={e => set('coUpstairsF', e.target.value)}
              pattern={FLOAT}
            >
              {!!(useJAVAPrice && isShowSnapshotIcon(state, 'coUpstairsF')) && (
                <PriceSnapshotIcon
                  className="input-icon"
                  data={priceSnapshot.co_upstairs_f}
                  fee="co_upstairs_f"
                  type="yd"
                  bizID={odLinkId}
                />
              )}
            </Input>
          </div>
        )}
        {!valueAddedServiceCardShow && declaredValueShow && (
          <div>
            <label className={`fn-label${required(declaredValueRequired)}`}>{ot('声明价值')}</label>
            <Input
              data-path="declared_value"
              value={declaredValue}
              required={declaredValueRequired}
              disabled={!declaredValueEditable}
              onChange={e => set('declaredValue', e.target.value)}
              pattern={FLOAT}
            >
              {!!(useJAVAPrice && isShowSnapshotIcon(state, 'declaredValue')) && (
                <PriceSnapshotIcon
                  className="input-icon"
                  data={priceSnapshot.declared_value}
                  fee="declared_value"
                  type="yd"
                  bizID={odLinkId}
                />
              )}
            </Input>
          </div>
        )}
        {coInsuranceShow && (
          <div>
            <label className={`fn-label${required(coInsuranceRequired)}`}>{ot('开单保价费', '保价费')}</label>
            <Input
              data-path="co_insurance"
              value={coInsurance}
              required={coInsuranceRequired}
              disabled={!coInsuranceEditable}
              onChange={e => set('coInsurance', e.target.value)}
              pattern={FLOAT}
              customValidity={maxInsuranceBlur}
            >
              {insurCalcIcon && <i className="input-icon fn-icon fn-icon-calculate" onClick={calcCoInsurance} />}
              {!!(useJAVAPrice && isShowSnapshotIcon(state, 'coInsurance')) && (
                <PriceSnapshotIcon
                  className="input-icon"
                  data={priceSnapshot.co_insurance}
                  fee="co_insurance"
                  type="yd"
                  bizID={odLinkId}
                />
              )}
            </Input>
          </div>
        )}
        {coTransFShow && (
          <div>
            <label className={`fn-label${required(coTransFRequired)}`}>{ot('开单中转费', '中转费')}</label>
            <Input
              data-path="co_trans_f"
              value={coTransF}
              required={coTransFRequired}
              disabled={!coTransFEditable}
              onChange={e => set('coTransF', e.target.value)}
              pattern={FLOAT}
            >
              {!!(useJAVAPrice && isShowSnapshotIcon(state, 'coTransF')) && (
                <PriceSnapshotIcon
                  className="input-icon"
                  data={priceSnapshot.co_trans_f}
                  fee="co_trans_f"
                  type="yd"
                  bizID={odLinkId}
                />
              )}
            </Input>
          </div>
        )}
        {coPkgFShow && (
          <div>
            <label className={`fn-label${required(coPkgFRequired)}`}>{ot('开单包装费', '包装费')}</label>
            <Input
              data-path="co_pkg_f"
              value={coPkgF}
              required={coPkgFRequired}
              disabled={!coPkgFEditable}
              onChange={e => set('coPkgF', e.target.value)}
              pattern={FLOAT}
            >
              {!!(useJAVAPrice && isShowSnapshotIcon(state, 'coPkgF')) && (
                <PriceSnapshotIcon
                  className="input-icon"
                  data={priceSnapshot.co_pkg_f}
                  fee="co_pkg_f"
                  type="yd"
                  bizID={odLinkId}
                />
              )}
            </Input>
          </div>
        )}
        {coInWhFShow && (
          <div>
            <label className={`fn-label${required(coInWhFRequired)}`}>{ot('开单进仓费', '进仓费')}</label>
            <Input
              data-path="co_in_wh_f"
              value={coInWhF}
              required={coInWhFRequired}
              disabled={!coInWhFEditable}
              onChange={e => set('coInWhF', e.target.value)}
              pattern={FLOAT}
            >
              {!!(useJAVAPrice && isShowSnapshotIcon(state, 'coInWhF')) && (
                <PriceSnapshotIcon
                  className="input-icon"
                  data={priceSnapshot.co_in_wh_f}
                  fee="co_in_wh_f"
                  type="yd"
                  bizID={odLinkId}
                />
              )}
            </Input>
          </div>
        )}
        {coPayAdvShow && (
          <div>
            <label className={`fn-label${required(coPayAdvRequired)}`}>{ot('开单垫付费', '垫付费')}</label>
            <Input
              data-path="co_pay_adv"
              value={coPayAdv}
              required={coPayAdvRequired}
              disabled={!coPayAdvEditable}
              onChange={e => set('coPayAdv', e.target.value)}
              pattern={FLOAT}
            >
              {coPayAdvPaidShow && (
                <Checkbox
                  data-path="co_pay_adv_paid"
                  checked={coPayAdvPaid}
                  disabled={!coPayAdvPaidEditable}
                  onChange={e => set('coPayAdvPaid', e.target.checked)}
                  {...(isModify && !coPayAdvPaidEditable ? payAdvPaidDisabledTip : paidTip)}
                />
              )}
              {!!(useJAVAPrice && isShowSnapshotIcon(state, 'coPayAdv')) && (
                <PriceSnapshotIcon
                  className="input-icon"
                  data={priceSnapshot.co_pay_adv}
                  fee="co_pay_adv"
                  type="yd"
                  bizID={odLinkId}
                />
              )}
            </Input>
          </div>
        )}
        {coDeliveryAdvShow && (
          <div>
            <label className={`fn-label${required(coDeliveryAdvRequired)}`}>{ot('开单垫付货款', '垫付货款')}</label>
            <Input
              data-path="co_delivery_adv"
              value={coDeliveryAdv}
              required={coDeliveryAdvRequired}
              disabled={!coDeliveryAdvEditable}
              onChange={e => set('coDeliveryAdv', e.target.value)}
              pattern={FLOAT}
            >
              {!!(useJAVAPrice && isShowSnapshotIcon(state, 'coDeliveryAdv')) && (
                <PriceSnapshotIcon
                  className="input-icon"
                  data={priceSnapshot.co_delivery_adv}
                  fee="co_delivery_adv"
                  type="yd"
                  bizID={odLinkId}
                />
              )}
            </Input>
          </div>
        )}
        {coStorageFShow && (
          <div>
            <label className={`fn-label${required(coStorageFRequired)}`}>{ot('开单保管费', '保管费')}</label>
            <Input
              data-path="co_storage_f"
              value={coStorageF}
              required={coStorageFRequired}
              disabled={!coStorageFEditable}
              onChange={e => set('coStorageF', e.target.value)}
              pattern={FLOAT}
            >
              {!!(useJAVAPrice && isShowSnapshotIcon(state, 'coStorageF')) && (
                <PriceSnapshotIcon
                  className="input-icon"
                  data={priceSnapshot.co_storage_f}
                  fee="co_storage_f"
                  type="yd"
                  bizID={odLinkId}
                />
              )}
            </Input>
          </div>
        )}
        {coInstallFShow && (
          <div>
            <label className={`fn-label${required(coInstallFRequired)}`}>{ot('开单安装费', '安装费')}</label>
            <Input
              data-path="co_install_f"
              value={coInstallF}
              required={coInstallFRequired}
              disabled={!coInstallFEditable}
              onChange={e => set('coInstallF', e.target.value)}
              pattern={FLOAT}
            >
              {!!(useJAVAPrice && isShowSnapshotIcon(state, 'coInstallF')) && (
                <PriceSnapshotIcon
                  className="input-icon"
                  data={priceSnapshot.co_install_f}
                  fee="co_install_f"
                  type="yd"
                  bizID={odLinkId}
                />
              )}
            </Input>
          </div>
        )}
        {coMakeFShow && (
          <div>
            <label className={`fn-label${required(coMakeFRequired)}`}>{ot('开单制单费', '制单费')}</label>
            <Input
              data-path="co_make_f"
              value={coMakeF}
              required={coMakeFRequired}
              disabled={!coMakeFEditable}
              onChange={e => set('coMakeF', e.target.value)}
              pattern={FLOAT_P_2}
              maxLength="20"
            >
              {!!(useJAVAPrice && isShowSnapshotIcon(state, 'coMakeF')) && (
                <PriceSnapshotIcon
                  className="input-icon"
                  data={priceSnapshot.co_make_f}
                  fee="co_make_f"
                  type="yd"
                  bizID={odLinkId}
                />
              )}
            </Input>
          </div>
        )}
        {coDeclareFShow && (
          <div>
            <label className={`fn-label${required(coDeclareFRequired)}`}>{ot('开单报关费', '报关费')}</label>
            <Input
              data-path="co_declare_f"
              value={coDeclareF}
              required={coDeclareFRequired}
              disabled={!coDeclareFEditable}
              onChange={e => set('coDeclareF', e.target.value)}
              pattern={FLOAT_P_2}
              maxLength="20"
            >
              {!!(useJAVAPrice && isShowSnapshotIcon(state, 'coDeclareF')) && (
                <PriceSnapshotIcon
                  className="input-icon"
                  data={priceSnapshot.co_declare_f}
                  fee="co_declare_f"
                  type="yd"
                  bizID={odLinkId}
                />
              )}
            </Input>
          </div>
        )}
        {coBuildFShow && (
          <div>
            <label className={`fn-label${required(coBuildFRequired)}`}>{ot('开单港建费', '港建费')}</label>
            <Input
              data-path="co_build_f"
              value={coBuildF}
              required={coBuildFRequired}
              disabled={!coBuildFEditable}
              onChange={e => set('coBuildF', e.target.value)}
              pattern={FLOAT_P_2}
              maxLength="20"
            >
              {!!(useJAVAPrice && isShowSnapshotIcon(state, 'coBuildF')) && (
                <PriceSnapshotIcon
                  className="input-icon"
                  data={priceSnapshot.co_build_f}
                  fee="co_build_f"
                  type="yd"
                  bizID={odLinkId}
                />
              )}
            </Input>
          </div>
        )}
        {coArrServiceFShow && (
          <div className="co-arr-service-f">
            <label className={`fn-label${required(coArrServiceFRequired)}`}>{ot('到付服务费', '到付服务费')}</label>
            <Input
              data-path="co_arr_service_f"
              value={coArrServiceF}
              required={coArrServiceFRequired}
              disabled={!coArrServiceFEditable}
              onChange={e => set('coArrServiceF', e.target.value)}
              pattern={FLOAT_P_2}
            >
              {!!(useJAVAPrice && isShowSnapshotIcon(state, 'coArrServiceF')) && (
                <PriceSnapshotIcon
                  className="input-icon"
                  data={priceSnapshot.co_arr_service_f}
                  fee="co_arr_service_f"
                  type="yd"
                  bizID={odLinkId}
                />
              )}
            </Input>
          </div>
        )}
        {coMiscFShow && (
          <div>
            <label className={`fn-label${required(coMiscFRequired)}`}>{ot('开单其他费', '其他费')}</label>
            <Input
              data-path="co_misc_f"
              value={coMiscF}
              required={coMiscFRequired}
              disabled={!coMiscFEditable}
              onChange={e => set('coMiscF', e.target.value)}
              pattern={FLOAT}
            >
              {!!(useJAVAPrice && isShowSnapshotIcon(state, 'coMiscF')) && (
                <PriceSnapshotIcon
                  className="input-icon"
                  data={priceSnapshot.co_misc_f}
                  fee="co_misc_f"
                  type="yd"
                  bizID={odLinkId}
                />
              )}
            </Input>
          </div>
        )}
        {backFShow && (
          <div>
            <label className="fn-label">{ot('退货费')}</label>
            <Input
              data-path="back_f"
              value={backF}
              disabled={!backFEditable}
              onChange={e => set('backF', e.target.value)}
              pattern={FLOAT}
            >
              {!!(useJAVAPrice && isShowSnapshotIcon(state, 'backF')) && (
                <PriceSnapshotIcon
                  className="input-icon"
                  data={priceSnapshot.back_f}
                  fee="back_f"
                  type="yd"
                  bizID={odLinkId}
                />
              )}
            </Input>
          </div>
        )}

        {window.simple_version ? (
          <>
            <div>
              <UpgradeMoreButton
                text="自定义费用"
                guideOptions={{
                  name: '自定义费用',
                  place: `${isCreate ? '创建订单' : '订单详情'}-自定义费用`,
                  guideImage: 'custom_fee.png',
                }}
              />
            </div>

            <UpgradeAlert
              text="自动计算运费，立即解放双手"
              guideOptions={{
                name: '价格计算',
                place: `${isCreate ? '创建订单' : '订单详情'}-应收价格计算`,
                guideImage: 'price_calc.png',
                video: 'https://static.g7cdn.com/fe-cdn/20230426-201038.mp4',
                videoStyle: {
                  width: '416px',
                  height: '491px',
                },
              }}
            />
          </>
        ) : null}
      </div>
    );
  };
}

export default renderFreightTo;
