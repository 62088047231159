/**
 * Created by wangnaihe on 2017/6/19.
 */
import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import URI from 'urijs';
import { formatCamelCase, convertEnum, formatEnum, formatArrayToObject, hasPermission, ot } from 'utils';
import { Shortcuts } from 'components';
import { btnSetDef } from './personSetting/constant';
import { hackProductType, renderArrInfoAddressCol } from './traffic/tool';
import {
  showOfSetting,
  requiredOfSetting,
  convertWeight,
  switchWeight,
  getPreDate,
  postponeDate,
  ary2Obj,
  buildPrintItem,
  getCeeRelateArrPoint,
  transferPOI,
  matchProfitFormula,
  formatFormula,
} from './tool';
import {
  effectGoodsNumKeyMap,
  tableInfo,
  routeHeader,
  pointHeader,
  deliveryModeEnum,
  directDelivery,
  withoutArr,
  scanShowHide,
  INNER_TRANS,
  POINT_TRANS,
  OUTER_TRANS,
  innerRemarkHeader,
  onlyShowWhenPickup,
  transModeEnum,
  serviceTypeEnum,
  urgencyEnum,
} from './constant';
import { defaultI18n } from 'utils/i18n/context';
import feeSource from 'utils/feeSource';
import { dangerGoodsFields } from 'constants/setting/rosSetting';

function adaptor(payload) {
  const queryInfo = URI.parseQuery(new URI().query());
  const { usedFor, i18n = defaultI18n } = payload;
  const res = payload.data;
  const data = res.res;
  const req = res.req || {};
  const modifyFrom = req.modify_from;
  const reservationNum = req.reservation_num;
  const tpl = req.order_tp_id;
  const isCreate = data.src === 'co';
  const isDetail = data.src === 'oinfo';
  const isPointTrans = data.src === 'pointTrans';
  const isOuterTrans = usedFor === 'outerTransDeal';
  const isModify = usedFor === 'modify';
  const isPre = usedFor === 'pre' || usedFor === 'tags';
  const isScan = usedFor === 'scan';
  const isUpdatePre = usedFor === 'updatePre';
  const isCopy = usedFor === 'copy';
  const { isReservation } = data;
  const isBack = req.back;
  const enums = data.enum || {};
  const order = data.order_data;
  const setting = data.co_setting;
  const runSetting = data.run_set;
  const personSetting = data.psn_setting.setting;
  const transSetting = data.trans_setting;
  const financeSetting = data.finance_setting;
  const cutPaymentSetting = data.cut_payment_set;
  const basicSetting = data.basic_setting;
  const comSetting = window.company_setting;
  const { co } = setting.co; // 开单显示必填设置
  const pre = setting.empty_pre_order.empty_pre; // 空白单显示必填设置
  const tags = setting.tags_pre_order.tags_pre; // 预打标签显示必填设置
  const gSugSet = setting.good_sug.sug_list; // 货物sug显示带入设置
  const goodsSugSetting = { ...gSugSet, unit_p_unit: gSugSet.unit_p, spec: gSugSet.sped, name: gSugSet.g_name };
  // eslint-disable-next-line no-nested-ternary
  const showHideSetting = usedFor === 'pre' ? pre : usedFor === 'tags' ? tags : usedFor === 'scan' ? scanShowHide : co;
  const defaultValue = personSetting.co_def_val;
  const { ext } = data;
  const odLinkId = ext.od_link_id;

  // http://issues.chinawayltd.com/browse/CYTRD-3799
  // 勾选了改单时同步货物到配载的运单，需要运单 id
  let odBasicId = ext.od_basic_id;
  if (!odBasicId) {
    odBasicId = queryInfo.odBasicId;
  }
  const cid = data.company_id;
  const createCid = ext.co_point || cid; // 初始创建运单的网点
  const isCoPointOrParent = ext.is_co_point_or_parent; // 当前网点是否是开单网点或者上级
  const msgTypeList = data.msgTypeList || [];
  const weightUnit = setting.weight_unit.value === 'KG' ? 'kg' : '吨';

  const warnLimitCoSave = setting.warn_limit_co_save;
  const warnLimitCoSaveVal = warnLimitCoSave.value || [];
  const profitLt0Limit = warnLimitCoSaveVal.includes('profit_lt_0');
  const useJAVAPrice = basicSetting.use_java_price && basicSetting.use_java_price.checked;

  const defaultOf = key =>
    (defaultValue[key] && defaultValue[key].__meta.value) || (setting[key] && setting[key].value);
  const headerOf = key => {
    if (!data[key]) return;
    const header = Object.entries(data[key].header || {}).map(([k, v]) => ({ ...v, key: k }));
    const showHeader = header.filter(item => item.display === 'show');
    showHeader.forEach((item, i) => !isDetail && item.width && (showHeader[i].style = { minWidth: `${item.width}px` }));
    return showHeader;
  };
  const dataOf = (key, dft) => data[key] && (data[key].data && data[key].data.length ? data[key].data : [dft || {}]);
  const table = (key, dft) => {
    const newKey = formatCamelCase(key);
    return {
      [newKey]: dataOf(key, dft),
      [`${newKey}Header`]: headerOf(key),
    };
  };

  const companyInfo = ext.co_company_info && { ...ext.co_company_info, company_id: ext.co_company_info.id };
  const hasCashier = companyInfo && +companyInfo.has_cashier;

  try {
    const com = ext.co_company_info;
    com && com.address && (companyInfo.addr = companyInfo.address = JSON.parse(com.address));
  } catch (err) {
    console.log(err);
  }

  const shortcuts = (personSetting.psn_short_keys && personSetting.psn_short_keys.__meta) || {};
  !('cor_name_log' in shortcuts) && (shortcuts.cor_name_log = 'f2');
  !('cee_name_log' in shortcuts) && (shortcuts.cee_name_log = 'f3');
  const shortcutsDisplay = Object.entries(shortcuts).reduce(
    (all, [k, v]) => ({ ...all, [k]: Shortcuts.format(v) }),
    {},
  );
  const autoActiveFirstOption = setting.key_list || {};

  // 价格模式
  const priceMode = ext.price_mode;
  // 扣款设置检查 通过才计算价格
  const isOpenPayment =
    cutPaymentSetting && cutPaymentSetting.is_open_payment && cutPaymentSetting.is_open_payment.checked;
  const actualRouteSettle =
    cutPaymentSetting && cutPaymentSetting.actual_route_settle && !cutPaymentSetting.actual_route_settle.checked;
  const calcInnerPrice = !isPre && isOpenPayment && actualRouteSettle;

  const productType = hackProductType(data);
  const declareRemark = setting.declare_remark.text || '';
  const declareRemarkMoney = setting.declare_remark.money;
  const receiptRemark = setting.receipt_remark.text || '';
  const deliveryRemark = setting.delivery_remark.text || '';
  const deliveryRemarkMoney = setting.delivery_remark.money;
  const deliveryModeRemark = setting.delivery_mode_remark.text;
  const remarkWhenDeliveryModeIs = setting.delivery_mode_remark.value.reduce((all, k) => ({ ...all, [k]: 1 }), {});
  const trspModeEnum = setting.transport_mode_list.selc_value || [];

  const defaultServiceType = defaultOf('service_type');
  const defaultDeliveryMode = defaultOf('delivery_way');
  const defaultTransMode = defaultOf('trans_mode');
  const defaultName = defaultOf('g_name_def');
  const defaultPkg = defaultOf('g_pkg_def');
  const defaultNum = defaultOf('num');
  const defaultUnitPrice = defaultOf('u_price');
  const defaultUnit = defaultOf('unit_price');
  const defaultGoods = {
    id: '',
    name: defaultName,
    pkg: defaultPkg,
    num: defaultNum,
    unit_p: defaultUnitPrice,
    unit_p_unit: defaultUnit,
  };
  const defaultPayMode = defaultOf('payment');
  const defaultPaymentMode = defaultOf('pm_mode');
  const defaultDeclareValue = defaultOf('declare_value');
  const defaultCoInsurance = defaultOf('co_insurance');
  const defaultCoMakeF = defaultOf('co_make_f');
  const defaultReceiptType = defaultOf('receipt_type');
  const defaultReceiptNum = defaultOf('receipt_num');

  const receiptNDefSet = setting.receipt_n_deft || {};
  const defaultReturnPayNum = receiptNDefSet.value || '';
  const receiptPayments = receiptNDefSet.select || [];
  const defaultReceiptN =
    // eslint-disable-next-line no-nested-ternary
    isCreate && !isReservation
      ? receiptPayments.includes(defaultPayMode)
        ? defaultReturnPayNum
        : defaultReceiptNum
      : '';

  const defaultReceiptRequire = setting.receipt_require_type && setting.receipt_require_type.value;
  const defaultRemark = [defaultDeclareValue > declareRemarkMoney && declareRemark, +defaultReceiptN && receiptRemark];
  const defaultTransportModeOfSetting = defaultOf('transport_type');
  const defaultTransportMode = trspModeEnum.find(item => item.value === defaultTransportModeOfSetting)
    ? defaultTransportModeOfSetting
    : '';
  const defaultInnerTransPay = transSetting ? transSetting.def_ts_in_pay.values : '';
  const defaultPointTransPay = transSetting ? transSetting.def_ts_in_p_pay.values : '';
  const defaultOuterTransPay = transSetting ? transSetting.def_ts_o_pay.values : '';
  const defaultPriceMode = cutPaymentSetting ? cutPaymentSetting.default_price_pattern.value : '';
  const defaultVipDelivery = defaultOf('vip_delivery');
  const defaultGoodsCount = defaultOf('goods_count') || 1;

  const orderGoods = (order.goods || []).map(item => {
    const newItem = Object.entries(item).reduce((allSet, [k, v]) => ({ ...allSet, [k]: v && v.value }), {});
    if (newItem.special && !Array.isArray(newItem.special)) {
      newItem.special = [newItem.special];
    }
    return newItem;
  });
  const goods =
    // eslint-disable-next-line no-nested-ternary
    order.goods && order.goods.length
      ? weightUnit === '吨'
        ? convertWeight(orderGoods)
        : orderGoods
      : Array.from({ length: defaultGoodsCount }, () => defaultGoods);
  order.actual_weight && weightUnit === '吨' && (order.actual_weight.value = switchWeight(order.actual_weight.value));
  order.shortfall_weight &&
    weightUnit === '吨' &&
    (order.shortfall_weight.value = switchWeight(order.shortfall_weight.value));
  const base = { ...order, ...(order.goods && order.goods[0]) };
  const groupId = +window.group_id;

  const textMap = {};
  const validateMap = {};
  const keyMap = {};
  const getMap = {};
  const camelKey = {};
  const hide = ext.perm_hide || {};
  const val = (key, dft = '') =>
    isDetail || isModify ? base[key] && base[key].value : (base[key] && base[key].value) || dft;
  const editable = key => {
    // 9901-如果是复制订单进入且是脱敏字段，返回true可编辑
    if (isCopy && hide[key]) {
      return true;
    } else {
      return !hide[key] && !isDetail && (!base[key] || base[key].editable === undefined || base[key].editable);
    }
  };

  const requiredOfShowHideSetting = requiredOfSetting(showHideSetting);
  const required = isOuterTrans ? key => editable(key) && requiredOfShowHideSetting(key) : requiredOfShowHideSetting;
  const show = showOfSetting(showHideSetting);
  const goodsSugShow = showOfSetting(goodsSugSetting);
  const field = (key, text, validate, get, dft, reset) => {
    const newKey = formatCamelCase(key);
    textMap[newKey] = ot(text);
    keyMap[newKey] = camelKey[newKey] = key;
    keyMap[key] = newKey;
    validate && (validateMap[newKey] = validate);
    get && (getMap[newKey] = get);
    return {
      [newKey]: reset ? _.clone(val(key, dft)) : val(key, dft),
      [`${newKey}Editable`]: editable(key),
      [`${newKey}Required`]: required(key),
      [`${newKey}Show`]: show(key),
    };
  };
  transferPOI(val('start_info'));
  transferPOI(val('arr_info'));
  transferPOI(val('cor_addr_info'));
  transferPOI(val('cee_addr_info'));
  const transDeliveryMust = transSetting ? transSetting.trans_delivery_f_must.values : [];
  const outerTransFieldSetting = formatArrayToObject(
    runSetting ? runSetting.outer_trans_field_setting.value : [],
    'key',
  );
  const innerTransFieldSetting = formatArrayToObject(
    runSetting ? runSetting.inner_trans_field_setting.value : [],
    'key',
  );
  const pointTransFieldSetting = formatArrayToObject(
    runSetting ? runSetting.point_trans_field_setting.value : [],
    'key',
  );

  const orderNumNoModify = setting.order_num.no_modify;
  const orderNumCheck = setting.order_num.checked;

  const oNumRuleCheck = setting.order_num.checked;
  const oNumRuleMdAutoBy = setting.order_num.modify_auto_by;
  const manualNumVal = false; // bug37331 ((oNumRuleCheck === 'auto') && (oNumRuleMdAutoBy === 'origin'))

  const isPrevDate = setting.previous_date && setting.previous_date.checked && localStorage.prevDate === 'true';
  const defaultBillingDate = val('billing_date') || moment().format('YYYY-MM-DD HH:mm:ss');
  const postponeBillingDate = setting.postpone_billing_date;
  const postponeBillingDateWhen = postponeBillingDate.when;
  const postponeBillingDateTo = postponeBillingDate.to;
  const checkSaturday = postponeBillingDateWhen.includes('saturday');
  const checkSunday = postponeBillingDateWhen.includes('sunday');
  const checkOverCutOffTime = postponeBillingDateWhen.includes('over_cut_off_time');
  const prevDate = getPreDate(defaultBillingDate);
  const postponedDate = postponeDate(defaultBillingDate, checkSaturday, checkSunday, postponeBillingDateTo);
  // eslint-disable-next-line no-nested-ternary
  const billingDate = isCreate ? (isPrevDate ? prevDate : postponedDate) : defaultBillingDate;
  const isToday = billingDate.slice(0, 10) === moment().format('YYYY-MM-DD') ? 'is-today' : 'not-today';
  const dateRange = setting.in_days && setting.in_days.value;
  const minDate = moment()
    .subtract(+dateRange, 'days')
    .startOf('day')
    .valueOf();
  const maxDate = moment()
    .add(+dateRange, 'days')
    .startOf('day')
    .valueOf();

  const baseEnumArrPoint = base.base_enum.arr_point;
  const defaultArrPoint = (isCreate && getCeeRelateArrPoint(ext)) || val('arr_point_default');
  const arrPoint =
    // eslint-disable-next-line no-nested-ternary
    defaultArrPoint && defaultArrPoint.company_id
      ? defaultArrPoint
      : baseEnumArrPoint
      ? { ...baseEnumArrPoint, company_id: baseEnumArrPoint.id, name: baseEnumArrPoint.display }
      : '';
  const ceeRelateArrPoint = usedFor !== 'scan' && setting.relate_arr_point && setting.relate_arr_point.value === 'cee';
  const arrOnlyRoute = setting.arr_only_route_new && setting.arr_only_route_new.only_route;
  const startArrNeedPoi = setting.start_in_divi && setting.start_in_divi.checked;
  const arrNoRelation = setting.dest_drop_hide_upper_route.isChecked; // 到站不关联上级行政区划

  const routeShow = show('route');
  const arrPointShow = show('arr_point');
  const addressLibrary = comSetting?.address_library?.values;
  const arrInfoIsDataList = !ceeRelateArrPoint && (arrPointShow || routeShow) && !addressLibrary;
  const arrPointHeader = [
    { key: 'company_code', title: '网点代码' },
    { key: 'short_name', title: '网点简称' },
    { key: 'trans_mode_name', title: '中转方式' },
    ...(routeShow ? routeHeader : []),
    ...(!routeShow ? pointHeader : []),
  ];

  const arrInfoHeader = [
    { title: '到站', render: renderArrInfoAddressCol },
    ...(arrPointShow ? [{ key: 'short_name', title: '目的网点' }] : []),
    ...(arrPointShow || routeShow ? [{ key: 'trans_mode_name', title: '中转方式' }] : []),
    ...(routeShow ? routeHeader : []),
  ];

  // 组合路由字段
  const route = {
    route_id: val('route_id', 0),
    route_name: val('route_name'),
    route_nick: val('route_nick'),
    route: val('route', []),
  };

  const routeEditable = editable('route');

  const productTypeShowPrice =
    (basicSetting.product_type_show_price && basicSetting.product_type_show_price.value) || [];
  const productTypeHeader =
    useJAVAPrice && productTypeShowPrice.length
      ? [
          { key: 'name', title: ot('产品类型') },
          ...(productTypeShowPrice.includes('budget_trans_f')
            ? [{ key: 'budget_trans_f', title: ot('预算中转费') }]
            : []),
          ...(productTypeShowPrice.includes('budget_delivery_f')
            ? [{ key: 'budget_delivery_f', title: ot('预算送货费') }]
            : []),
        ]
      : undefined;

  const insurSet = setting.decimal_price || {};
  const commissionSetting = setting.commission;

  const rcvStn = base.base_enum.rcv_stn;

  const valueAddedServiceCardShow = show('value_add_service_card');

  const xpcdArrDateConsist = setting.xpcd_arr_date_consist;
  const xpcdArrDateConsistBegin = xpcdArrDateConsist.begin;
  const xpcdArrDateConsistDelta = xpcdArrDateConsist.delta;
  const postponeXpcdArrDate = setting.postpone_xpcd_arr_date;
  const postponeXpcdArrDateWhen = postponeXpcdArrDate.when;
  const postponeXpcdArrDateTo = postponeXpcdArrDate.to;
  const xpcdArrDateCheckSaturday = postponeXpcdArrDateWhen.includes('saturday');
  const xpcdArrDateCheckSunday = postponeXpcdArrDateWhen.includes('sunday');
  const checkOverDeliveryTime = postponeXpcdArrDateWhen.includes('over_delivery_time');

  const shudGTEXpcd = setting.shud_gte_xpcd.checked;

  const optionalDeliveryMode = setting.optional_delivery_mode.options;
  const deliveryMode = val(
    'delivery_mode',
    optionalDeliveryMode.includes(defaultDeliveryMode) ? defaultDeliveryMode : '',
  );
  const transMode = val('trans_mode');
  const isTrans = transMode && +transMode !== 3;
  const deliveryModeShowWhenModify = v =>
    routeEditable || deliveryMode === v || !directDelivery[v] || (withoutArr[v] && !isTrans);
  const deliveryFilter = v => optionalDeliveryMode.includes(v) && (!isModify || deliveryModeShowWhenModify(v));
  const optionalDeliveryModeEnum = deliveryModeEnum.filter(item => deliveryFilter(item.key));
  remarkWhenDeliveryModeIs[deliveryMode] && deliveryModeRemark && defaultRemark.push(deliveryModeRemark);

  // 是否是送货
  const isDelivery = deliveryMode !== 'self_pick';
  // 送货必填
  const deliveryNeed = !isPre && setting.delivery_need ? setting.delivery_need.value : [];
  const needCeeAddress = deliveryNeed.includes('cee_adr');
  const needDeliveryFee = deliveryNeed.includes('delivery_fee');

  // 送货方式快捷键
  const deliveryShortcutsOfSetting = personSetting.delivery_keys ? personSetting.delivery_keys.__meta : {};
  const deliveryShortcuts = Object.entries(deliveryShortcutsOfSetting).reduce(
    (allSet, [k, v]) => ({ ...allSet, [v]: k }),
    {},
  );

  // 提货必填
  const isPickup = !!val('pickup');
  const pickupNeed = !isPre && setting.door_pick_need ? setting.door_pick_need.value : [];
  const needCorAddress = pickupNeed.includes('cor_adr');
  const needPickupFee = pickupNeed.includes('pick_fee');

  // 送货方式显示位置
  const deliveryModeTop = !valueAddedServiceCardShow && setting.delivery_pos.position === 'top_router';
  const deliveryModeBottom = !valueAddedServiceCardShow && setting.delivery_pos.position === 'botm_trans';

  const needTruck = !isPre && setting.tr_type_length_enable && setting.tr_type_length_enable.tr_type_length_check;
  const isDirectTruck = deliveryMode === 'direct_complete';

  // 自提运单送货费>0时 提醒且允许保存运单
  const warnAllowSave = setting.warn_allow_co_save ? setting.warn_allow_co_save.value : [];
  const warnAllowCheck = warnAllowSave.includes('self_d_gt_0');

  // 车型枚举
  const truckTypeEnum = basicSetting ? basicSetting.truck_type.selc_value : [];
  // 车长枚举
  const truckLengthEnum = basicSetting ? basicSetting.truck_length.selc_value : [];

  const goodsNumCustom = setting.goods_num.checked === 'custom';
  const autoIncrementByArrPoint = setting.goods_num.auto_by === 'arr_p';
  const goodsNumRule = setting.goods_num.selected || {};
  const effectGoodsNumKeys = Object.keys(goodsNumRule).reduce(
    (allSet, key) => (effectGoodsNumKeyMap[key] ? { ...allSet, [effectGoodsNumKeyMap[key]]: 1 } : allSet),
    {},
  );
  autoIncrementByArrPoint && (effectGoodsNumKeys.arrPoint = 1);
  // 会员卡sug表头
  const autoShowDistribute = setting.co_delivery_auto_bounce && setting.co_delivery_auto_bounce.auto_bounce;
  const memberCodeType = /* ext.member_code_diy ? 'member_code_diy' : */ 'member_code';
  const memberCodeHeader = [
    // { key: 'company_name', title: '所属组织' },
    { key: memberCodeType, title: '会员号' },
    { key: 'account_holder', title: '开户人' },
    { key: 'account_holder_phone', title: '开户电话' },
    { key: 'bank_card_num', title: '银行卡号' },
    { key: 'shipper_phone', title: '联系电话' },
  ];
  const cashHead = [
    { key: 'company_name', title: '所属组织' },
    { key: memberCodeType, title: '会员号' },
    { key: 'account_holder', title: '联系人' },
    { key: 'account_holder_phone', title: '开户电话' },
    { key: 'payee_id_card', title: '身份证号' },
    { key: 'shipper_phone', title: '联系电话' },
  ];
  const bankHead = memberCodeHeader;

  // 收发货方sug表头
  const corSugShow = setting.cor_sug_show && setting.cor_sug_show.cor_sug_show_val;
  const ceeSugShow = setting.cor_sug_show && setting.cee_sug_show.cee_sug_show_val;

  const allPersonHeader = type => [
    { key: 'company_name', title: '所属组织' },
    { key: 'rap_short_name', title: '归属目的网点' },
    { key: 'customer_cname', title: '单位' },
    { key: 'name', title: type === 'cor' ? '发货人' : '收货人' },
    { key: 'telephone', title: '手机', style: { minWidth: '130px', maxWidth: '130px' } },
    { key: 'addressShow', title: '地址' },
    { key: 'phone', title: '电话', style: { minWidth: '130px', maxWidth: '130px' } },
    { key: 'customer_no', title: '客户编号' },
    { key: 'industry', title: '客户行业' },
    { key: 'customer_m_code', title: '助记码' },
    { key: 'is_month', title: '月结' },
    { key: 'salesmen_name', title: '业务员' },
    { key: 'address_remark', title: '地址备注' },
  ];
  // 发货方sug header 全集
  const allCorHeader = [
    { key: 'name', title: '发货人' },
    { key: 'telephone', title: '发货手机', style: { minWidth: '130px', maxWidth: '130px' } },
    { key: 'customer_cname', title: '发货单位' },
    { key: 'phone', title: '发货电话', style: { minWidth: '130px', maxWidth: '130px' } },
    { key: 'member_code', title: '会员号' },
    { key: 'bank_name', title: '开户行' },
    { key: 'bank_card', title: '银行卡号' },
    { key: 'account_holder', title: '开户人' },
    { key: 'account_holder_phone', title: '开户电话' },
    { key: 'addressShow', title: '发货地址' },
    { key: 'address_remark', title: '地址备注' },
    { key: 'industry', title: '客户行业' },
    // { key: 'cor_id_num', title: '身份证号' },
    // { key: 'rap_short_name', title: '归属目的网点' },
    { key: 'id_num', title: '身份证号' },
    { key: 'payment_mode', title: '发放方式' },
    { key: 'remark', title: '发货备注', style: { maxWidth: '500px' } },
    { key: 'customer_m_code', title: '助记码' },
    { key: 'salesmen_name', title: '业务员' },
    { key: 'is_month', title: '月结客户' },
    { key: 'company_name', title: '所属网点' },
  ];

  const corShow = key => show(isPre || `cor_${key}` in showHideSetting ? `cor_${key}` : `cor_cee_${key}`);
  const ceeShow = key => show(isPre || `cee_${key}` in showHideSetting ? `cee_${key}` : `cor_cee_${key}`);

  const showAddressRemarkColGroupId = { 4391: 1, 226: 1 };

  const corHeaderShow = {
    // member_code: memberCodeShow,
    customer_cname: corShow('com'),
    name: corShow('name'),
    telephone: corShow('mobile'),
    addressShow: show(isPre || 'cor_addr_info' in showHideSetting ? 'cor_addr_info' : 'cor_cee_addr'),
    phone: corShow('phone'),
    customer_no: corShow('cno'),
    industry: corShow('indut'),
    customer_m_code: corSugShow.includes('customer_m_code'),
    company_name: true,
    is_month: corSugShow.includes('is_month'),
    salesmen_name: corSugShow.includes('salesmen'),
    remark: corShow('remark'),
    bank_name: show('open_bank'),
    bank_card: show('bank_card_num'),
    address: show('card_holder'),
    cor_addr_info: show('cor_addr_info'),
    address_remark: showAddressRemarkColGroupId[groupId] && corShow('area'),
    account_holder_phone: show('contact_phone'),
    account_holder: show('card_holder'),
    id_num: show('cor_id_num'),
    payment_mode: show('co_delivery_mode'),
  };

  const ceeHeaderShow = {
    rap_short_name: ceeRelateArrPoint && arrPointShow,
    customer_cname: ceeShow('com'),
    name: ceeShow('name'),
    telephone: ceeShow('mobile'),
    addressShow: show(isPre || 'cee_addr_info' in showHideSetting ? 'cor_addr_info' : 'cor_cee_addr'),
    phone: ceeShow('phone'),
    customer_no: ceeShow('cno'),
    industry: ceeShow('indut'),
    company_name: true,
    is_month: ceeSugShow.includes('is_month'),
    address_remark: showAddressRemarkColGroupId[groupId] && ceeShow('area'),
  };

  const corHeader = allCorHeader.filter(item => corHeaderShow[item.key] || show(item.key));
  const ceeHeader = allPersonHeader('cee').filter(item => ceeHeaderShow[item.key]);
  // 发货方各个字段的sug Header
  const corHeaders = {};
  // 表头最多10列（当前列需要显示），超过依次不显示
  corHeader.forEach((v, i) => {
    const k = v.key;
    let h = [...corHeader];
    const index = i >= 9 ? 9 : i;
    const curCol = h.splice(i, 1)[0];
    if (h.length > 9) {
      h = h.splice(0, 9);
    }
    h.splice(index, 0, curCol);
    corHeaders[k] = h;
  });
  const corInsuranceRatio = ext.cor_co_insurance_ratio;
  const isCMBCOrLSMember = basicSetting.member_code && ['ls', 'cmbc'].includes(basicSetting.member_code.checked);
  isCMBCOrLSMember && (corHeaders.member_code = memberCodeHeader);
  const corMaintainCalc = basicSetting.cor_maintain_calc || {};
  const ceeMaintainCalc = basicSetting.cee_maintain_calc || {};
  const parsedCorMaintain = ext.maintain_fee && ext.maintain_fee !== 'null' ? ext.maintain_fee : [];
  const parsedCeeMaintain = ext.cee_maintain_fee && ext.cee_maintain_fee !== 'null' ? ext.cee_maintain_fee : [];
  const hasCorMaintainRule =
    ext.maintain_rule &&
    ((+ext.maintain_rule === 2 && ext.maintain_ratio) || (+ext.maintain_rule === 1 && parsedCorMaintain.some(Boolean)));
  const hasCeeMaintainRule =
    ext.cee_maintain_rule &&
    ((+ext.maintain_rule === 2 && ext.cee_maintain_ratio) ||
      (+ext.cee_maintain_rule === 1 && parsedCeeMaintain.some(Boolean)));
  const corId = ext.cor_id;
  const ceeId = ext.cee_id;
  const corMaintain = (corId && (hasCorMaintainRule ? parsedCorMaintain : corMaintainCalc.maintain_fee)) || []; // 发货人维护费单价
  const ceeMaintain = (ceeId && (hasCeeMaintainRule ? parsedCeeMaintain : ceeMaintainCalc.maintain_fee)) || []; // 收货人维护费单价

  // 收发货是否使用公式计算维护费（默认否）
  const corMaintainByFormula = +(hasCorMaintainRule ? ext.maintain_rule : corMaintainCalc.maintain_rule) === 2;
  const ceeMaintainByFormula = +(hasCeeMaintainRule ? ext.cee_maintain_rule : ceeMaintainCalc.maintain_rule) === 2;

  // 收发货人维护费系数
  const corMaintainRatio = (corId && +(hasCorMaintainRule ? ext.maintain_ratio : corMaintainCalc.maintain_ratio)) || 0;
  const ceeMaintainRatio =
    (ceeId && +(hasCeeMaintainRule ? ext.cee_maintain_ratio : ceeMaintainCalc.maintain_ratio)) || 0;

  const ceeSalesmenPermission = hasPermission('ceeSalesmen');

  // 会员号位置
  const vipPos = setting.vip_pos ? setting.vip_pos.position : 'botm';
  // 发货方sug带出会员号关联 代收发放 bug: 23421
  // const memberInfoUponCor = setting.member_upon_cor.checked
  // 根据会员号关联发货人 bug: 23421
  // const corUponMember = setting.cor_upon_member.checked

  // 读取身份证
  const readIDCard = setting.read_IDCard && setting.read_IDCard.checked;

  // 开启地图选点
  const mapChoose = !isPointTrans && setting.map_choose_enable && setting.map_choose_enable.map_on;
  // 收发货地址必须从下拉选择
  const corAddressLock = setting.cor_addr_lock && setting.cor_addr_lock.checked;
  const ceeAddressLock = setting.cee_addr_lock && setting.cee_addr_lock.checked;

  const unitEnum = [
    { key: 'per_num', name: '/件' },
    { key: 'per_v', name: '/方' },
    { key: 'per_w', name: weightUnit === 'kg' ? '/公斤' : '/吨' },
    { key: 'per_s', name: '/套' },
  ];

  const unitMap = {
    per_num: '/件',
    per_v: '/方',
    per_w: weightUnit === 'kg' ? '/公斤' : '/吨',
    per_s: '/套',
  };

  const goodsRelate = setting.good_relate ? setting.good_relate.value : [];
  const goodsRelateCor = goodsRelate.includes('cor');
  const goodsRelateCee = goodsRelate.includes('cee');
  const goodsRelateArr = goodsRelate.includes('arr');
  const goodsNameHeader =
    window.level <= 6
      ? null
      : [
          ...(goodsSugSetting.g_name.show ? [{ key: 'g_name', title: '货物名称' }] : []),
          ...(show('code') ? [{ key: 'g_code', title: '货物代码' }] : []),
          ...(goodsSugSetting.order_num.show ? [{ key: 'order_num', title: `${i18n.get('order', '运单')}号` }] : []),
          ...(goodsSugSetting.billing_date.show ? [{ key: 'billing_date', title: '开单时间' }] : []),
          ...(goodsSugSetting.cor_name.show ? [{ key: 'cor_name', title: '发货人' }] : []),
          ...(goodsSugSetting.cee_name.show ? [{ key: 'cee_name', title: '收货人' }] : []),
          ...(goodsSugSetting.arr.show ? [{ key: 'arr', title: '到站' }] : []),
          ...(goodsSugSetting.co_freight_f.show ? [{ key: 'co_freight_f', title: '运费', className: 'text-red' }] : []),
          ...(show('subtotal_price') && goodsSugSetting.subtotal_price.show
            ? [{ key: 'g_subtotal_price', title: '运费小计', className: 'text-red' }]
            : []),
          ...(show('unit_p') && goodsSugSetting.unit_p.show
            ? [{ key: 'unit_str', title: '单价', className: 'text-red' }]
            : []),
          ...(show('co_delivery_f') && goodsSugShow('co_delivery_f')
            ? [{ key: 'co_delivery_f', title: '送货费', className: 'text-red' }]
            : []),
          ...(show('co_pickup_f') && goodsSugShow('co_pickup_f')
            ? [{ key: 'co_pickup_f', title: '提货费', className: 'text-red' }]
            : []),
          ...(show('co_pkg_f') && goodsSugShow('co_pkg_f')
            ? [{ key: 'co_pkg_f', title: '包装费', className: 'text-red' }]
            : []),
          ...(show('declared_value') && goodsSugShow('declared_value')
            ? [{ key: 'declared_value', title: '声明价值', className: 'text-red' }]
            : []),
          ...(show('co_insurance') && goodsSugShow('co_insurance')
            ? [{ key: 'co_insurance', title: '保价费', className: 'text-red' }]
            : []),
          ...(show('num') && goodsSugSetting.num.show ? [{ key: 'g_num', title: '件数' }] : []),
          ...(show('weight_per_num') && goodsSugSetting.weight_per_num.show
            ? [{ key: 'g_weight_per_num', title: '单件重量' }]
            : []),
          ...(show('weight') && goodsSugSetting.weight.show ? [{ key: 'g_weight', title: '重量' }] : []),
          ...(show('volume') && goodsSugSetting.volume.show ? [{ key: 'g_volume', title: '体积' }] : []),
          ...(show('pkg') && goodsSugSetting.pkg.show ? [{ key: 'g_pkg', title: '包装' }] : []),
          ...(show('pkg_service') && goodsSugSetting.pkg_service.show
            ? [{ key: 'pkg_service_str', title: '包装服务' }]
            : []),
          ...(show('cat') && goodsSugSetting.cat.show ? [{ key: 'g_cat', title: '货物类型' }] : []),
          ...(show('sped') && goodsSugSetting.sped.show ? [{ key: 'g_spec', title: '规格' }] : []),
          ...(show('model') && goodsSugSetting.model.show ? [{ key: 'g_model', title: '型号' }] : []),
          ...(show('special') && goodsSugSetting.special.show ? [{ key: 'g_special_str', title: '特殊货物' }] : []),
        ];

  const pkgLock = setting.pkg_name && setting.pkg_name.lock;

  const showGoodsTotalRow = setting.show_goods_total_row.checked;

  const showDistributeIcon = show('co_delivery_dialog') && !isPointTrans;
  const payModeSet = base.pay_mode_set;
  const payModeEnum = isDetail
    ? formatEnum(enums.pay_mode)
    : Object.entries(payModeSet).map(([key, name]) => ({ key, name }));
  // 付款方式快捷键
  const payModeShortcutsSetting = personSetting.pay_keys ? personSetting.pay_keys.__meta : {};
  const payModeShortcuts = Object.entries(payModeShortcutsSetting).reduce(
    (allSet, [k, v]) => ({ ...allSet, [v]: k }),
    {},
  );
  // 根据上次发货人,默认付款方式
  const paymentUponLast = defaultValue.payment_upon_last && defaultValue.payment_upon_last.__meta.checked;
  // 有现返/欠返/回扣时，付款方式默认到付
  const payArrivalWhenCashDiscountRebate =
    setting.pay_arrival_when_cash_or_discount_or_rebate && setting.pay_arrival_when_cash_or_discount_or_rebate.checked;
  // 有垫付费时，付款方式默认到付
  const payArrivalWhenPayAdv = setting.pay_arrival_when_pay_adv && setting.pay_arrival_when_pay_adv.checked;

  const freeDelivery = +(setting.low_payment && setting.low_payment.money);

  // 合计运费关联费用项
  const totalPriceFormula = setting.total_calc.value.map(item => formatCamelCase(item));
  const effectTotalPriceKeys = totalPriceFormula.reduce((allSet, key) => ({ ...allSet, [key]: 1 }), {});
  // 退货费默认计入合计运费
  effectTotalPriceKeys.backF = 1;
  // 货物计算合计运费的舍入方式
  // eslint-disable-next-line no-nested-ternary
  const freightRoundType = setting.get_unit
    ? Array.isArray(setting.get_unit.value)
      ? setting.get_unit.value[0]
      : setting.get_unit.value
    : 'real';
  // 改单时不重新计算运费
  const notCalcFreightWhenModify = setting.num_no_change_freight && setting.num_no_change_freight.checked;

  // 现返，欠返 显示位置
  const cashOwnBrokerMiddle = setting.cash_own_broker.position === 'mid_fee';
  const cashOwnBrokerBottom = setting.cash_own_broker.position === 'botm_trans';

  // 经办人枚举
  const mgrIdEnum = []; // Object.entries(enums.mgr_id).map(([key, item]) => ({ ...item, key }))
  const mgrInfo = base.base_enum.mgr_id_info;
  const hasAddMgrPermission = window.permission.includes('orgMgr');
  const showAddMgr = !isDetail && hasAddMgrPermission;
  const receiptRequire = val('receipt_require', defaultReceiptRequire);
  const receiptRequireLock = setting.receipt_require_list.lock;
  const remark = val('remark', defaultRemark.filter(item => item).join(','));
  const remarkLock = setting.transport_remark.lock;
  const isLongKun = comSetting.insure_longkun_open && comSetting.insure_longkun_open.checked; // 启用龙坤保险
  const hasInsurancePermission = hasPermission('singleInsurance');
  const checkInsureDef = +(setting.check_insure_def && setting.check_insure_def.checked) || 0;
  const insuredShow = (checkInsureDef || show('insured')) && (isLongKun ? hasInsurancePermission : isCreate);
  const insuredDisabled = comSetting.insured_disabled && comSetting.insured_disabled.checked;
  const insuredServerEditable = editable('insured');
  const outgoFeeShow = (setting.outgo_fee && setting.outgo_fee.show) || [];

  const originPointCostInfoHeader = headerOf('point_cost_info') || [];

  const pointCostInfoHeader = isCreate
    ? originPointCostInfoHeader.filter(item => groupId !== 4586 || isPickup || !onlyShowWhenPickup.includes(item.key))
    : originPointCostInfoHeader;
  const taxRate = setting.tax_rate.rateVal;
  const showTax = pointCostInfoHeader && pointCostInfoHeader.find(item => item.key === 'tax');
  const showTaxRate = pointCostInfoHeader && pointCostInfoHeader.find(item => item.key === 'tax_r');
  const autoCalcTax = setting.auto_calc_tax.checked && pointCostInfoHeader && showTax && showTaxRate;

  const createTableTabs = personSetting.co_table_tab
    ? personSetting.co_table_tab.__meta.show
    : btnSetDef.co_table_tab_btn.show;
  const detailTableTabs = personSetting.oinfo_table_tab
    ? personSetting.oinfo_table_tab.__meta.show
    : btnSetDef.oinfo_table_tab_btn.show;
  const tableTabs = isDetail ? detailTableTabs : createTableTabs;
  const tableTabKeys = tableTabs.reduce((all, k) => ({ ...all, [k]: 1 }), {});

  const enableInnerTrans = transSetting ? transSetting.trans_in_p.checked : false;

  const hasInnerTransPermission = window.permission.indexOf('innerTransferOut') !== -1;

  const showTransSubtract = enableInnerTrans && isCreate && hasInnerTransPermission;

  // 中转类型枚举
  const transTypeEnum = Object.entries(enums.trans_type || {}).map(([key, item]) => ({
    ...item,
    key,
    name: item.display,
  }));

  const allPayMode = enums.trans_pay_mode || {};
  const transPayList = Object.keys(allPayMode).map(k => `trans_${k}`);
  const transPayKeys = transPayList.reduce((allSet, k) => ({ ...allSet, [k]: 1 }), {});
  // 员工中转可选付款方式
  const innerTransPayModeList = transSetting ? transSetting.trans_in_pay.values : [];
  // 网点中转可选付款方式
  const pointTransPayModeList = transSetting ? transSetting.trans_in_p_pay.values : [];
  // 外部中转可选付款方式
  const outerTransPayModeList = transSetting ? transSetting.trans_o_pay.values : [];
  // 员工中转付款方式枚举
  const innerTransPayModeEnum = innerTransPayModeList
    .filter(key => allPayMode[key])
    .map(key => ({ key, ...allPayMode[key], name: allPayMode[key].display }));
  // 网点中转付款方式枚举
  const pointTransPayModeEnum = pointTransPayModeList
    .filter(key => allPayMode[key])
    .map(key => ({ key, ...allPayMode[key], name: allPayMode[key].display }));
  // 外部中转付款方式枚举
  const outerTransPayModeEnum = outerTransPayModeList
    .filter(key => allPayMode[key])
    .map(key => ({ key, ...allPayMode[key], name: allPayMode[key].display }));

  // 到付时可选中转付款方式
  const payArrivalPayMode = { pay_arrival: 1, pay_multi: 1 };
  // 到付时员工中转付款方式枚举
  const payArrivalInnerTransPayModeEnum = innerTransPayModeEnum.filter(item => payArrivalPayMode[item.key]);
  // 到付时网点中转付款方式枚举
  const payArrivalPointTransPayModeEnum = pointTransPayModeEnum.filter(item => payArrivalPayMode[item.key]);
  // 到付时外部中转付款方式枚举
  // const payArrivalOuterTransPayModeEnum = outerTransPayModeEnum.filter(item => payArrivalPayMode[item.key])
  const payArrivalOuterTransPayModeEnum = outerTransPayModeEnum;

  // 员工中转费用组成 带inner_前缀要去掉
  const innerTransFeeList = (transSetting ? transSetting.trans_in_calc.values : []).map(item =>
    item.replace('inner_', ''),
  );
  const innerTransFeeKeys = innerTransFeeList.reduce((allSet, key) => ({ ...allSet, [key]: 1 }), {});
  // 网点中转费用组成 带point_前缀要去掉
  const pointTransFeeList = (transSetting ? transSetting.trans_in_p_calc.values : []).map(item =>
    item.replace('point_', ''),
  );
  const pointTransFeeKeys = pointTransFeeList.reduce((allSet, key) => ({ ...allSet, [key]: 1 }), {});
  // 外部中转费用组成
  const outerTransFeeList = transSetting ? transSetting.trans_o_calc.values : [];
  const outerTransFeeKeys = outerTransFeeList.reduce((allSet, key) => ({ ...allSet, [key]: 1 }), {});

  const transPickTypeEnum =
    enums.trans_pick_type &&
    Object.entries(enums.trans_pick_type).map(([key, item]) => ({ ...item, key, name: item.display }));
  const transBillingMethod =
    enums.billing_method &&
    Object.entries(enums.billing_method).map(([key, item]) => ({ ...item, key, name: item.display }));
  const transInputRebate = (runSetting.trans_input_rebate || {}).checked;
  const coTrans = basicSetting.co_trans_price && basicSetting.co_trans_price.checked;
  const coPoint = basicSetting.co_calc_point_trans_price && basicSetting.co_calc_point_trans_price.checked;
  const coSend = basicSetting.co_send_price && basicSetting.co_send_price.checked;
  const coBudget = basicSetting.co_budget_price && basicSetting.co_budget_price.checked;
  const coPickup = basicSetting.co_pickup_price && basicSetting.co_pickup_price.checked;

  const mfTrans = basicSetting.modify_trans_price && basicSetting.modify_trans_price.checked;
  const mfSend = basicSetting.modify_send_price && basicSetting.modify_send_price.checked;
  const mfBudget = basicSetting.modify_budget_price && basicSetting.modify_budget_price.checked;
  const mfPickup = basicSetting.modify_pickup_price && basicSetting.modify_pickup_price.checked;
  const hasPickupF = pointCostInfoHeader && pointCostInfoHeader.find(item => item.key === 'pickup_f');
  // 9901 加入订单复制情况逻辑处理
  const sendFlag = (coSend && (isCreate || isCopy)) || (mfSend && isModify && !notCalcFreightWhenModify);
  const transFlag = (coTrans && (isCreate || isCopy)) || (mfTrans && isModify);
  const pointFlag = coPoint && (isCreate || isCopy || isModify || isPointTrans);
  const budgetFlag = (coBudget && (isCreate || isCopy)) || (mfBudget && isModify);
  const pickupFlag = ((coPickup && (isCreate || isCopy)) || (mfPickup && isModify)) && hasPickupF;
  const calcOuterPrice = !isPre && (sendFlag || transFlag || budgetFlag || pickupFlag || pointFlag);

  Object.keys(tableInfo).forEach(key => {
    if (data[key] && data[key].header && data[key].data && data[key].data.length) {
      data[key].data.forEach((row, index) => {
        Object.entries(data[key].header).forEach(([k, col]) => {
          if (col.format && col.format.round && !Number.isNaN(+row[k])) {
            data[key].data[index][k] = +(+row[k]).toFixed(col.format.round);
          }
          if (['trans_w', 'weight'].includes(k) && weightUnit === '吨') {
            data[key].data[index][k] = switchWeight(+row[k]);
          }
          if (k === 'trans_w_detail' && weightUnit === '吨') {
            data[key].data[index][k] = (row[k] || []).map(w => switchWeight(w));
          }
          if ((k === 'std_mgr_id' || k === 'std_cee_mgr_id') && isModify && enums[k]) {
            data[key].data[index][k] = { id: row[k], name: enums[k][row[k]] && enums[k][row[k]].display };
          }
          if (k === 'dn_com_id' && isModify && row.trans_type !== POINT_TRANS && row[k]) {
            data[key].data[index][k] = { id: row.trans_dn_com_name, carrier_name: row[k] };
          }
          if (k === 'dn_com_id' && isModify && row.trans_type === POINT_TRANS) {
            data[key].data[index][k] = { id: row.point_trans_dn_com_id, short_name: row[k] };
          }
          if (k === 'trans_route' && isModify && row.trans_type === POINT_TRANS) {
            data[key].data[index][k] = { route_nick: row[k] };
          }
          if (k === 'address' && isModify) {
            data[key].data[index][k] = { address: row.address_addr, name: row[k] };
          }
        });
      });
    }
  });

  const validateMobile = setting.validate_mobile.value;

  const validateCorMobile = validateMobile.includes('cor_mobile');
  const validateCeeMobile = validateMobile.includes('cee_mobile');
  const validateCashreturnPhone = validateMobile.includes('cashreturn_phone');
  const validateDiscountPhone = validateMobile.includes('discount_phone');
  const validateRebatePhone = validateMobile.includes('rebate_phone');
  const validateBInfoPhone = validateMobile.includes('b_info_phone');
  const validateBLocPhone = validateMobile.includes('b_loc_phone');
  const validateLocTrPhone = validateMobile.includes('loc_tr_phone');

  const mileRatio = (basicSetting.mile_ratio && +basicSetting.mile_ratio.ratio_val) || 1;

  const ceeMatchArr = false; // !isPointTrans && setting.cee_match_arr_enable && setting.cee_match_arr_enable.cee_match_arr
  const showArrPoint =
    ceeMatchArr || (setting.show_arr_point_map_enable && setting.show_arr_point_map_enable.show_arr_point_map);
  const showMap = !isPointTrans && (ceeMatchArr || showArrPoint);

  const printBtn = ext.print_btn || {};
  let allPrintItems = setting.print_options ? setting.print_options.printItems : {};
  if (isPointTrans) {
    allPrintItems = runSetting.trans_a_print_options ? runSetting.trans_a_print_options.printItems : {};
  } else if (usedFor === 'pre') {
    allPrintItems = setting.empty_pre_print ? setting.empty_pre_print.printItems : {};
  } else if (usedFor === 'tags') {
    allPrintItems = setting.tags_pre_print ? setting.tags_pre_print.printItems : {};
  }
  // eslint-disable-next-line no-nested-ternary
  const orderPrintSort = isPointTrans
    ? []
    : setting.order_print_sort && setting.order_print_sort.show
    ? setting.order_print_sort.show
    : [];

  const printItems = hasPermission('printReservationV1') ? buildPrintItem(allPrintItems, printBtn, orderPrintSort) : [];
  const collectPrintTypes = setting.collected_enable_print_types.value.reduce((all, k) => ({ ...all, [k]: 1 }), {});
  const collectPrintFees = setting.collected_enable_print_fees.value;
  const collectPrintFeesIncludePickupF = collectPrintFees.includes('pickup_f');

  const showBtn = setting.co_op_btns.options || [];

  const showPreview = showBtn.includes('btn_preview');
  const showAddPrint = hasPermission('printReservationV1') && showBtn.includes('btn_new_print');
  const showSavePrint = hasPermission('printReservationV1') && showBtn.includes('btn_save_print');
  const showSaveAdd = showBtn.includes('btn_save_new');
  const showSave = showBtn.includes('btn_save');
  const showNoticeMessage = showBtn.includes('btn_notice_message');
  const showScanPay = hasPermission('scanPay');
  // const showInsurance = ext.is_perm_insured

  const showOrderSetting = hasPermission('orderSetting');
  const showPersonSetting = hasPermission('coOrder') && !hasPermission('createReservationV1');
  const settingSubList = [
    {
      key: 'coPath',
      title: (
        <span>
          <i className="fn-icon fn-icon-polygon text-blue" /> 光标路径
        </span>
      ),
    },
    ...(showOrderSetting
      ? [
          {
            key: 'orderSet',
            title: (
              <span>
                <i className="fn-icon fn-icon-setting text-blue" /> {i18n.get('order', '运单')}设置
              </span>
            ),
          },
        ]
      : []),
    ...(showPersonSetting
      ? [
          {
            key: 'psnSet',
            title: (
              <span>
                <i className="fn-icon fn-icon-user-add text-blue" /> 个人设置
              </span>
            ),
          },
        ]
      : []),
    {
      key: 'skinSet',
      title: (
        <span>
          <i className="fn-icon fn-icon-skin text-blue" /> 皮肤设置
        </span>
      ),
      direction: 'left_up',
      children: [
        { key: 'skin-CAE0F6', title: <span className="skin-item skin-item--CAE0F6">默认</span> },
        { key: 'skin-AEE8C6', title: <span className="skin-item skin-item--AEE8C6">浅豆绿</span> },
        { key: 'skin-FFB9C2', title: <span className="skin-item skin-item--FFB9C2">樱桃红</span> },
        { key: 'skin-5C87B3', title: <span className="skin-item skin-item--5C87B3">绅士蓝</span> },
        { key: 'skin-FFBF73', title: <span className="skin-item skin-item--FFBF73">柠檬黄</span> },
        { key: 'skin-C7B8ED', title: <span className="skin-item skin-item--C7B8ED">紫罗兰</span> },
      ],
    },
  ];

  // 单票毛利公式
  const profitFormula = matchProfitFormula(financeSetting.fn_formula, priceMode, isCoPointOrParent, isOpenPayment);
  const { formulaKeys: effectProfitKeys, formulaKeyMap: effectProfitKeyMap } = formatFormula(profitFormula);
  // 收发货客户维护费公式
  const corMaintainFormula = basicSetting.cor_maintain_fee_formula
    ? basicSetting.cor_maintain_fee_formula.formulaDt
    : [];
  const { formulaKeys: corMaintainKeys, formulaKeyMap: corMaintainKeyMap } = formatFormula(corMaintainFormula);
  const ceeMaintainFormula = basicSetting.cee_maintain_fee_formula
    ? basicSetting.cee_maintain_fee_formula.formulaDt
    : [];
  const { formulaKeys: ceeMaintainKeys, formulaKeyMap: ceeMaintainKeyMap } = formatFormula(ceeMaintainFormula);

  const scanPath = isScan && { show: ['arr', 'order_num', 'good_sn'] };
  // eslint-disable-next-line no-nested-ternary
  const coPath = personSetting.coPath ? personSetting.coPath.__meta : data.co_path ? data.co_path.coPath : {};
  const path = scanPath || coPath;

  const buttons = data.buttons || {};
  buttons.print && (buttons.print = { ...buttons.print, children: printItems });
  ext.co_pre_st === 1 && delete buttons.print;
  const disableInsure = [20, 30, 40, 60].includes(ext.insured_status_code);
  // const showDialog = ext.is_perm_insure
  let insureStatusText = '投保';
  if ([20, 30, 60].includes(ext.insured_status_code)) {
    insureStatusText = '已投保';
  } else if (ext.insured_status_code === 40) {
    insureStatusText = '已失效';
  }

  buttons.order_insurance &&
    (buttons.order_insurance = { ...buttons.order_insurance, title: insureStatusText, disabled: disableInsure });
  // 是否有派单的权限
  const appDispatchPermission = hasPermission('appDispatch');
  // 是否设置了只有需要配安的运单才可派单
  const dispatch = runSetting.dispatch && runSetting.dispatch.checked;
  // 是否有管理司机的权限
  const driverMgrPermission = hasPermission('driverMgr');
  const noticePickItemsKey = ['notice_delivery', 'pickup', 'need_dispatch', 'back_journey', 'insured', 'hx_code'];
  const noticePickShowArr = noticePickItemsKey.filter(show);
  // eslint-disable-next-line no-nested-ternary
  const noticePickShowMode = noticePickShowArr.length >= 4 ? '1' : noticePickShowArr.length >= 3 ? '2' : 'name';

  // 带入危险货物字段
  const dangerGoodsFieldsObj = Object.keys(dangerGoodsFields).reduce(
    (o, k) => ({ ...o, ...field(k, dangerGoodsFields[k]) }),
    {},
  );

  return {
    modifyFrom,
    reservationNum,
    tpl,
    isCreate,
    isDetail,
    isPointTrans,
    isModify,
    isPre,
    isScan,
    isUpdatePre,
    odLinkId,
    odBasicId,
    isReservation,
    isBack,
    cid,
    createCid,
    isCoPointOrParent,
    logid: data.logid,
    companyId: cid,
    companyName: data.company_name,
    companyInfo,
    hasCashier,
    setting,
    runSetting,
    transSetting,
    basicSetting,
    personSetting,
    financeSetting,
    ext,
    origin: data,
    enums,
    priceMode,
    defaultPriceMode,
    calcInnerPrice,
    autoActiveFirstOption,
    shortcuts,
    shortcutsDisplay,
    arrNoRelation,
    transDeliveryMust,
    msgTypeList,
    startPointIsPoint: +(ext.ipt_company_info || ext.co_company_info || {}).type === 3,

    ...field('order_num', '运单号'),
    orderNum: req.def_order_num || val('order_num'),
    ...(usedFor === 'pre' || (usedFor === 'updatePre' && !odLinkId) ? { orderNum: '' } : {}),
    originOrderNum: val('order_num'),
    orderNumShow: true,
    orderNumRequired: true,
    ...field('back_journey', '返程'),
    ...field('pre_count', '开单数量'),
    ...field('order_num_range', '运单号'),
    orderNumEditable:
      !req.def_order_num && editable('order_num') && !(orderNumNoModify && ['auto', 'custom'].includes(orderNumCheck)),

    ...field('manual_num', '', '', '', manualNumVal), // 临时手动单号，下次开单不自增
    manualNumShow: oNumRuleCheck === 'auto' && oNumRuleMdAutoBy === 'after_new' && isCreate,

    ...field('billing_date', '开单时间', null, null),
    billingDate,
    billingDateShow: show('billing_date'),
    billingDateRequired: isPre ? required('billing_date') : true,
    dateRange,
    minDate,
    maxDate,
    isToday,
    defaultBillingDate,
    postponeBillingDateTo,
    checkSaturday,
    checkSunday,
    checkOverCutOffTime,
    prevDate: isPrevDate,
    prevDateEditable: !isDetail,
    ...field('order_creator_name', '制单员'),

    // traffic
    ...field('start_point', '出发网点', null, 'id'),
    startPoint: base.base_enum.start_point,
    startPointShow: isPre || show('start_point'),
    startPointId: val('start_point'),
    startPointEnum: base.base_enum.start_point && [base.base_enum.start_point],
    comId: base.com_id && base.com_id.value,
    groupId,
    ...field('ob_project_id', '项目名称', null, 'id'),
    obProjectId: base.base_enum.ob_project_id,
    obProjectIdEnum: base.base_enum.ob_project_id && [base.base_enum.ob_project_id],
    ...field('ob_customer_id', '客户名称', null, 'id'),
    obCustomerId: base.base_enum.ob_customer_id,
    obCustomerIdEnum: base.base_enum.ob_customer_id && [base.base_enum.ob_customer_id],

    ...field('stock_point', '入库网点'),
    stockPointId: val('stock_point'),
    stockPointEnum: base.base_enum.stock_point && [base.base_enum.stock_point],

    ...field('arr_point', '目的网点', null, 'company_id'),
    arrPoint,

    ...field('start_info', '发站'),
    startInfoEditable: editable('start_info') && !setting.start_noedt.noedit,
    startInfoShow: show('start'),
    startInfoRequired: required('start'),

    ...field('rcv_stn', '收货点', null),
    rcvStn,
    rcvStnOld: rcvStn,

    // ...field('arr_info', '到站', 'poi', 'addr'),
    ...field('arr_info', '到站', null, 'addr'),
    arrInfoShow: show('arr'),
    arrInfoRequired: required('arr'),
    arrInfoIsDataList,
    arrOnlyRoute,
    ceeRelateArrPoint,
    startArrNeedPoi,
    arrPointHeader,
    arrInfoHeader,

    ...field('product_line', '线路'),
    productLine: val('product_line') && val('product_line')[0],
    ...field('trans_mode', '中转方式', null, null, defaultTransMode),
    transModeEnum,
    ...field('route', '路由'),
    route,
    routeRequired: required('route') && !directDelivery[deliveryMode],
    routeSettingRequired: required('route'),
    ...field('route_mile', '路由里程'),
    isThrough: val('is_through'),
    oldIsThrough: val('old_is_through'),
    oldPriceMode: val('old_price_mode'),
    ...field('thermosphere', '温层'),
    thermosphereEnum: setting.thermosphere_list && setting.thermosphere_list.selc_value,
    thermosphereLock: setting.thermosphere_list && setting.thermosphere_list.lock,
    ...field('product_type', '产品类型'),
    productType: productType.value,
    productTypeHeader,
    productTypeEnum: productType.enums,
    productTypeShowPrice,
    ...field('service_type', '服务类型', null, null, defaultServiceType),
    serviceTypeEnum,
    ...field('xpcd_arr_date', '标准到货日期'),
    xpcdArrDateConsistBegin,
    xpcdArrDateConsistDelta,
    postponeXpcdArrDateTo,
    xpcdArrDateCheckSaturday,
    xpcdArrDateCheckSunday,
    checkOverDeliveryTime,
    shudGTEXpcd,
    ...field('delivery_mode', '送货方式'),
    deliveryModeShow: isPre ? show('delivery_mode') : true,
    deliveryModeRequired: isPre ? required('delivery_mode') : true,
    deliveryModeEnum: isDetail ? deliveryModeEnum : optionalDeliveryModeEnum,
    deliveryModeEnumNoSelfPick: optionalDeliveryModeEnum.filter(item => item.key !== 'self_pick'),
    deliveryMode,
    deliveryModeTop,
    deliveryShortcuts,
    warnAllowCheck,

    ...field('truck_type', '需求车型'),
    truckTypeShow: show('truck_type') || needTruck,
    truckTypeRequired: required('truck_type') || (needTruck && isDirectTruck),
    truckTypeSettingRequired: required('truck_type'),
    ...(isDetail ? { truckType: { value: val('truck_type') } } : {}),
    truckTypeEnum,

    ...field('truck_length', '需求车长'),
    ...(isDetail ? { truckLength: { value: val('truck_length') } } : {}),
    truckLengthShow: show('truck_length') || needTruck,
    truckLengthRequired: required('truck_length') || (needTruck && isDirectTruck),
    truckLengthSettingRequired: required('truck_length'),
    truckLengthEnum,
    needTruck,

    ...field('goods_num', '货号'),
    goodsNumEditable: editable('goods_num') && !setting.goods_num.no_modify,
    goodsNumCustom,
    effectGoodsNumKeys,
    ...field('entrust_num', '委托单号'),
    ...field('device_id', 'GPS编号'),
    deviceIdEnum: enums.device_id,
    // 发货方sug表头
    corHeaders,
    corHeader,
    // 收货方sug表头
    ceeHeader,
    mapChoose,
    corLock: setting.cor_only_in && setting.cor_only_in.checked,
    readIDCard,
    // 发货方

    cashHead,
    bankHead,
    ...field('co_delivery_mode', '发放方式'),
    coDeliveryModeEnum: ary2Obj(ext.co_delivery_mode || ['银行卡', '现金']),
    hasArrStation: ext.has_arr_station,
    ...field('card_holder', '开户人'),
    cardHolderSettingRequired: required('card_holder'),
    ...field('contact_phone', '开户电话'),
    contactPhoneSettingRequired: required('contact_phone'),
    ...field('open_bank', '开户行'),
    openBankEnum: ary2Obj(ext.bank_card_list || []),
    openBankSettingRequired: required('open_bank'),
    ...field('bank_card_num', '银行卡号'),
    bankCardNumSettingRequired: required('bank_card_num'),
    ...field('shipper_phone', '会员电话'),

    corId,
    corMaintain,
    corSalesmen: ext.salesmens,
    // 发货客户 预警值 锁机值
    alarmThreshold: ext.alarm_threshold,
    lockValue: ext.lock_value,
    needAlarm: ext.need_alarm,
    corCompanyId: ext.cor_company_id,
    corLockTime: ext.cor_lock_time,
    todoDays: ext.cor_todo_days,
    todoTime: ext.cor_todo_time,
    corIsProject: +ext.cor_is_proj === 1,
    corInsuranceRatio,
    corIsMonth: val('cor_is_month'),
    corIsMonthShow: show('monthly_customer'),
    ...field('cor_customer_no', '发货客户编号'),
    corCustomerNoShow: show('cor_cno' in showHideSetting ? 'cor_cno' : 'cor_cee_cno'),
    corCustomerNoRequired: required('cor_cno' in showHideSetting ? 'cor_cno' : 'cor_cee_cno'),

    ...field('cor_industry', '发货客户行业'),
    corIndustryShow: show('cor_indut' in showHideSetting ? 'cor_indut' : 'cor_cee_indut'),
    corIndustryRequired: required('cor_indut' in showHideSetting ? 'cor_indut' : 'cor_cee_indut'),

    ...field('cor_com', '发货单位'),
    corComShow: show(isPre || 'cor_com' in showHideSetting ? 'cor_com' : 'cor_cee_com'),
    corComRequired: required(isPre || 'cor_com' in showHideSetting ? 'cor_com' : 'cor_cee_com'),

    ...field('cor_name', '发货人'),
    corNameShow: show(isPre || 'cor_name' in showHideSetting ? 'cor_name' : 'cor_cee_name'),
    corNameRequired: required(isPre || 'cor_name' in showHideSetting ? 'cor_name' : 'cor_cee_name'),
    corHistoryShow: show('cor_history') && !isDetail,

    ...field('cor_mobile', '发货人手机号码'),
    corMobileShow: show(isPre || 'cor_mobile' in showHideSetting ? 'cor_mobile' : 'cor_cee_mobile'),
    corMobileRequired: required(isPre || 'cor_mobile' in showHideSetting ? 'cor_mobile' : 'cor_cee_mobile'),

    ...field('cor_phone', '发货人电话号码'),
    corPhoneShow: show(isPre || 'cor_phone' in showHideSetting ? 'cor_phone' : 'cor_cee_phone'),
    corPhoneRequired: required(isPre || 'cor_phone' in showHideSetting ? 'cor_phone' : 'cor_cee_phone'),

    ...field('cor_cate', '发货客户类型'),
    corCate: ext.cor_cate,
    corCateStr: val('cor_cate_disp'),

    ...field('cor_id_num', '发货人身份证号'),
    corIdNumShow: show('cor_id_num' in showHideSetting ? 'cor_id_num' : 'cor_cee_id_num'),
    corIdNumRequired: required('cor_id_num' in showHideSetting ? 'cor_id_num' : 'cor_cee_id_num'),

    // ...field('cor_addr_info', '发货地址', needCorAddress && 'poi'),
    ...field('cor_addr_info', '发货地址'),
    needCorAddress,
    corAddressLock,
    corAddressType: setting.cor_addr_type && setting.cor_addr_type.value,
    corAddrInfoShow:
      // eslint-disable-next-line no-nested-ternary
      show(isPre ? 'cor_addr' : 'cor_addr_info' in showHideSetting ? 'cor_addr_info' : 'cor_cee_addr') ||
      needCorAddress,
    corAddrInfoSettingRequired: required(
      // eslint-disable-next-line no-nested-ternary
      isPre ? 'cor_addr' : 'cor_addr_info' in showHideSetting ? 'cor_addr_info' : 'cor_cee_addr',
    ),
    corAddrInfoRequired:
      // eslint-disable-next-line no-nested-ternary
      required(isPre ? 'cor_addr' : 'cor_addr_info' in showHideSetting ? 'cor_addr_info' : 'cor_cee_addr') ||
      (isPickup && needCorAddress),

    ...field('cor_addr_remark', '发货地址备注'),
    corAddrRemarkShow: show(isPre || 'cor_area' in showHideSetting ? 'cor_area' : 'cor_cee_area'),
    corAddrRemarkRequired: required(isPre || 'cor_area' in showHideSetting ? 'cor_area' : 'cor_cee_area'),

    ...field('cor_pick_dist', '提货里程'),
    ...field('co_pickup_date', '预约提货时间'),
    ...field('ob_plan_load_t', '预计装货时间'),
    ...field('ob_plan_truck_t', '预计发车时间'),
    ...field('ob_plan_arr_t', '预计到达时间'),
    ...field('cor_remark', '发货备注'),
    ...field('cor_attachment', '发货人附件'),
    matchedCorAttachment: val('matched_cor_attachment'),
    // 收货方
    ceeId,
    ceeMaintain,
    ceeSalesmen: ext.cee_salesmens,
    ceeIsProject: ext.cee_is_proj === 1,
    ceeIsMonth: val('cee_is_month'),
    ceeIsMonthShow: show('monthly_customer'),

    ...field('cee_customer_no', '收货客户编号'),
    ceeCustomerNoShow: show('cee_cno' in showHideSetting ? 'cee_cno' : 'cor_cee_cno'),
    ceeCustomerNoRequired: required('cee_cno' in showHideSetting ? 'cee_cno' : 'cor_cee_cno'),

    ...field('cee_industry', '收货客户行业'),
    ceeIndustryShow: show('cee_indut' in showHideSetting ? 'cee_indut' : 'cor_cee_indut'),
    ceeIndustryRequired: required('cee_indut' in showHideSetting ? 'cee_indut' : 'cor_cee_indut'),

    ...field('cee_com', '收货单位'),
    ceeComShow: show(isPre || 'cee_com' in showHideSetting ? 'cee_com' : 'cor_cee_com'),
    ceeComRequired: required(isPre || 'cee_com' in showHideSetting ? 'cee_com' : 'cor_cee_com'),

    ...field('cee_name', '收货人'),
    ceeNameShow: show(isPre || 'cee_name' in showHideSetting ? 'cee_name' : 'cor_cee_name'),
    ceeNameRequired: required(isPre || 'cee_name' in showHideSetting ? 'cee_name' : 'cor_cee_name'),
    ceeHistoryShow: show('cee_history') && !isDetail,

    ...field('cee_mobile', '收货人手机号码'),
    ceeMobileShow: show(isPre || 'cee_mobile' in showHideSetting ? 'cee_mobile' : 'cor_cee_mobile'),
    ceeMobileRequired: required(isPre || 'cee_mobile' in showHideSetting ? 'cee_mobile' : 'cor_cee_mobile'),

    ...field('cee_phone', '收货人电话号码'),
    ceePhoneShow: show(isPre || 'cee_phone' in showHideSetting ? 'cee_phone' : 'cor_cee_phone'),
    ceePhoneRequired: required(isPre || 'cee_phone' in showHideSetting ? 'cee_phone' : 'cor_cee_phone'),

    ...field('cee_cate', '收货客户类型'),
    ceeCate: ext.cee_cate,
    ceeCateStr: val('cee_cate_disp'),

    ...field('cee_id_num', '收货人身份证号'),
    ceeIdNumShow: show('cee_id_num' in showHideSetting ? 'cee_id_num' : 'cor_cee_id_num'),
    ceeIdNumRequired: required('cee_id_num' in showHideSetting ? 'cee_id_num' : 'cor_cee_id_num'),

    needCeeAddress,
    ceeAddressLock,
    ceeAddressType: setting.cee_addr_type && setting.cee_addr_type.value,
    // ...field('cee_addr_info', '收货地址', needCeeAddress && 'poi'),
    ...field('cee_addr_info', '收货地址'),
    ceeAddrInfoShow:
      // eslint-disable-next-line no-nested-ternary
      show(isPre ? 'cee_addr' : 'cee_addr_info' in showHideSetting ? 'cee_addr_info' : 'cor_cee_addr') ||
      needCeeAddress,
    ceeAddrInfoSettingRequired: required(
      // eslint-disable-next-line no-nested-ternary
      isPre ? 'cee_addr' : 'cee_addr_info' in showHideSetting ? 'cee_addr_info' : 'cor_cee_addr',
    ),
    ceeAddrInfoRequired:
      // eslint-disable-next-line no-nested-ternary
      required(isPre ? 'cee_addr' : 'cee_addr_info' in showHideSetting ? 'cee_addr_info' : 'cor_cee_addr') ||
      (isDelivery && needCeeAddress),

    ...field('cee_addr_remark', '收货地址备注'),
    ceeAddrRemarkShow: show(isPre || 'cee_area' in showHideSetting ? 'cee_area' : 'cor_cee_area'),
    ceeAddrRemarkRequired: required(isPre || 'cee_area' in showHideSetting ? 'cee_area' : 'cor_cee_area'),

    ...field('cee_pick_dist', '送货里程'),
    ...field('shud_arr_date', '指定到货日期'),
    ...field('cee_remark', '收货备注'),
    ...field('cee_attachment', '收货人附件'),
    matchedCeeAttachment: val('matched_cee_attachment'),
    // 货物
    goodsStyle: (personSetting.goods_style && personSetting.goods_style.__meta) || {},
    goodsRelate,
    goodsRelateCor,
    goodsRelateCee,
    goodsRelateArr,
    goodsNameHeader,
    showGoodsTotalRow,
    unitMap,
    ...field('goods', '货物'),
    goods,
    addEditable: editable('add'),
    goodsShow: true,
    defaultGoods,
    freightRoundType,
    notCalcFreightWhenModify,

    ...field('name', '货物名称'),
    ...field('code', '货物代码'),
    goodsSugSetting,
    nameLock: setting.goods_name && setting.goods_name.lock,
    nameEnum: setting.goods_name && setting.goods_name.selc_value,

    goodsSugSource: setting.goods_sug_source && setting.goods_sug_source.value,
    ...dangerGoodsFieldsObj,
    ...field('attachments', '货物附件'),

    ...field('pkg', '包装'),
    pkgLock,
    pkgEnum:
      pkgLock || (setting.pkg_show && setting.pkg_show.value === 'def')
        ? setting.pkg_name && setting.pkg_name.selc_value
        : ext.pkg_list,

    ...field('pkg_service', '包装服务'),
    ...field('suit', '套数', 'float_p_3'),
    ...field('suit_unit_price', '套数单价', 'float_p_6'),
    ...field('num', '件数', 'number'),
    ...field('num_unit_price', '件数单价', 'float_p_6'),
    ...field('num_per_tray', '单托件数', 'number'),
    ...field('tray_count', '托数', 'number'),
    ...field('weight_per_num', '单件重量', weightUnit === 'kg' ? 'float_p_3' : 'float_p_6'),
    ...field('weight', '重量', weightUnit === 'kg' ? 'float_p_3' : 'float_p_6'),
    ...field('wh_id', '仓库'),
    ...field('wh_area_id', '库区'),
    ...field('wh_location_id', '货位'),
    ...field('in_bill_num', '入库单号'),
    ...field('out_bill_num', '出库单号'),
    ...field('practical_w', '实际重量', weightUnit === 'kg' ? 'float_p_3' : 'float_p_6'),
    weightUnit,
    ...field('weight_unit_price', '重量单价', 'float_p_6'),
    ...field('length', '长', 'float_p_6'),
    ...field('width', '宽', 'float_p_6'),
    ...field('high', '高', 'float_p_6'),
    ...field('volume_per_num', '单件体积', 'float_p_6'),
    ...field('volume', '体积', 'float_p_6'),
    ...field('practical_v', '实际体积', 'float_p_6'),
    ...field('calc_info', '体积计算器'),
    calcInfoShow: groupId === 68544 || groupId === 226,
    ...field('volume_unit_price', '体积单价', 'float_p_6'),
    ...field('unit_p', '单价', 'float_p_6'),
    unitEnum,
    ...field('unit_p_unit', '单位'),
    unitPUnitShow: show('unit_p'),
    ...field('subtotal_price', '运费小计', 'float_p'),
    ...field('count_per_num', '单件数量', 'number'),
    ...field('count', '数量', 'number'),
    ...field('unit', '单位'),
    ...field('cat', '货物类型'),
    catLock: setting.goods_cat && setting.goods_cat.lock,
    catEnum: setting.goods_cat && setting.goods_cat.selc_value,

    ...field('spec', '规格'),
    specShow: show('sped'),
    specRequired: required('sped'),
    ...field('model', '型号'),

    ...field('special', '特殊货物'),
    specialShow: isPre ? show('shaped') : show('special'),
    specialRequired: isPre ? required('shaped') : required('special'),
    specialLock: setting.goods_special && setting.goods_special.lock,
    specialEnum: setting.goods_special && setting.goods_special.selc_value,
    specialCheckbox: setting.goods_special_show && setting.goods_special_show.value === 'checkbox',

    valueAddedServiceCardShow,
    ...field('value_add_service', '增值服务项'),
    valueAddServiceEnum: (setting.value_add_service_list && setting.value_add_service_list.selc_value) || [],

    // 费用
    ...field('total_price', '合计运费', null, null, 0),
    totalPriceShow: true,
    totalPriceFormula,
    effectTotalPriceKeys,
    ...field('actual_price', '实际运费'),
    actualPrice: (ext.profit || {}).actual_price || 0,
    cashOwnBrokerMiddle,
    preFreight: isUpdatePre && +val('co_freight_f'),
    preRebate: isUpdatePre && +val('rebate'),
    preCashreturn: isUpdatePre && +val('cashreturn'),
    preDiscount: isUpdatePre && +val('discount'),
    ...field('tax_inc', '含税'),
    ...field('co_freight_f', '运费', 'float'),
    ...field('rebate', '回扣', 'float'),
    rebateShow: show('rebate') || show('rebate_paid'),
    ...field('rebate_paid', '回扣已付'),
    ...field('rebate_name', '回扣人'),
    ...field('rebate_phone', '回扣手机'),
    ...field('cashreturn', '现返', 'float'),
    ...field('cashreturn_paid', '现返已返'),
    ...field('cashreturn_name', '现返人'),
    ...field('cashreturn_phone', '现返手机'),
    ...field('discount', '欠返', 'float'),
    ...field('discount_name', '欠返人'),
    ...field('discount_phone', '欠返手机'),
    ...field('co_delivery_f', '开单送货费', 'float'),
    ...field('back_f', '退货费', 'float'),
    backFShow: order.back_f, // 退货费有就显示
    needDeliveryFee,
    coDeliveryFShow: show('co_delivery_f') || (!isPre && needDeliveryFee),
    coDeliveryFSettingRequired: required('co_delivery_f'),
    coDeliveryFRequired: required('co_delivery_f') || (isDelivery && needDeliveryFee),
    ...field('co_pickup_f', '开单提货费', 'float'),
    coPickupFShow: show('co_pickup_f') || (!isPre && needPickupFee),
    coPickupFSettingRequired: required('co_pickup_f'),
    coPickupFRequired: required('co_pickup_f') || (isPickup && needPickupFee),
    ...field('co_receipt_f', '开单回单费', 'float'),
    ...field('co_handling_f', '开单装卸费', 'float'),
    ...field('co_upstairs_f', '开单上楼费', 'float'),
    ...field('need_insured', '需要保价'),
    ...field('declared_value', '声明价值', 'float', null, defaultDeclareValue),
    ...field('co_insurance', '开单保价费', 'float', null, defaultCoInsurance),
    insurSet,
    insurCalcIcon: insurSet.checked !== 'manual' && !isDetail,
    maxDecimal: setting.max_decimal ? setting.max_decimal.heightVal : '',
    ...field('co_trans_f', '开单中转费', 'float'),
    ...field('co_pkg_f', '开单包装费', 'float'),
    ...field('co_in_wh_f', '开单进仓费', 'float'),
    ...field('co_pay_adv', '开单垫付费', 'float'),
    ...field('co_pay_adv_paid', '开单垫付费已付'),
    coPayAdvPaid:
      isCreate && setting.pay_adv_auto_check && setting.pay_adv_auto_check.checked
        ? !!show('co_pay_adv_paid')
        : val('co_pay_adv_paid'),
    ...field('co_delivery_adv', '垫付货款', 'float'),
    ...field('co_storage_f', '开单保管费', 'float'),
    ...field('co_install_f', '开单安装费', 'float'),
    ...field('co_make_f', '开单制单费', 'float', null, defaultCoMakeF),
    ...field('co_declare_f', '开单报关费', 'float_p'),
    ...field('co_build_f', '开单港建费', 'float_p'),
    ...field('co_arr_service_f', '到付服务费', 'float_p'),
    ...field('co_misc_f', '开单其他费', 'float'),

    // 付款方式
    payModeSet,
    ...field('payment_mode', '支付方式', null, null, defaultPaymentMode),
    paymentModeEnum: setting.payment_mode_list && setting.payment_mode_list.selc_value,
    ...field('pay_mode', '付款方式', null, null, defaultPayMode),
    payModeShow: isPre ? show('pay_mode') : true,
    payModeRequired: isPre ? required('pay_mode') : true,
    payModeEnum,
    payModeShortcuts,
    paymentUponLast,
    payArrivalWhenCashDiscountRebate,
    payArrivalWhenPayAdv,
    ...field('pay_billing', '现付', 'float'),
    ...field('pay_billing_paid', '现付已付'),
    ...field('pay_arrival', '到付', 'float'),
    ...field('pay_credit', '货到打卡', 'float'),
    ...field('pay_monthly', '月结', 'float'),
    ...field('pay_receipt', '回付', 'float'),
    ...field('pay_co_delivery', '货款扣', 'float'),
    ...field('pay_owed', '欠付', 'float'),

    // 代收货款
    ...field('co_delivery', '代收货款'),
    ...field('co_delivery_release_days', '发放天数', 'number'),
    coDeliveryReleaseDaysEnum: Object.values(base.co_delivery_release_days_set || {})
      .filter(key => !window.isNaN(key))
      .map(key => ({ key, name: key })),
    coDeliveryReleaseDaysSe: base.co_delivery_release_days && base.co_delivery_release_days.se,
    freeDelivery,
    commissionSetting,
    coDeliveryFeeCalcIcon: commissionSetting.checked !== 'manual' && !isDetail,
    maxCoDeliveryFee: setting.max_delivery ? setting.max_delivery.heightVal : '',
    ...field('co_delivery_fee', '手续费', 'float'),
    ...field('co_delivery_freight', '代收运费', 'float'),
    ...field('member_code', '会员号', null, memberCodeType),
    vipPos,
    memberCode: isCreate ? { ...(ext.member_info || {}), co_delivery_mode: defaultVipDelivery } : ext.member_info,
    memberCodeType,
    isCMBCOrLSMember,
    memberCodeHeader,
    // corUponMember,
    // memberInfoUponCor,
    autoShowDistribute,
    showDistributeIcon,

    // extra
    ...field('mgr_id', '经办人', null, 'id'),
    mgrId: mgrInfo,
    mgrIdRequired: isPre ? required('mgr_id') : true,
    mgrIdEnum,
    showAddMgr,
    cashOwnBrokerBottom,
    deliveryModeBottom,
    ...field('notice_delivery', '控货'),
    noticeDeliveryShow: isPre ? false : show('notice_delivery'),
    ...field('pickup', '提货'),
    pickupShow: show('pickup'),
    dispatch,
    ...field('need_dispatch', '配安'),
    needDispatchShow: show('need_dispatch'),
    ...field('hx_code', '核销码'),
    hxCodeShow: show('hx_code'),
    noticePickShow: !valueAddedServiceCardShow && noticePickShowArr.length > 0,
    noticePickShowMode,
    needPickupFee,
    ...field('insured', '投保', null, null, checkInsureDef),
    hasInsurancePermission,
    originInsuredShow: insuredShow,
    insuredShow,
    checkInsureDef,
    insuredEditable: !insuredDisabled && insuredServerEditable,
    insuredServerEditable,
    isLongKun,
    longKunBlack: comSetting.no_insure_cor
      ? comSetting.no_insure_cor.selc_value.reduce(
          (all, item) => ({ ...all, [`${item.name || ''}___${item.telephone || ''}`]: 1 }),
          {},
        )
      : {},
    longKunOfflineGoods: comSetting.no_insure_goods_online
      ? comSetting.no_insure_goods_online.selc_value.map(item => item.value)
      : [],
    ...field('urgency', '紧急度', null, null, 'normal'),
    urgencyEnum,
    ...field('receipt_cat', '回单类型', null, null, defaultReceiptType),
    receiptCatShow: show('receipt_info'),
    receiptCatRequired: required('receipt_info'),
    receiptCatEnum: setting.receipt_type_list.selc_value,
    receiptPayments,
    defaultReturnPayNum,
    ...field('receipt_n', '回单数', 'number', null, defaultReceiptN),
    receiptNShow: show('receipt_info'),
    receiptNRequired: required('receipt_info'),
    ...field('receipt_num', '回单号'),
    ...field('expected_release_time', '回单预计发放时间'),
    ...field('receipt_require', '回单要求', null, null, defaultReceiptRequire),
    // eslint-disable-next-line no-nested-ternary
    receiptRequire: receiptRequireLock ? (receiptRequire ? receiptRequire.split(',') : []) : receiptRequire,
    receiptRequireLock,
    receiptRequireEnum: setting.receipt_require_list.selc_value,
    ...field('trsp_mode', '运输方式', null, null, defaultTransportMode),
    trspModeEnum,
    ...field('actual_weight', '实际重量'),
    actualWeightShow: true,
    ...field('shortfall_weight', '亏吨重量'),
    ...field('actual_volume', '实际体积'),
    actualVolumeShow: true,
    ...field('shortfall_volume', '亏方体积'),
    ...field('remark', '备注', null, null),
    // eslint-disable-next-line no-nested-ternary
    remark: remarkLock ? (remark ? remark.split(',') : []) : remark,
    remarkLock,
    remarkEnum: setting.transport_remark.selc_value,
    ...field('inner_remark', '内部备注', null, null),
    ...field('co_img', '订单附件', null, null, [], true),
    declareRemarkMoney,
    declareRemark,
    ...field('od_currency_0001', '自定义数字1'),
    ...field('od_currency_0002', '自定义数字2'),
    ...field('od_currency_0003', '自定义数字3'),
    ...field('od_currency_0004', '自定义数字4'),
    deliveryRemarkMoney,
    deliveryRemark,
    receiptRemark,
    deliveryModeRemark,
    remarkWhenDeliveryModeIs,
    noticeDeliveryAutoRemark: setting.notice_to_release.value.includes('auto_fill'),

    // 自动匹配高德地址
    autoMatchGdAddr: comSetting.auto_match_gd_addr?.checked,

    taxRate,
    // 表格
    tableTabs,
    tableTabKeys,
    showType: setting.show_type.position || 'plain',
    ...table('point_cost_info', { tax_r: taxRate }),
    pointCostInfoHeader,
    originPointCostInfoHeader,
    ...table('std_cost', {}),
    ...table('tr_info'),
    ...table('sign_info'),
    ...table('trans_info'),
    ...table('delivery_info'),
    ...table('cost_info'),
    ...table('shuttle_info'),
    ...table('pickup_info'),
    ...table('budget_info'),
    innerRemarkInfo: [],
    innerRemarkInfoHeader: hasPermission('viewRecord') && innerRemarkHeader,
    showInnerRemarkAdd: hasPermission('addRecord'),
    showInnerRemarkSubtract: hasPermission('editRecord'),
    pickupFPaidShow: outgoFeeShow.includes('pickup_f_paid'),
    bInfoFPaidShow: outgoFeeShow.includes('b_info_f_paid'),
    pickupFShow: show('pickup_f'),
    pickupFRequired: required('pickup_f'),
    autoCalcTax,
    maxRate: setting.max_rate ? setting.max_rate.heightVal : '',
    noModifyCorMaintain: setting.no_modify_maintain && setting.no_modify_maintain.checked,
    noModifyCeeMaintain: setting.no_modify_cee_maintain && setting.no_modify_cee_maintain.checked,
    // 收发货客户维护费公式
    corMaintainKeys,
    corMaintainKeyMap,
    ceeMaintainKeys,
    ceeMaintainKeyMap,
    // 初始收发货人维护费规则 & 系数
    ceeMaintainByFormula,
    corMaintainByFormula,
    corMaintainRatio,
    ceeMaintainRatio,
    corSalesmenCanAdd: !(setting.salesmen_only_in && setting.salesmen_only_in.checked),
    ceeSalesmenPermission,
    ceeSalesmenCanAdd: !(setting.cee_salesmen_only_in && setting.cee_salesmen_only_in.checked) && ceeSalesmenPermission,
    bTrNumLock:
      runSetting.batch_select_trdr_info &&
      runSetting.batch_select_trdr_info.options &&
      runSetting.batch_select_trdr_info.options.includes('tr_num'),
    bDrInfoLock:
      runSetting.batch_select_trdr_info &&
      runSetting.batch_select_trdr_info.options &&
      runSetting.batch_select_trdr_info.options.includes('dr_info'),
    enableInnerTrans,
    showTransSubtract,
    transVolumeRatio: (transSetting && +transSetting.trans_v_ratio.value) || 1,
    transTypeEnum,
    transPayKeys,
    transPayList,
    modifyTransPayModeEnum: convertEnum(ext.trans_pay_mode || {}),
    transPayModeEnum: {
      [INNER_TRANS]: innerTransPayModeEnum,
      [OUTER_TRANS]: outerTransPayModeEnum,
      [POINT_TRANS]: pointTransPayModeEnum,
    },
    payArrivalTransPayModeEnum: {
      [INNER_TRANS]: payArrivalInnerTransPayModeEnum,
      [OUTER_TRANS]: payArrivalOuterTransPayModeEnum,
      [POINT_TRANS]: payArrivalPointTransPayModeEnum,
    },
    defaultTransPayMode: {
      [INNER_TRANS]: defaultInnerTransPay,
      [OUTER_TRANS]: defaultOuterTransPay,
      [POINT_TRANS]: defaultPointTransPay,
    },
    transFeeList: {
      [INNER_TRANS]: innerTransFeeList,
      [OUTER_TRANS]: outerTransFeeList,
      [POINT_TRANS]: pointTransFeeList,
    },
    transFeeKeys: {
      [INNER_TRANS]: innerTransFeeKeys,
      [OUTER_TRANS]: outerTransFeeKeys,
      [POINT_TRANS]: pointTransFeeKeys,
    },
    transFieldSetting: {
      [OUTER_TRANS]: outerTransFieldSetting,
      [INNER_TRANS]: innerTransFieldSetting,
      [POINT_TRANS]: pointTransFieldSetting,
    },
    transFPaidShow: {
      [OUTER_TRANS]: !!runSetting.show_trans_f_paid.checked,
      [POINT_TRANS]: !!runSetting.show_point_trans_f_paid.checked,
      [INNER_TRANS]: false,
    },
    transPickTypeEnum,
    transBillingMethod,
    transCorPickDist: ext.trans_cor_pick_dist,
    transCeePickDist: ext.trans_cee_pick_dist,
    transInputRebate,
    validateCorMobile,
    validateCeeMobile,
    validateCashreturnPhone,
    validateDiscountPhone,
    validateRebatePhone,
    validateBInfoPhone,
    validateBLocPhone,
    validateLocTrPhone,
    coSend,
    sendFlag,
    transFlag,
    pointFlag,
    budgetFlag,
    pickupFlag,
    calcOuterPrice,
    useJAVAPrice,
    r2oFillPriceWhenEmpty: basicSetting.r2o_price_rule && basicSetting.r2o_price_rule.value === 'fill_when_empty',
    priceSnapshot: {},
    priceSnapshotDelete: {},

    QRCode: val('qrcode'),

    mileRatio,

    // 地图相关
    ceeMatchArr,
    showArrPoint,
    showMap,

    // 单票毛利
    profit: 0,
    profitShow:
      show('profit') &&
      !ext.d_profit_hide &&
      ((!ext.prev_od_link_id && !ext.inner_trans_index) || +ext.practical_route_ol_up === 7),
    profitRate: 0,
    effectProfitKeys,
    effectProfitKeyMap,
    profitFormula,
    profitLt0Limit,

    // 底部按钮相关
    printBtn,
    printItems,
    collectPrintFees,
    collectPrintTypes,
    collectPrintFeesIncludePickupF,
    settingSubList,
    showPreview,
    showAddPrint,
    showSavePrint,
    showSaveAdd,
    showSave,
    showNoticeMessage,
    showScanPay,
    buttons,
    // orderInfoOpType: setting.order_info_op_type.value,

    textMap,
    validateMap,
    getMap,
    keyMap,
    camelKey,

    path,
    appDispatchPermission,
    driverMgrPermission,
    // 是否开启扣款设置
    isOpenPayment,

    // 初始化价格来源
    feeSource: feeSource(ext.fee_source, order.goods),
    // 存储收发货人附件信息
    ceeAllAttachment: {
      goods_claims: ext?.cee?.goods_claims ?? [],
      attachment: ext?.cee?.attachment ?? [],
    },
    corAllAttachment: {
      goods_claims: ext?.cor?.goods_claims ?? [],
      attachment: ext?.cor?.attachment ?? [],
    },
  };
}

export default adaptor;
