/**
 * Created by wangnaihe on 2017/6/19.
 */
import React, { Fragment } from 'react';
import _ from 'lodash';
import { showInfo, buildTipMixin, fetchApi as fetch, confirm } from 'utils';
import { WARN, ERROR, CHECK, FLOAT, MOBILE, PAY_MODE_SAME_TO_ORDER, REGULAR, FLOAT_P_6, FLOAT_P_3 } from 'constants';
import {
  DataList,
  Select,
  Tabs,
  PureInput as Input,
  PureCheckbox as Checkbox,
  DateTimePicker,
  Icon,
  Tips,
  AddrSug,
  PopUp,
} from 'components';
import PriceSnapshotIcon from 'components/commoncomponents/PriceSnapshotIcon';
import StaffSlider from 'pages/CompanyController/companyGroup/components/SiderDrager';
import { addDriver } from 'components/commoncomponents/listOperation/driverOperat.js';
import { getDistance, calcField } from '../tool';
import {
  SET_SUG_DATA,
  MERGE_DATA,
  OUTER_TRANS,
  INNER_TRANS,
  POINT_TRANS,
  tableKeysMap,
  transFeeKeys,
  tableFeeKeys,
  tableInfo,
  paidTip,
} from '../constant';
import {
  sugHeaderMap,
  sugMap,
  sugHighlightMap,
  totalCostTip,
  transInfoDisabledKeys,
  tableCalcPriceFee,
} from './constant';
import {
  feeRelate,
  calcPayTotal,
  calcFeeTotal,
  previewImg,
  getEnumText,
  transPayModeRelate,
  getTransPayModeEnum,
  calcTax,
  transFieldRequired,
  editTransWeightVolume,
  calcTransFreight,
  getTransRoute,
  getDefaultTransRoute,
  dnComRelate,
  getInnerRemark,
} from './tool';
import PointCostEditModal from './pointCostEditModal';
import InnerRemarkEditModal from './innerRemarkEditModal';
import { prefixCls } from './index.scss';

const { TabPane } = Tabs;

const maintainTip = buildTipMixin(
  '维护费是根据单价或公式计算的，不允许修改；如需修改，请联系管理员修改客户的维护费计算规则。',
);

const ceeSalesmenTip = buildTipMixin('您没有维护收货业务员的权限，请联系系统管理员开通！');

const receivedTip = buildTipMixin('已收');

const needDispatchFlagTip = buildTipMixin('您设置了勾选“需要配安”的运单，才能勾选派单！');

const noDispatchDrTip = buildTipMixin('请先选择配安司机和手机号，才能勾选派单！');

const dispatchDrTip = buildTipMixin('勾选后，会派单到配安司机APP上');

const dnComTip = buildTipMixin('承运网点必须是运单路由的第二个节点！如需修改承运网点，请修改或清空路由！');

const transRouteTip = buildTipMixin('必须选择了“承运商”和运单的“到站”或“目的网点”时，才能选择“承运路由');

function renderInfo(page) {
  const drag = (e, tableKey, i) => {
    const start = e.clientX;
    const { target } = e;
    const th = target.parentNode;
    const width = th.offsetWidth;
    const table = th.parentNode.parentNode.parentNode;
    const height = table.offsetHeight - 2;
    let end = start;
    const onMove = e1 => {
      e1.preventDefault();
      end = e1.clientX;
      const delta = end - start;
      width + delta > 0 && (target.style.transform = `translate3d(${delta}px, 0 ,0)`);
    };
    const onEnd = () => {
      const { store } = page;
      const state = store.getState();
      const headerKey = `${tableKey}Header`;
      const header = [...state[headerKey]];
      const delta = end - start;
      const newWidth = Math.max(width + delta, 4);
      target.style.height = '';
      target.style.transform = '';
      target.className = 'table-drager';
      document.removeEventListener('mousemove', onMove);
      document.removeEventListener('mouseup', onEnd);
      header[i] = { ...header[i], style: { minWidth: newWidth, maxWidth: newWidth } };
      store.dispatch({ type: MERGE_DATA, payload: { [headerKey]: header } });
      const tables = [...page.wrap.querySelectorAll('table')];
      tables.forEach(item => (item.style.display = 'block')); // eslint-disable-line
      setTimeout(() => tables.forEach(item => (item.style.display = ''))); // eslint-disable-line
    };
    target.className = 'table-drager active';
    target.style.height = `${height}px`;
    document.addEventListener('mousemove', onMove);
    document.addEventListener('mouseup', onEnd);
  };

  const showContextMenu = (e, tableKey, i) => {
    e.preventDefault();
    const menu = document.createElement('ul');
    const onMenuClick = e1 => {
      const { store } = page;
      const state = store.getState();
      const { className } = e1.target;
      const headerKey = `${tableKey}Header`;
      const header = [...state[headerKey]];
      if (className === 'hide-col') {
        header[i] = { ...header[i], hidden: true };
        store.dispatch({ type: MERGE_DATA, payload: { [headerKey]: header } });
      }
      if (className === 'show-hidden-col') {
        store.dispatch({
          type: MERGE_DATA,
          payload: { [headerKey]: header.map(item => ({ ...item, hidden: false })) },
        });
      }
      if (className === 'adjust-width') {
        header[i] = { ...header[i], style: { minWidth: 0 } };
        store.dispatch({ type: MERGE_DATA, payload: { [headerKey]: header } });
      }
    };

    const hideMenu = () => {
      document.body.removeChild(menu);
      document.removeEventListener('click', hideMenu);
    };

    menu.className = 'order-editor-context-menu';
    menu.style.left = `${e.clientX}px`;
    menu.style.top = `${e.clientY}px`;
    menu.innerHTML = `
      <li class="hide-col"><i class="fn-icon fn-icon-hide"></i>隐藏列</li>
      <li class="show-hidden-col"><i class="fn-icon fn-icon-ps-show"></i>显示隐藏列</li>
      <li class="adjust-width"><i class="fn-icon fn-icon-auto-width"></i>宽度自适应</li>
    `;
    menu.addEventListener('click', onMenuClick);
    document.body.appendChild(menu);
    document.addEventListener('click', hideMenu);
  };

  let addSalesmenSlider;

  const addSalesmen = (key, name) => {
    addSalesmenSlider && addSalesmenSlider.handleHide();
    new PopUp(StaffSlider, {
      popName: 'addSalesmen',
      type: 'User',
      opType: 'add',
      inforeq: { User_id: '0', type: 'User', readonly: false, name, src: 'co', show_type: '1' },
      opreq: { User_id: '', type: 'User' },
      title: '添加业务员',
      currId: window.company_id,
      ref: r => (addSalesmenSlider = r),
      onConfirm: (res, req) => {
        const user = req.org_info;
        const mgrIdEnum = page.store.getState()[`stdCost0${key}Enum`] || [];
        const mgrId = { id: res.res[1], name: user.name };
        page.setSug(`stdCost0${key}`, [...mgrIdEnum, mgrId]);
        page.setTable('stdCost', 0, { [key]: mgrId });
      },
    }).show();
  };

  const addDriverClick = tableKey => {
    addDriver(
      this,
      null,
      driverInfo => {
        const enums = page.store.getState();
        const drEnum = page.store.getState().pointCostInfo0b_delivery_drEnum || [];
        const newDriver = { ...driverInfo, short_name: window.company_info.short_name };
        page.setSug('pointCostInfo0dispatch_dr', [...drEnum, newDriver]);
        page.setTable(tableKey, 0, { dispatch_dr_name: newDriver.name, dispatch_dr_phone: newDriver.tel });
      },
      { company_id: window.company_id },
    );
  };

  const validateBlackDriver = val => {
    if (val === '1') {
      // showInfo(WARN, '当前司机已被拉黑')
      return { type: 'warn', msg: '当前司机已被拉黑' };
    } else if (val === '2') {
      return '当前司机已被拉黑';
    }
    return '';
  };

  const customValidator = {
    pickup_dr_name: () => {
      const state = page.store.getState();
      return validateBlackDriver(_.get(state, 'pickupDrInfo.black_set'));
    },
    pickup_dr_phone: () => {
      const state = page.store.getState();
      return validateBlackDriver(_.get(state, 'pickupDrInfo.black_set'));
    },
    dispatch_dr_name: () => {
      const state = page.store.getState();
      return validateBlackDriver(state.pointCostInfo[0].dispatch_dr_black);
    },
    dispatch_dr_phone: () => {
      const state = page.store.getState();
      return validateBlackDriver(state.pointCostInfo[0].dispatch_dr_black);
    },
    b_dr_name: () => {
      const state = page.store.getState();
      return validateBlackDriver(state.pointCostInfo[0].b_dr_black);
    },
    b_dr_phone: () => {
      const state = page.store.getState();
      return validateBlackDriver(state.pointCostInfo[0].b_dr_black);
    },
  };
  const salesmenBlur = (key, e) => {
    const { dataset } = e.target;
    if (dataset.userInput && !dataset.isSelect) addSalesmen(key, dataset.userInput);
  };

  const editPointCostInfo = row =>
    new PopUp(PointCostEditModal, { popName: 'editCost', data: row, onSaveSuccess: page.props.onSaveSuccess }).show();

  const toggleDnComInfo = async () => {
    const { store } = page;
    const state = page.store.getState();
    const { transInfo, dnComInfo, dnComInfoShow, dnComInfoLoading } = state;
    const outerTrans = transInfo.find(item => +item.trans_type === OUTER_TRANS);
    if (!outerTrans || dnComInfoLoading) return false;
    store.dispatch({ type: MERGE_DATA, payload: { dnComInfoLoading: true } });
    const req = {
      carrier_id: outerTrans.dn_carrier_id,
      carrier_info: { carrier_id: outerTrans.dn_carrier_id },
      op_type: 'view',
    };
    const res = dnComInfo || (await fetch('/Basic/Carrier/carrierInfo?raw=1', { method: 'POST', body: { req } }));
    store.dispatch({
      type: MERGE_DATA,
      payload: { dnComInfoShow: !dnComInfoShow, dnComInfo: res, dnComInfoLoading: false },
    });
  };

  const renderDnComArrInfo = () => {
    const { dnComInfo, dnComInfoShow, setting } = page.store.getState();
    const arr = _.get(dnComInfo, 'data.data3.dataList', {});
    const header = arr.header && setting.carrier_arr_info_info.show.map(key => ({ ...arr.header[key], key }));
    const data = (arr.data || []).map(item => ({
      ...item,
      city: item.city ? item.city.show_val : '',
      address: item.address ? item.address.show_val : '',
    }));
    return (
      !!setting.carrier_arr_info_info.show.length && (
        <Fragment>
          <Icon iconType="icon-carry-out" classname="dn-com-info-toggle" onClick={toggleDnComInfo} />
          {dnComInfoShow && !!header && <span className="line-v" />}
          {dnComInfoShow && !!header && (
            <table className="dn-com-info">
              <tbody>
                {data.map((row, i) => (
                  <tr key={i}>
                    {header.map((item, j) => (
                      <td key={j}>
                        {item.title} : {row[item.key]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Fragment>
      )
    );
  };

  const add = tableKey => {
    const state = page.store.getState();
    if (tableKey === 'innerRemarkInfo') {
      const { odBasicId, odLinkId } = state;
      new PopUp(InnerRemarkEditModal, {
        popName: 'editInnerRemark',
        odBasicId,
        odLinkId,
        onSaveSuccess: () => getInnerRemark(page),
      }).show();
    } else if (tableKey === 'transInfo') {
      const data = state[tableKey] || [];
      if (data.length >= 2) {
        showInfo(ERROR, '只支持同时员工中转和外部中转！');
        return;
      }
      const val = { trans_pay_arrival: state.payArrival };
      page.setTable(tableKey, data.length, val);
      setTimeout(() => page.initTips(), 100);
    }
  };

  const minus = async (tableKey, i, row) => {
    if (tableKey === 'innerRemarkInfo' && (await confirm(WARN, '您确定要删除这条内部备注吗？'))) {
      await fetch('Order/OrderSelect/deleteRecord', { method: 'POST', body: { req: { id: row.id } } });
      showInfo(CHECK, '删除成功！');
      setTimeout(() => getInnerRemark(page), 1500);
    } else if (tableKey === 'transInfo') {
      page.setTable(tableKey, i);
    }
  };

  const edit = (tableKey, i, row) => {
    const state = page.store.getState();
    if (tableKey === 'innerRemarkInfo') {
      const { odBasicId, odLinkId } = state;
      new PopUp(InnerRemarkEditModal, {
        popName: 'editInnerRemark',
        data: row,
        odBasicId,
        odLinkId,
        onSaveSuccess: () => getInnerRemark(page),
      }).show();
    }
  };

  const onTabChange = key => {
    if (key === 'innerRemarkInfo') getInnerRemark(page);
    setTimeout(() => page.store.getState().ext.promp && page.initTips(page.store.getState().ext.promp));
  };

  return function () {
    const { store } = page;
    const state = store.getState();
    const {
      noModifyCorMaintain,
      noModifyCeeMaintain,
      weightUnit,
      useJAVAPrice,
      priceSnapshot,
      priceSnapshotDelete,
      odLinkId,
    } = state;
    const {
      cid,
      companyName,
      isDetail,
      enums,
      ext,
      companyInfo,
      mileRatio,
      isCreate,
      goods,
      route,
      arrInfo,
      arrPoint,
    } = state;
    const { showType, tableTabs, tableTabKeys, pointCostInfo, pointCostInfoHeader, stdCost, stdCostHeader } = state;
    const { trInfo, trInfoHeader, signInfo, signInfoHeader, transInfo, transInfoHeader } = state;
    const { shuttleInfo, shuttleInfoHeader, pickupInfo, pickupInfoHeader } = state;
    const { deliveryInfo, deliveryInfoHeader, costInfo, costInfoHeader } = state;
    const { budgetInfo, budgetInfoHeader } = state;
    const { pickupFPaidShow, bInfoFPaidShow, showTransSubtract, transFPaidShow, transVolumeRatio } = state;
    const { transTypeEnum, transPayModeEnum, transPayKeys, transPayList } = state;
    const { validateBInfoPhone, validateBLocPhone, validateLocTrPhone } = state;
    const { isModify, autoCalcTax, maxRate, transPickTypeEnum, transInputRebate, transBillingMethod } = state;
    const { driverMgrPermission, ceeSalesmenPermission } = state;
    const { corSalesmen, ceeSalesmen, corSalesmenCanAdd, ceeSalesmenCanAdd } = state;
    const { dispatch, appDispatchPermission, needDispatch } = state;
    const { usedFor } = page.props;

    const isHidden = tableKey => (state[`${tableKey}Hidden`] ? ' hidden' : '');

    const toggle = tableKey => page.set(`${tableKey}Hidden`, !state[`${tableKey}Hidden`]);

    // 增量更新一行数据
    const set = page.setTable;

    const onInput = (tableKey, i, key, val) => {
      if (key === 'pickup_dr_name' || key === 'pickup_dr_phone') {
        state.pickupDrInfo && page.merge('pickupDrInfo', null);
      }
      set(tableKey, i, { [key]: val });
    };

    const setbDrInfo = (tableKey, i, data, other = {}) => {
      const payeeAry = data.payee || [];
      const payeeObj =
        payeeAry.length !== 1
          ? {}
          : {
              b_tr_payee_name: payeeAry[0].tr_payee_name,
              b_tr_payee_open_bank: payeeAry[0].tr_payee_open_bank,
              b_tr_payee_open_phone: payeeAry[0].tr_payee_open_phone,
              b_tr_payee_bank_card_num: payeeAry[0].tr_payee_bank_card_num,
              b_tr_payee_id: payeeAry[0].id,
              b_tr_payee_id_card: payeeAry[0].tr_payee_id_card,
            };
      const newSet = { ...payeeObj, ...other };
      set(tableKey, i, newSet);
    };

    const sug = (enumKey, key, keyword, select, other) => {
      const sugConf = sugMap[key];
      const searchKey = sugConf.searchKey || 'query';
      const { url } = sugConf;
      const query = sugConf.query || {};
      const req = { company_id: cid, [searchKey]: keyword, ...query, ...other };
      const conf = { method: 'POST', body: { req } };
      select.fetch(url, conf).then(res => {
        const data = key === 'dn_com_id' ? res.res.filter(item => +item.trans_type !== 1) : res.res;
        store.dispatch({ type: SET_SUG_DATA, payload: { key: enumKey, data } });
      });
    };

    const selectSug = (tableKey, i, key, data) => {
      if (key === 'b_tr_num') {
        setbDrInfo(tableKey, i, data, { b_dr_name: data.dr_name, b_dr_phone: data.dr_tel, b_tr_num: data.tr_num });
        // set(tableKey, i, { b_dr_name: data.dr_name, b_dr_phone: data.dr_tel, b_tr_num: data.tr_num })
      }

      if (key === 'pickup_tr_num') {
        set(tableKey, i, { pickup_dr_name: data.dr_name, pickup_dr_phone: data.dr_tel, pickup_tr_num: data.tr_num });
      }

      if (key === 'b_dr_name') {
        setbDrInfo(tableKey, i, data, { b_dr_name: data.name, b_dr_phone: data.tel, b_dr_black: data.black_set });
        // set(tableKey, i, { b_dr_name: data.name, b_dr_phone: data.tel })
        if (data && data.black_set === '1') {
          showInfo(WARN, '当前司机已被拉黑');
        }
      }

      if (key === 'pickup_dr_name') {
        set(tableKey, i, { pickup_dr_name: data.name, pickup_dr_phone: data.tel });
        page.merge('pickupDrInfo', data);
        if (data && data.black_set === '1') {
          showInfo(WARN, '当前司机已被拉黑');
        }
      }

      if (key === 'b_dr_phone') {
        setbDrInfo(tableKey, i, data, { b_dr_name: data.name, b_dr_phone: data.tel, b_dr_black: data.black_set });
        // set(tableKey, i, { b_dr_name: data.name, b_dr_phone: data.tel })
        if (data && data.black_set === '1') {
          showInfo(WARN, '当前司机已被拉黑');
        }
      }

      if (key === 'pickup_dr_phone') {
        set(tableKey, i, { pickup_dr_phone: data.tel });
        page.merge('pickupDrInfo', data);
        if (data && data.black_set === '1') {
          showInfo(WARN, '当前司机已被拉黑');
        }
      }

      if (['b_tr_payee_name', 'b_tr_payee_bank_card_num'].includes(key)) {
        set(tableKey, i, {
          b_tr_payee_name: data.tr_payee_name,
          b_tr_payee_open_bank: data.tr_payee_open_bank,
          b_tr_payee_open_phone: data.tr_payee_open_phone,
          b_tr_payee_bank_card_num: data.tr_payee_bank_card_num,
          b_tr_payee_id: data.id,
          b_tr_payee_id_card: data.id_card,
        });
      }
    };

    const sugTransRoute = (enumKey, row, keyword, select) => {
      getTransRoute(keyword, row, state, select.fetch).then(res =>
        store.dispatch({ type: SET_SUG_DATA, payload: { key: enumKey, data: res } }),
      );
    };

    const setTransPayMode = (i, val, row, tip = true, isHandle = false) => {
      const newVal = transPayModeRelate(state, i, val, row, tip);
      newVal.isHandle = isHandle;
      set('transInfo', i, newVal);
    };

    const setTransFeeItem = (i, key, val, row) => {
      const newVal = { [key]: val };
      const feeKeys = state.transFeeKeys[row.trans_type] || {};
      if (feeKeys[key]) {
        const tempRow = { ...row, [key]: val };
        const transFee = calcFeeTotal(state, tempRow);
        const relate = feeRelate(state, tempRow, transFee);
        Object.assign(newVal, relate);
      }
      set('transInfo', i, newVal);
    };

    const setTransSettleMoney = (i, key, val, row) => {
      const feeKeys = state.transFeeKeys[row.trans_type] || {};
      const newVal = { [key]: val };
      if (transInputRebate && +row.trans_pay_arrival && feeKeys.trans_freight_f) {
        Object.keys(feeKeys).forEach(fee => (newVal[fee] = 0)); // 清空中转XX费
        if (!+val) {
          newVal.trans_freight_f = ''; // 如中转返款清空,中转**费也清空
        } else {
          newVal.trans_freight_f = (+state.payArrival - (+val || 0)).toFixed(2); // 中转费 = 到付 - 中转返款
        }
        newVal.trans_f = newVal.trans_freight_f; // 重新计算中转费合计 = 到付 (中转 XX费 被清空了)
      }
      set('transInfo', i, newVal);
    };

    const setTransPayItem = (i, key, val, row) => {
      const transFee = +row.trans_f || 0;
      const tempRow = { ...row, [key]: val };
      const totalPay = calcPayTotal(tempRow, transPayList);
      const newVal = { [key]: val };
      const transType = +row.trans_type;
      if (transType === OUTER_TRANS) {
        newVal.trans_settle_money = +(+state.payArrival - transFee).toFixed(2);
      } else {
        newVal.trans_settle_money = +(totalPay - transFee).toFixed(2);
      }
      set('transInfo', i, newVal);
    };

    const calcDist = (i, row) => {
      const transType = +row.trans_type;
      if (transType !== OUTER_TRANS && transType !== POINT_TRANS) return;
      if (row.trans_pick_type === 'from_com') {
        const endPoi = companyInfo.address && companyInfo.address.poi;
        const start = row.dn_com_id && row.dn_com_id.address;
        const startPoi = start && (typeof start === 'string' ? JSON.parse(start).poi : start.poi);
        getDistance(startPoi, endPoi, mileRatio).then(distance => {
          transType === POINT_TRANS &&
            (state.pointTransCorPickDist || distance) &&
            page.set('pointTransCorPickDist', distance);
          transType === OUTER_TRANS && (state.transCorPickDist || distance) && page.set('transCorPickDist', distance);
        });
      } else {
        transType === POINT_TRANS && state.pointTransCorPickDist && page.set('pointTransCorPickDist', 0);
        transType === OUTER_TRANS && state.transCorPickDist && page.set('transCorPickDist', 0);
      }
    };

    const setTransType = (i, val, row) => {
      const newVal = { trans_type: val };
      const payArrival = +state.payArrival;
      const payArrivalTransPayModeEnum = state.payArrivalTransPayModeEnum[val] || [];
      const defaultTransPayMode = state.defaultTransPayMode[val] || '';
      const sup = state.ext.co_distribute_info;
      // 更新中转单号
      newVal.trans_order_num = '';
      newVal.trans_dn_carrier_phone = '';
      newVal.trans_dn_carrier_phone2 = '';
      newVal.trans_dn_carrier_cs_phone = '';
      newVal.trans_dn_carrier_addr = '';
      newVal.trans_dn_carrier_addr_remark = '';
      newVal.trans_dn_carrier_type = '';
      newVal.trans_dn_carrier_is_monthly = '';

      newVal.address = '';
      newVal.trans_arr_address = '';
      newVal.trans_arr_address_remark = '';
      newVal.trans_arr_site = '';
      newVal.trans_arr_phone = '';
      newVal.trans_arr_cs_phone = '';
      newVal.trans_pickup_addr = '';
      newVal.trans_pickup_addr_remark = '';
      newVal.trans_w_detail = goods.map(item => +item.weight || 0);
      newVal.trans_w = +newVal.trans_w_detail
        .reduce((total, w) => total + (+w || 0), 0)
        .toFixed(state.weightUnit === 'kg' ? 3 : 6);
      newVal.trans_v_detail = goods.map(item => +((+item.volume || 0) * transVolumeRatio).toFixed(6));
      newVal.trans_v = +newVal.trans_v_detail.reduce((total, v) => total + (+v || 0), 0).toFixed(6);
      newVal.trans_n = calcField(goods, 'num');
      newVal.trans_pay_mode = '';
      newVal.trans_route = '';
      if (+val === OUTER_TRANS) {
        newVal.dn_com_id = '';
        newVal.billing_method = row.billing_method || 'per_w_kg';
      } else if (+val === POINT_TRANS) {
        newVal.trans_pick_type = '';
        newVal.billing_method = '';
        newVal.trans_unit_price = '';
        newVal.trans_order_num = state.orderNum;
        newVal.trans_pickup_addr = state.companyInfo.address;
        newVal.trans_pickup_addr_remark = state.companyInfo.address_remark;
        page.set('pointTransCorPickDist', 0);
        Object.assign(newVal, dnComRelate(route.node2 || (sup.length === 1 ? sup[0] : '')));
        getDefaultTransRoute(route, ext.co_distribute_info, state).then(
          tr => tr && set('transInfo', i, { trans_route: tr }),
        );
      } else {
        newVal.trans_pick_type = '';
        newVal.billing_method = '';
        newVal.trans_unit_price = '';
        newVal.dn_com_id = { id: cid, carrier_name: companyName };
      }

      if (payArrival && payArrivalTransPayModeEnum.length) {
        newVal.trans_pay_mode =
          payArrivalTransPayModeEnum.length === 2 ? 'pay_arrival' : payArrivalTransPayModeEnum[0].key;
      }
      if (!payArrival && defaultTransPayMode !== 'pay_arrival') {
        newVal.trans_pay_mode = defaultTransPayMode === PAY_MODE_SAME_TO_ORDER ? state.payMode : defaultTransPayMode;
      }

      // 修改付款方式关联修改
      const relate = transPayModeRelate(state, i, newVal.trans_pay_mode, { ...row, ...newVal }, true);
      let disable = [];
      // 根据中转类型设置不可编辑项
      if (+val === POINT_TRANS) {
        disable = [
          'trans_order_num',
          'trans_dn_carrier_phone2',
          'address',
          'trans_arr_phone',
          'dn_mgr_id',
          'trans_std_trans_f',
        ];
      } else {
        disable = ['trans_pickup_addr', 'trans_pickup_addr_remark', 'trans_route'];
      }
      set('transInfo', i, {
        ...newVal,
        ...relate,
        otherProps: { disable: Array.from(new Set([...transInfoDisabledKeys, ...disable])) },
      });
    };
    const setAddress = (tableKey, i, key, val) => {
      const newVal = { [key]: val };
      newVal.trans_arr_address = val.address && val.address.show_val;
      newVal.trans_arr_address_remark = val.address_mark;
      newVal.trans_arr_site = val.site;
      newVal.trans_arr_phone = val.contract_phone;
      newVal.trans_arr_cs_phone = val.cs_phone;
      set(tableKey, i, newVal);
    };
    const setDnCompany = (tableKey, i, key, val, row) => {
      const transType = +row.trans_type;
      const newVal = transType === OUTER_TRANS ? {} : dnComRelate(val);
      if (transType === OUTER_TRANS) {
        const payArrival = +state.payArrival;
        const arrivalInfo = val.arrival_infos ? JSON.parse(val.arrival_infos) : [];

        const arrEnum = arrivalInfo.map(item => ({ ...item, key: item.reference, name: `${item.city.show_val}` }));
        const payModeEnumOfSetting = transPayModeEnum[transType] || [];
        const companyPayModeList = (val.pay_mode && JSON.parse(val.pay_mode)) || [];
        const companyPayModeEnum = payModeEnumOfSetting.filter(item => companyPayModeList.includes(item.key));
        newVal[key] = val && { ...val, arrEnum, payModeEnum: companyPayModeEnum };

        let transPayMode = companyPayModeEnum.find(item => item.key === row.trans_pay_mode) ? row.trans_pay_mode : '';

        if (!transPayMode && payArrival) {
          if (companyPayModeEnum.find(item => item.key === 'pay_arrival')) {
            transPayMode = 'pay_arrival';
          } else if (companyPayModeEnum.find(item => item.key === 'pay_multi')) {
            transPayMode = 'pay_multi';
          }
        }

        if (!payArrival) {
          const comDefaultPayMode =
            val.default_pay_mode === PAY_MODE_SAME_TO_ORDER
              ? state.payMode
              : val.default_pay_mode || val.trans_pay_mode;
          if (companyPayModeEnum.find(item => item.key === comDefaultPayMode)) {
            transPayMode = comDefaultPayMode;
          } else {
            transPayMode = '';
          }
        }
        Object.assign(newVal, transPayModeRelate(state, i, transPayMode, row, true));
        newVal.trans_dn_carrier_phone = val.telephone;
        newVal.trans_dn_carrier_phone2 = val.phone2;
        newVal.trans_dn_carrier_cs_phone = val.phone1;
        newVal.trans_dn_carrier_addr = val.address && val.address.show_val;
        newVal.trans_dn_carrier_addr_remark = val.address_remark;
        newVal.trans_dn_carrier_type = val.trans_type;
        newVal.trans_dn_carrier_is_monthly = val.is_month;
        newVal.address = '';
        newVal.trans_arr_address = '';
        newVal.trans_arr_address_remark = '';
        newVal.trans_arr_site = '';
        newVal.trans_arr_phone = '';
        newVal.trans_arr_cs_phone = '';
        if (arrInfo && arrInfo.addr) {
          // 默认中转到站等于运单到站
          const citys = arrEnum.map(item => item.city);
          const { province, city, district, street } = state.arrInfo.addr;
          const index = citys.findIndex(_.matches({ province, city, district, street }));
          if (index > -1) {
            setTimeout(() => setAddress(tableKey, i, 'address', arrEnum[index]), 100);
          }
          newVal.addressCustomized = newVal.addressCustomized && index > -1;
        }
      }
      set(tableKey, i, newVal);
      calcDist(i, { ...row, ...newVal });
    };

    const setTransPickType = (tableKey, i, key, val, row) => {
      const transType = +row.trans_type;
      set(tableKey, i, { [key]: val });
      if (transType === OUTER_TRANS || transType === POINT_TRANS) calcDist(i, { ...row, [key]: val });
    };

    const setTransFreight = (tableKey, i, transFreight, row) => {
      !page.edited('trans_freight_f', 'trans_info', i) && setTransFeeItem(i, 'trans_freight_f', transFreight, row);
    };

    // 中转计费方式
    const setTransBillingMethod = (tableKey, i, key, val, row) => {
      if (+row.trans_type !== OUTER_TRANS) return;
      set(tableKey, i, { [key]: val });
      if (!row.trans_unit_price || +row.trans_unit_price === 0) return;
      const newRow = { ...row, [key]: val };
      setTransFreight(tableKey, i, calcTransFreight(newRow), newRow);
    };

    // 中转单价
    const setTransUnitPrice = (tableKey, i, key, val, row) => {
      set(tableKey, i, { [key]: val });
      if (!val || +val === 0 || !row.billing_method) return;
      const newRow = { ...row, [key]: val };
      setTransFreight(tableKey, i, calcTransFreight(newRow), newRow);
    };

    // 中转单价
    const setTransWVNUnitPrice = (tableKey, i, key, val, row) => {
      set(tableKey, i, { [key]: val });
      const newRow = { ...row, [key]: val || 0 };
      setTransFreight(tableKey, i, calcTransFreight(newRow), newRow);
    };

    const setTransWDetail = (tableKey, i, key, val, row) => {
      set(tableKey, i, {
        [key]: val,
        trans_w: +val.reduce((total, w) => total + (+w || 0), 0).toFixed(weightUnit === 'kg' ? 3 : 6),
      });
      const newRow = { ...row, [key]: val };
      setTransFreight(tableKey, i, calcTransFreight(newRow), newRow);
    };

    const setTransVDetail = (tableKey, i, key, val, row) => {
      set(tableKey, i, { [key]: val, trans_v: +val.reduce((total, v) => total + (+v || 0), 0).toFixed(6) });
      const newRow = { ...row, [key]: val };
      setTransFreight(tableKey, i, calcTransFreight(newRow), newRow);
    };

    const onTransEditClick = (tableKey, i, row) =>
      editTransWeightVolume(row).then(data => {
        if (!data) return;
        setTransWDetail(tableKey, i, 'trans_w_detail', data.trans_w_detail, row);
        setTransVDetail(tableKey, i, 'trans_v_detail', data.trans_v_detail, { ...row, ...data });
      });

    const setBDeliveryDrName = (tableKey, i, val) => {
      const value = val || { id: '', name: '', tel: '', black_set: '' };
      const obj = {
        dispatch_dr_id: value.id,
        dispatch_dr_name: value.name,
        dispatch_dr_phone: value.tel,
        dispatch_dr_black: value.black_set,
      };
      if (!value.name) {
        obj.dispatch_st = 10;
      }
      page.setTable(tableKey, i, obj);
      validateBlackDriver(val.black_set);
    };

    const setMaintain = key => {
      const stdMaintainEle = page.getEle(`std_cost_${key}_0`);
      delete stdMaintainEle.dataset.isEdited;
      if (key !== 'std_mn_f') {
        page.set('ceeMaintain', state.ceeMaintain);
      } else {
        page.set('corMaintain', state.corMaintain);
      }
    };

    const setTax = row => {
      const pctTaxEle = page.getEle('point_cost_info_tax_0');
      delete pctTaxEle.dataset.isEdited;
      set('pointCostInfo', 0, { tax: calcTax(row.tax_r, state.totalPrice, state.taxInc, maxRate) });
    };

    const setTaxRate = (tableKey, i, key, val) => {
      const newVal = { [key]: val };
      !page.edited('tax', 'point_cost_info') && (newVal.tax = calcTax(val, state.totalPrice, state.taxInc, maxRate));
      set(tableKey, i, newVal);
    };

    const validateMaxTax = val => (maxRate && +val > +maxRate ? `税费上限${maxRate}元！` : '');

    const renderTd = (tableKey, i, row, item) => {
      const { key } = item;
      const value = row[key];
      const dataPath = `${tableKeysMap[tableKey]}_${key}_${i}`;
      const disableKeys = (row.otherProps && row.otherProps.disable) || [];
      const transType = +row.trans_type;
      const required = !!transFieldRequired(key, state, row);
      let pattern;
      let { type } = item;
      let disabled = isDetail || disableKeys.includes(key) || !!(ext.perm_hide && ext.perm_hide[key]) || false;
      let onChangeInput = e => set(tableKey, i, { [key]: e.target.value });

      if (tableFeeKeys[key] || (item.validate && item.validate.includes('float'))) pattern = FLOAT;

      if (isDetail) {
        if (type === 'Link')
          return (value || []).map((img, index) => <a onClick={() => previewImg(img)}>{index + 1}</a>);
        // 结算金额
        if (key === 'trans_settle_money') {
          return (
            <Input value={+value ? value : ''} data-path={dataPath} disabled>
              <Checkbox checked={!!row.trans_settle_money_paid} disabled {...receivedTip} />
            </Input>
          );
        }
        if (key === 'trans_f') {
          return (
            <Input value={+value ? value : ''} data-path={dataPath} disabled>
              {!!+value && !+state.payArrival && transFPaidShow[transType] && (
                <Checkbox checked={!!+row.trans_f_paid} disabled {...paidTip} />
              )}
            </Input>
          );
        }
        if (key === 'pickup_f') {
          return (
            <Input value={+value ? value : ''} data-path={dataPath} disabled>
              {pickupFPaidShow && <Checkbox checked={!!row.pickup_f_paid} disabled />}
              {!!(+value && useJAVAPrice && (priceSnapshot.pickup_f || !priceSnapshotDelete.pickup_f)) && (
                <PriceSnapshotIcon
                  className="input-icon"
                  data={priceSnapshot.pickup_f}
                  fee="pickup_f"
                  type="yd"
                  bizID={odLinkId}
                />
              )}
            </Input>
          );
        }
        if (key === 'b_info_f') {
          return (
            <Input value={+value ? value : ''} data-path={dataPath} disabled>
              {bInfoFPaidShow && <Checkbox checked={!!row.b_info_f_paid} disabled />}
            </Input>
          );
        }
        if (key === 'trans_w_detail' || key === 'trans_v_detail') {
          return <div data-path={dataPath}>{(value || []).join(',') || ' '}</div>;
        }
        if (+value && useJAVAPrice && tableCalcPriceFee[key]) {
          const priceKey = key;
          return (
            <Input value={+value ? value : ''} data-path={dataPath} disabled>
              {!!(priceSnapshot[priceKey] || !priceSnapshotDelete[priceKey]) && (
                <PriceSnapshotIcon
                  className="input-icon"
                  data={priceSnapshot[priceKey]}
                  fee={priceKey}
                  type="yd"
                  bizID={odLinkId}
                />
              )}
            </Input>
          );
        }

        if (tableKey === 'pointCostInfo' && key === 'com_id' && row.can_edit) {
          const comName = getEnumText(enums[key], value);
          return (
            <div className="point-cost-info-com-id">
              {comName}
              <Icon iconType="icon-modify" tips="编辑网点支出" onClick={() => editPointCostInfo({ ...row, comName })} />
            </div>
          );
        }
        return <div data-path={dataPath}>{(enums[key] ? getEnumText(enums[key], value) : value) || ' '}</div>;
      }

      if (
        transPayKeys[key] ||
        transFeeKeys[key] ||
        (item.validate && item.validate.includes('float')) ||
        key === 'trans_settle_money'
      ) {
        pattern = FLOAT;
        item.format && item.format.round && +item.format.round === 6 && (pattern = FLOAT_P_6);
      }
      if (key === 'b_loc_phone' && validateBLocPhone) pattern = MOBILE;

      if (key === 'loc_tr_phone' && validateLocTrPhone) pattern = MOBILE;

      if (key === 'b_info_phone' && validateBInfoPhone) pattern = MOBILE;

      if (key === 'trans_w_detail') pattern = weightUnit === 'kg' ? FLOAT_P_3 : FLOAT_P_6;
      if (key === 'trans_v_detail') pattern = FLOAT_P_6;

      if (key === 'pickup_f') {
        return (
          <Input data-path={dataPath} pattern={pattern} disabled={disabled} value={value} onChange={onChangeInput}>
            {pickupFPaidShow && (
              <Checkbox
                checked={row.pickup_f_paid}
                disabled={disabled}
                onChange={e => set(tableKey, i, { pickup_f_paid: e.target.checked })}
              />
            )}
            {!!(+value && useJAVAPrice && (priceSnapshot.pickup_f || !priceSnapshotDelete.pickup_f)) && (
              <PriceSnapshotIcon
                className="input-icon"
                data={priceSnapshot.pickup_f}
                fee="pickup_f"
                type="yd"
                bizID={odLinkId}
              />
            )}
          </Input>
        );
      }

      if (key === 'b_info_f') {
        return (
          <Input data-path={dataPath} pattern={pattern} disabled={disabled} value={value} onChange={onChangeInput}>
            {bInfoFPaidShow && (
              <Checkbox
                checked={row.b_info_f_paid}
                disabled={disabled}
                onChange={e => set(tableKey, i, { b_info_f_paid: e.target.checked })}
              />
            )}
          </Input>
        );
      }

      if (key === 'tax') {
        return (
          <Input
            data-path={dataPath}
            pattern={pattern}
            disabled={disabled}
            value={value}
            customValidity={validateMaxTax}
            onChange={onChangeInput}
          >
            <i className="fn-icon fn-icon-calculate input-icon" onClick={() => setTax(row)} />
          </Input>
        );
      }

      if (key === 'tax_r' && autoCalcTax) {
        onChangeInput = e => setTaxRate(tableKey, i, key, e.target.value, row);
      }

      // 业务员
      if (key === 'std_mgr_id' || key === 'std_cee_mgr_id') {
        key === 'std_cee_mgr_id' && (disabled = !ceeSalesmenPermission || disabled);
        const enumKey = `${tableKey}${i}${key}`;
        const canAdd = (key === 'std_mgr_id' ? corSalesmenCanAdd : ceeSalesmenCanAdd) && !disabled;
        const ids = key === 'std_mgr_id' ? corSalesmen : ceeSalesmen;
        return (
          <Select
            data-path={dataPath}
            value={value}
            data={state[`${enumKey}Enum`]}
            header={sugHeaderMap[key]}
            map={false}
            showIcon={false}
            compare="id"
            disabled={disabled} // 下拉选择发货人时禁用
            filter={(keyword, select) => sug(enumKey, key, keyword, select, { ids })}
            onChange={val => set(tableKey, i, { [key]: val })}
            onBlur={canAdd ? e => salesmenBlur(key, e) : undefined}
            {...(key === 'std_cee_mgr_id' && !ceeSalesmenPermission ? ceeSalesmenTip : {})}
          >
            {canAdd && <i className="fn-icon fn-icon-add-rad input-icon add-mgr" onClick={() => addSalesmen(key)} />}
          </Select>
        );
      }

      // 维护费
      if (key === 'std_mn_f' || key === 'std_cee_mn_f') {
        const noModifyMaintain = key === 'std_mn_f' ? noModifyCorMaintain : noModifyCeeMaintain;
        disabled = noModifyMaintain || disabled;
        return (
          <Input
            data-path={dataPath}
            pattern={pattern}
            disabled={disabled}
            value={value}
            onChange={onChangeInput}
            {...(noModifyMaintain ? maintainTip : {})}
          >
            <i className="fn-icon fn-icon-calculate input-icon" onClick={() => setMaintain(key)} />
          </Input>
        );
      }

      // 结算金额
      if (key === 'trans_settle_money') {
        return (
          <Input
            data-path={dataPath}
            pattern={pattern}
            value={value}
            disabled={!(transInputRebate && +state.payArrival) || disabled}
            onChange={e => setTransSettleMoney(i, key, e.target.value, row)}
          >
            {!!+value && (
              <Checkbox
                data-path="trans_settle_money_paid"
                checked={!!row.trans_settle_money_paid}
                disabled={disabled}
                onChange={e => set(tableKey, i, { trans_settle_money_paid: e.target.checked })}
                {...paidTip}
              />
            )}
          </Input>
        );
      }
      if (key === 'trans_f' && (transType === OUTER_TRANS || transType === POINT_TRANS)) {
        return (
          <Input data-path={dataPath} pattern={pattern} value={value} disabled>
            {!!+value && !+state.payArrival && transFPaidShow[transType] && (
              <Checkbox
                data-path="trans_f_paid"
                checked={!!row.trans_f_paid}
                disabled={disabled}
                onChange={e => set(tableKey, i, { trans_f_paid: +e.target.checked, trans_f_paid_handle_change: true })}
                {...paidTip}
              />
            )}
          </Input>
        );
      }
      // 中转类型
      if (key === 'trans_type') {
        return (
          <Select
            data-path={dataPath}
            disabled={disabled || !isCreate}
            value={value}
            data={transTypeEnum}
            onChange={val => setTransType(i, val, row)}
          />
        );
      }

      // 中转付款方式 外部中转付款方式要从承运商sug带出
      if (key === 'trans_pay_mode') {
        return (
          <Select
            data-path={dataPath}
            disabled={disabled}
            value={value}
            data={getTransPayModeEnum(state, row)}
            filter={false}
            onChange={val => setTransPayMode(i, val, row, true, true)}
          />
        );
      }

      // 中转承运商 员工中转时禁用
      if (key === 'dn_com_id') {
        const enumKey = `${tableKey}${i}${key}`;
        if (+row.trans_type === POINT_TRANS) {
          return (
            <Select
              data-path={dataPath}
              value={value && value.length ? { short_name: value } : value}
              data={state[`${enumKey}Enum`]}
              map={false}
              compare="id"
              format="short_name"
              header={sugHeaderMap.point_trans_dn_com_id}
              disabled={disabled || !isCreate || (route && route.route_id)}
              onChange={val => setDnCompany(tableKey, i, key, val, row)}
              filter={sugMap[key] && ((keyword, select) => sug(enumKey, 'point_trans_dn_com_id', keyword, select))}
              {...(route && route.route_id ? dnComTip : undefined)}
            />
          );
        }
        return (
          <Select
            data-path={dataPath}
            value={value && value.length ? { carrier_name: value } : value}
            data={state[`${enumKey}Enum`]}
            map={false}
            compare="id"
            format="carrier_name"
            header={sugHeaderMap[key]}
            disabled={disabled || !isCreate || +row.trans_type === INNER_TRANS}
            onChange={val => setDnCompany(tableKey, i, key, val, row)}
            filter={sugMap[key] && ((keyword, select) => sug(enumKey, key, keyword, select))}
          />
        );
      }

      if (key === 'trans_route') {
        const enumKey = `${tableKey}${i}${key}`;
        const arr = arrInfo && arrInfo.addr ? arrInfo.addr : arrInfo;
        const editable = row.dn_com_id && ((arr && arr.show_val) || (arrPoint && arrPoint.company_id));
        return (
          <Select
            data-path={dataPath}
            value={value}
            data={state[`${enumKey}Enum`]}
            header={sugHeaderMap[key]}
            map={false}
            compare="id"
            format="route_nick"
            disabled={disabled || !editable}
            filter={(keyword, select) => sugTransRoute(enumKey, row, keyword, select)}
            onChange={val => set(tableKey, i, { [key]: val })}
            {...(disabled || editable ? undefined : transRouteTip)}
          />
        );
      }

      // 中转到站 下拉项由选择的承运商sug带出 员工中转时禁用
      if (key === 'address') {
        const dnCompany = row.dn_com_id;
        const isInner = +row.trans_type === INNER_TRANS;
        if (!isCreate && !isModify) return value || '';
        return (
          <Select
            required={required}
            data-path={dataPath}
            value={value}
            data={dnCompany ? dnCompany.arrEnum : undefined}
            header={sugHeaderMap[key]}
            disabled={disabled || !isCreate || isInner}
            filter="name"
            map={false}
            onChange={val => setAddress(tableKey, i, key, val)}
          />
        );
      }

      // 中转经办人 外部中转时禁用
      if (key === 'dn_mgr_id') {
        const enumKey = `${tableKey}${i}${key}`;
        const isOuter = +row.trans_type === OUTER_TRANS;
        return (
          <Select
            data-path={dataPath}
            value={isCreate ? value : { name: (enums[key] && enums[key][value] && enums[key][value].display) || '' }}
            data={state[`${enumKey}Enum`]}
            map={false}
            compare="id"
            disabled={disabled || !isCreate || isOuter}
            filter={sugMap[key] && ((keyword, select) => sug(enumKey, key, keyword, select))}
            onChange={val => set(tableKey, i, { [key]: val })}
          />
        );
      }

      // 中转交接方式
      if (key === 'trans_pick_type') {
        const isInner = +row.trans_type === INNER_TRANS;
        return (
          <Select
            data-path={dataPath}
            required={required}
            disabled={disabled || !isCreate || isInner}
            value={value}
            data={transPickTypeEnum}
            onChange={val => setTransPickType(tableKey, i, key, val, row)}
          />
        );
      }
      // 计费方式
      if (key === 'billing_method') {
        const isOuter = +row.trans_type === OUTER_TRANS;
        return (
          <Select
            data-path={dataPath}
            disabled={disabled || !isOuter}
            value={value}
            data={transBillingMethod}
            onChange={val => setTransBillingMethod(tableKey, i, key, val, row)}
          />
        );
      }
      // 中转单价和中转重量体积发生变化
      if (key === 'trans_unit_price') {
        if (+row.trans_type !== OUTER_TRANS) disabled = true;
        onChangeInput = e => setTransUnitPrice(tableKey, i, key, e.target.value, row);
      }
      if (
        [
          'trans_v_detail',
          'trans_volume_unit_p',
          'trans_w_detail',
          'trans_weight_unit_p',
          'trans_n_detail',
          'trans_num_unit_p',
        ].includes(key)
      ) {
        onChangeInput = e => setTransWVNUnitPrice(tableKey, i, key, e.target.value, row);
        pattern = FLOAT_P_6;
      }
      if (key === 'trans_w_detail' || key === 'trans_v_detail') {
        if (!row.trans_type || +row.trans_type === INNER_TRANS) disabled = true;
        const setTransWV = key === 'trans_w_detail' ? setTransWDetail : setTransVDetail;
        return (
          <Input
            data-path={dataPath}
            pattern={!goods.length ? pattern : undefined}
            value={(value || []).join(',')}
            disabled={disabled || goods.length > 1}
            required={required}
            onChange={e => setTransWV(tableKey, i, key, [e.target.value], row)}
          >
            {!disabled && goods.length > 1 && (
              <i className="fn-icon fn-icon-modify input-icon" onClick={() => onTransEditClick(tableKey, i, row)} />
            )}
          </Input>
        );
      }

      if (key === 'dispatch_dr_name') {
        const enumKey = `${tableKey}${i}dispatch_dr`;
        return (
          <Select
            data-path={dataPath}
            value={value}
            data={state[`${enumKey}Enum`] || [{ name: row.dispatch_dr_name, tel: row.dispatch_dr_phone }]}
            header={sugHeaderMap.dispatch_dr}
            map={false}
            showIcon={false}
            compare="name"
            disabled={disabled}
            filter={(keyword, select) => sug(enumKey, 'b_dr_name', keyword, select)}
            onChange={data => setBDeliveryDrName(tableKey, i, data)}
            customValidity={customValidator[key]}
          >
            {isCreate && driverMgrPermission && (
              <i className="fn-icon fn-icon-add-rad input-icon add-mgr" onClick={() => addDriverClick(tableKey, i)} />
            )}
          </Select>
        );
      }
      if (key === 'dispatch_dr_phone') {
        const enumKey = `${tableKey}${i}dispatch_dr`;
        return (
          <Select
            data-path={dataPath}
            value={value}
            data={state[`${enumKey}Enum`] || [{ name: row.dispatch_dr_name, tel: row.dispatch_dr_phone }]}
            header={sugHeaderMap.dispatch_dr}
            map={false}
            compare="tel"
            format="tel"
            disabled={disabled}
            filter={(keyword, select) => sug(enumKey, 'b_dr_name', keyword, select)}
            onChange={data => setBDeliveryDrName(tableKey, i, data)}
            customValidity={customValidator[key]}
          />
        );
      }
      if (key === 'b_tr_num' && state.bTrNumLock) {
        const enumKey = `${tableKey}${i}b_tr_num`;
        return (
          <Select
            data-path={dataPath}
            value={{ tr_num: row.b_tr_num }}
            data={state[`${enumKey}Enum`]}
            header={sugHeaderMap.b_tr_num}
            format="tr_num"
            map={false}
            disabled={disabled}
            filter={(keyword, select) => sug(enumKey, 'b_tr_num', keyword, select)}
            onChange={data => selectSug(tableKey, i, key, data)}
          />
        );
      }
      if (key === 'b_dr_name' && state.bDrInfoLock) {
        const enumKey = `${tableKey}${i}b_dr_name`;
        return (
          <Select
            data-path={dataPath}
            value={{ name: row.b_dr_name, tel: row.b_dr_phone }}
            data={state[`${enumKey}Enum`]}
            header={sugHeaderMap.b_dr_name}
            map={false}
            disabled={disabled}
            filter={(keyword, select) => sug(enumKey, 'b_dr_name', keyword, select)}
            onChange={data => selectSug(tableKey, i, key, data)}
          />
        );
      }
      if (key === 'b_dr_phone' && state.bDrInfoLock) {
        const enumKey = `${tableKey}${i}b_dr_phone`;
        return (
          <Select
            data-path={dataPath}
            value={{ tel: row.b_dr_phone }}
            data={state[`${enumKey}Enum`]}
            header={sugHeaderMap.b_dr_phone}
            format="tel"
            map={false}
            disabled={disabled}
            filter={(keyword, select) => sug(enumKey, 'b_dr_phone', keyword, select)}
            onChange={data => selectSug(tableKey, i, key, data)}
          />
        );
      }
      // 收款人
      if (key === 'b_tr_payee_name') {
        const enumKey = `${tableKey}${i}b_tr_payee_name`;
        return (
          <Select
            data-path={dataPath}
            value={{ name: row.b_tr_payee_name }}
            data={state[`${enumKey}Enum`]}
            header={sugHeaderMap.b_tr_payee_name}
            map={false}
            disabled={disabled}
            filter={(keyword, select) => sug(enumKey, 'b_tr_payee_name', keyword, select)}
            onChange={data => selectSug(tableKey, i, key, data)}
          />
        );
      }
      // 收款人银行卡号
      if (key === 'b_tr_payee_bank_card_num') {
        const enumKey = `${tableKey}${i}b_tr_payee_bank_card_num`;
        return (
          <Select
            data-path={dataPath}
            value={{ name: row.b_tr_payee_bank_card_num }}
            data={state[`${enumKey}Enum`]}
            header={sugHeaderMap.b_tr_payee_bank_card_num}
            map={false}
            disabled={disabled}
            filter={(keyword, select) => sug(enumKey, 'b_tr_payee_bank_card_num', keyword, select)}
            onChange={data => selectSug(tableKey, i, key, data)}
          />
        );
      }
      // 付款方式不为多笔付 禁用所有付款方式输入
      if (transPayKeys[key] && row.trans_pay_mode !== 'pay_multi') disabled = true;

      // 员工中转 禁用中转单号
      if (key === 'trans_order_num' && +row.trans_type === INNER_TRANS) disabled = true;

      // 到付只有外部中转多笔付时可以输入
      if (key === 'trans_pay_arrival') {
        disabled = !(transType === OUTER_TRANS && row.trans_pay_mode === 'pay_multi');
      }
      if (key === 'trans_order_num') pattern = REGULAR;

      if (transPayKeys[key] || transFeeKeys[key]) type = 'Input';

      if (transPayKeys[key]) {
        onChangeInput = e => setTransPayItem(i, key, e.target.value, row);
      }

      if (transFeeKeys[key]) {
        onChangeInput = e => setTransFeeItem(i, key, e.target.value, row);
      }

      if (useJAVAPrice && +value && tableCalcPriceFee[key]) {
        const priceKey = key; // transType === POINT_TRANS && pointTransCalcPriceFee[key] ? `point_${key}` : key
        disabled = disabled || type !== 'Input';
        return (
          <Input
            data-path={dataPath}
            pattern={pattern}
            value={value}
            disabled={disabled}
            required={required}
            onChange={onChangeInput}
          >
            {!!(
              +value &&
              useJAVAPrice &&
              tableCalcPriceFee[key] &&
              (priceSnapshot[priceKey] || !priceSnapshotDelete[priceKey])
            ) && (
              <PriceSnapshotIcon
                className="input-icon"
                data={priceSnapshot[priceKey]}
                fee={priceKey}
                type="yd"
                bizID={odLinkId}
              />
            )}
          </Input>
        );
      }

      switch (type) {
        case 'Input': {
          return (
            <Input
              data-path={dataPath}
              pattern={pattern}
              value={value}
              disabled={disabled}
              required={required}
              onChange={onChangeInput}
            />
          );
        }
        case 'SelectDrop': {
          const enumKey = `${tableKey}${i}${key}`;
          return (
            <DataList
              data-path={dataPath}
              value={value}
              data={state[`${enumKey}Enum`]}
              header={sugHeaderMap[key]}
              onChange={val => onInput(tableKey, i, key, val)}
              disabled={disabled}
              highlight={sugHighlightMap[key]}
              onSelect={data => selectSug(tableKey, i, key, data)}
              customValidity={customValidator[key]}
              filter={sugMap[key] && ((keyword, select) => sug(enumKey, key, keyword, select))}
            />
          );
        }
        case 'AddrSug': {
          return (
            <AddrSug
              data-path={dataPath}
              defaultValue={value}
              disabled={disabled}
              handleSelected={val => set(tableKey, i, { [key]: val })}
              onChange={val => set(tableKey, i, { [key]: val })}
              mapIcon={false}
            />
          );
        }
        case 'DateTime':
          return (
            <DateTimePicker
              showTime
              defaultValue={value}
              disabled={disabled}
              onChange={val => set(tableKey, i, { [key]: val })}
            />
          );
        case 'Link':
          return (value || []).map((img, index) => <a onClick={() => previewImg(img)}>{index + 1}</a>);
        default: {
          return (enums[key] ? getEnumText(enums[key], value) : value) || <span>&nbsp;</span>;
        }
      }
    };

    const renderTh = (item, { tableKey, data }) => {
      const { key } = item;
      if (key === 'trans_settle_money') {
        return (
          <span>
            {item.title}
            <Tips
              title={
                <span>
                  【外部中转】中转返款=运单到付-中转费合计
                  <br />
                  【网点中转&员工中转】中转返款=（现付+到付+月结+欠付+回付+货到打卡+货款扣）中转费-中转费合计；中转付款方式=免费时，中转返款=0
                </span>
              }
            >
              <i className="fn-icon fn-icon-help" />
            </Tips>
          </span>
        );
      }
      if (key === 'trans_pay_arrival') {
        return (
          <span>
            {item.title}
            <Tips
              title={
                <span>
                  【外部中转】中转付款方式=到付时，中转到付=运单到付；中转付款方式=多笔付时，中转到付可编辑
                  <br />
                  【网点中转&员工中转】中转到付=运单到付
                </span>
              }
            >
              <i className="fn-icon fn-icon-help" />
            </Tips>
          </span>
        );
      }
      if (key === 'b_loc_misc_f') {
        return (
          <span>
            {item.title}
            <Tips title="其他费=批次上的（发站其他费+到站其他费）分摊到运单上的值">
              <i className="fn-icon fn-icon-help" />
            </Tips>
          </span>
        );
      }
      if (key === 'total_cost') {
        return (
          <span>
            {item.title}
            <Tips title={totalCostTip}>
              <i className="fn-icon fn-icon-help" />
            </Tips>
          </span>
        );
      }
      if (key === 'od_loc_tr_f') {
        return (
          <span>
            {item.title}
            <Icon iconType="icon-help" tips="发站单票装车费：指运单的“网点”作为批次发站时，产生的单票装车费。" />
          </span>
        );
      }
      if (key === 'od_loc_misc_f') {
        return (
          <span>
            {item.title}
            <Icon iconType="icon-help" tips="发站单票其他费：指运单的“网点”作为批次发站时，产生的单票其他费。" />
          </span>
        );
      }
      if (key === 'od_rmt_unload_f') {
        return (
          <span>
            {item.title}
            <Icon
              iconType="icon-help"
              tips="到站单票卸车费：指运单的“网点”作为批次到站时，产生的单票卸车费。在到车批次里，因为显示的是发站的运单，因此“到站单票卸车费”为空。"
            />
          </span>
        );
      }
      if (key === 'od_rmt_msc_f') {
        return (
          <span>
            {item.title}
            <Icon
              iconType="icon-help"
              tips="到站单票其他费：指运单的“网点”作为批次到战时，产生的单票其他费。在到车批次里，因为显示的是发站的运单，因此“到站单票其他费”为空。"
            />
          </span>
        );
      }
      if (key === 'dispatch_dr_name' && tableKey === 'pointCostInfo') {
        // 送货司机和手机号是否已填
        const isNoDispatchDr = !data[0].dispatch_dr_name || !data[0].dispatch_dr_phone;
        const disableKeys = (data && data[0].otherProps && data[0].otherProps.disable) || [];
        // 是否可编辑
        // 设置了勾选了需要派单才可派单 => (dispatch && needDispatch !== 1)
        const disable = isDetail || (dispatch && needDispatch !== 1) || isNoDispatchDr || disableKeys.includes(key);
        // 10 为未派单， 老数据为undefined, 其他为已派单
        const checked = !(+data[0].dispatch_st === 10 || !data[0].dispatch_st);
        const dataPath = `${tableKeysMap[tableKey]}_dispatch_st_0`;
        const tips =
          // eslint-disable-next-line no-nested-ternary
          dispatch && +needDispatch !== 1 ? needDispatchFlagTip : isNoDispatchDr ? noDispatchDrTip : dispatchDrTip;
        // data-tips 是为了触发checkbox跟新，checkbox shouldcomponentupdate ignore func
        const dispatchF = (
          <Checkbox
            data-path={dataPath}
            data-tips={tips}
            checked={checked}
            disabled={disable}
            onChange={e => page.setTable(tableKey, 0, { dispatch_st: e.target.checked ? 20 : 10 })}
            {...tips}
          />
        );
        return (
          <span>
            {item.title}
            {
              // eslint-disable-next-line no-nested-ternary
              isDetail ? dispatchF : appDispatchPermission ? dispatchF : ''
            }
          </span>
        );
      }
      return item.title;
    };

    const renderTable = (header, data, tableKey, subtract) => (
      <table>
        <thead>
          <tr>
            {subtract && (
              <th className="subtract">
                <Icon iconType="icon-add-rad" onClick={() => add(tableKey)} />
              </th>
            )}
            {tableKey === 'innerRemarkInfo' && state.showInnerRemarkAdd && (
              <th className="subtract">
                <Icon iconType="icon-add-rad" onClick={() => add(tableKey)} />
              </th>
            )}
            {header.map((item, i) => {
              if (item.hidden) return null;
              return (
                <th style={item.style} key={i} onContextMenu={e => showContextMenu(e, tableKey, i)}>
                  {renderTh(item, { tableKey, data, header })}
                  <i className="table-drager" onMouseDown={e => drag(e, tableKey, i)} />
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              {subtract && (
                <td className="subtract">
                  <Icon iconType="icon-minus-rad" onClick={() => minus(tableKey, index)} />
                </td>
              )}
              {tableKey === 'innerRemarkInfo' && (state.showInnerRemarkAdd || state.showInnerRemarkSubtract) && (
                <td className="subtract inner-remark">
                  {state.showInnerRemarkSubtract && (
                    <Icon iconType="icon-modify" onClick={() => edit(tableKey, index, row)} />
                  )}
                  {state.showInnerRemarkSubtract && (
                    <Icon iconType="icon-minus-rad" onClick={() => minus(tableKey, index, row)} />
                  )}
                </td>
              )}
              {header.map((item, i) => !item.hidden && <td key={i}>{renderTd(tableKey, index, row, item)}</td>)}
            </tr>
          ))}
        </tbody>
      </table>
    );

    const renderTab = () => (
      <div className={`${prefixCls} info-tab ${usedFor}`}>
        <Tabs animated={false} onChange={onTabChange}>
          {tableTabs.map(item => {
            const table = tableInfo[item];
            const { dataKey } = table;
            const { name } = table;
            const header = state[table.headerKey];
            const data = state[dataKey];
            const showSubtract = table.showSubtractKey ? state[table.showSubtractKey] : false;
            const dnCom = isDetail && dataKey === 'transInfo' && renderDnComArrInfo();
            return (
              header &&
              !!header.length && (
                <TabPane forceRender={isModify} tab={name} key={dataKey}>
                  {renderTable(header, data, dataKey, showSubtract)}
                  {dnCom}
                </TabPane>
              )
            );
          })}
        </Tabs>
      </div>
    );

    if (+ext.co_pre_st === 1 && isDetail) return null;

    if (showType === 'tab' || usedFor === 'detail') return renderTab();

    return (
      <div className={`${prefixCls} plain ${usedFor}`}>
        <div className={`${isHidden('costInfo')} x3`}>
          {tableTabKeys.point_cost_info && pointCostInfoHeader && !!pointCostInfoHeader.length && (
            <div className="order-card">
              <h3>
                <Icon iconType="icon-carry-out" onClick={() => toggle('costInfo')} />
                支出费用
              </h3>
              <div className="table-wrap">{renderTable(pointCostInfoHeader, pointCostInfo, 'pointCostInfo')}</div>
            </div>
          )}
          {tableTabKeys.std_cost && stdCostHeader && !!stdCostHeader.length && (
            <div className="order-card">
              <h3>
                <Icon iconType="icon-carry-out" onClick={() => toggle('costInfo')} />
                标准费用
              </h3>
              <div className="table-wrap">{renderTable(stdCostHeader, stdCost, 'stdCost')}</div>
            </div>
          )}
          {tableTabKeys.cost_info && costInfoHeader && !!costInfoHeader.length && (
            <div className="order-card">
              <h3>
                <Icon iconType="icon-carry-out" onClick={() => toggle('costInfo')} />
                成本信息
              </h3>
              <div className="table-wrap">{renderTable(costInfoHeader, costInfo, 'costInfo')}</div>
            </div>
          )}
          {tableTabKeys.budget_info && budgetInfoHeader && !!budgetInfoHeader.length && (
            <div className="order-card">
              <h3>
                <Icon iconType="icon-carry-out" onClick={() => toggle('costInfo')} />
                预算费用
              </h3>
              <div className="table-wrap">{renderTable(budgetInfoHeader, budgetInfo, 'budgetInfo')}</div>
            </div>
          )}
        </div>
        {tableTabKeys.tr_info && trInfoHeader && !!trInfoHeader.length && (
          <div className={`order-card full${isHidden('trInfo')}`}>
            <h3>
              <Icon iconType="icon-carry-out" onClick={() => toggle('trInfo')} />
              配载信息
            </h3>
            <div className="table-wrap">{renderTable(trInfoHeader, trInfo, 'trInfo')}</div>
          </div>
        )}
        {tableTabKeys.shuttle_info && shuttleInfoHeader && !!shuttleInfoHeader.length && (
          <div className={`order-card full${isHidden('trInfo')}`}>
            <h3>
              <Icon iconType="icon-carry-out" onClick={() => toggle('shuttleInfo')} />
              短驳信息
            </h3>
            <div className="table-wrap">{renderTable(shuttleInfoHeader, shuttleInfo, 'shuttleInfoHeader')}</div>
          </div>
        )}
        {tableTabKeys.sign_info && signInfoHeader && !!signInfoHeader.length && (
          <div className={`order-card full${isHidden('signInfo')}`}>
            <h3>
              <Icon iconType="icon-carry-out" onClick={() => toggle('signInfo')} />
              签收信息
            </h3>
            <div className="table-wrap"> {renderTable(signInfoHeader, signInfo, 'signInfo')}</div>
          </div>
        )}
        {tableTabKeys.trans_info && transInfoHeader && !!transInfoHeader.length && (
          <div className={`order-card full${isHidden('transInfo')}`}>
            <h3>
              <Icon iconType="icon-carry-out" onClick={() => toggle('transInfo')} />
              中转信息
            </h3>
            <div className="table-wrap">{renderTable(transInfoHeader, transInfo, 'transInfo', showTransSubtract)}</div>
          </div>
        )}
        {tableTabKeys.delivery_info && deliveryInfoHeader && !!deliveryInfoHeader.length && (
          <div className={`order-card full${isHidden('deliveryInfo')}`}>
            <h3>
              <Icon iconType="icon-carry-out" onClick={() => toggle('deliveryInfo')} />
              送货信息
            </h3>
            <div className="table-wrap">{renderTable(deliveryInfoHeader, deliveryInfo, 'deliveryInfo')}</div>
          </div>
        )}
        {tableTabKeys.pickup_info && pickupInfoHeader && !!pickupInfoHeader.length && (
          <div className={`order-card full${isHidden('pickupInfo')}`}>
            <h3>
              <Icon iconType="icon-carry-out" onClick={() => toggle('pickupInfo')} />
              提货信息
            </h3>
            <div className="table-wrap">{renderTable(pickupInfoHeader, pickupInfo, 'pickupInfo')}</div>
          </div>
        )}
      </div>
    );
  };
}

export default renderInfo;
