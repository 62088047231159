/**
 * Created by wangnaihe on 2019/4/28.
 */

import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';
import QRCode from 'qrcode';
import { ButtonIcon, Icon, PureCheckbox } from 'components';
import { fetchApi } from 'utils';
import logo from 'images/logo1.png';
import { prefixCls } from './index.scss';

const getGoodsFieldValue = (goods, i, key) => goods[i] && goods[i][key];

const telMap = { 1: 'telephone', 2: 'phone', 3: 'service_phone', 4: 'complain_phone' };

const disablePrintTip = '您未选择打印模板，无法打印！';

export default class OrderPreview extends PureComponent {
  static propTypes = {
    data: PropTypes.object,
    close: PropTypes.func,
    onClickBtn: PropTypes.func,
  };

  state = { startPoint: {}, arrPoint: {} };

  UNSAFE_componentWillMount() {
    const { arrPoint, startPointId, QRCode: QRCodeURL } = this.props.data;
    if (arrPoint && arrPoint.company_id && +startPointId !== +arrPoint.company_id) {
      fetchApi(
        `Basic/Org/orgInfo?raw=1&req=${JSON.stringify({ company_id: arrPoint.company_id, type: 'Company' })}`,
      ).then(res => this.setState({ arrPoint: res.res.org_info }));
    }
    if (startPointId) {
      fetchApi(`Basic/Org/orgInfo?raw=1&req=${JSON.stringify({ company_id: startPointId, type: 'Company' })}`).then(
        res => {
          this.setState({ startPoint: res.res.org_info });
          if (+startPointId === +arrPoint.company_id) {
            this.setState({ arrPoint: res.res.org_info });
          }
        },
      );
    }
    QRCodeURL && QRCode.toDataURL(QRCodeURL, { margin: 0 }).then(url => this.setState({ QRCodeDataURL: url }));
  }

  onClickBackdrop = e => {
    if (this.wrap === e.target) {
      this.props.close();
    }
  };

  formatCurrency = val => {
    const text = '零壹贰叁肆伍陆柒捌玖';
    const value = Math.abs(Math.floor(+val || 0));
    const str = value.toString().slice(-4).padStart(4, '0');
    const unit = [...'千百拾元'];
    const w = Math.floor(value / 10000);
    const wArr = [...w.toString()];
    const wStr =
      !!w && wArr.map((n, i) => `${text[+n]}${(wArr.length - i < 5 && unit[4 - (wArr.length - i)]) || ''}`).join('');
    return (
      <span className="uppercase">
        <span className="blue">
          {wStr &&
            wStr
              .replace('零百', '零')
              .replace('零拾', '零')
              .replace('零零', '零')
              .replace(/[零|元]+$/, '')}
        </span>
        万
        {unit.map((u, i) => (
          <Fragment key={i}>
            <span className="blue">{value >= Math.pow(10, 3 - i) && text[+str[i]]}</span>
            {u}
          </Fragment>
        ))}
      </span>
    );
  };

  render() {
    const { data, onClickBtn } = this.props;
    const {
      collectPrint,
      collectPrintTypes,
      printItems,
      shortcutsDisplay,
      showSavePrint,
      showSave,
      odLinkId,
      billingDate,
      startInfo,
      arrInfo,
      orderNum,
      corName,
      corMobile,
      corAddrInfo,
      ceeName,
      ceeMobile,
      ceeAddrInfo,
      weightUnit,
      goods,
      coFreightF,
      coPickupF,
      coDeliveryF,
      declaredValue,
      coInsurance,
      coHandlingF,
      coDeliveryFee,
      coPayAdv,
      coMiscF,
      coDelivery,
      totalPrice,
      payModeEnum,
      keyMap,
      deliveryMode,
      deliveryModeEnum,
      receiptN,
      receiptRequire,
      noticeDelivery,
      pickup,
      insure,
      remark,
      orderCreatorName,
    } = data;
    const { startPoint, arrPoint, QRCodeDataURL } = this.state;
    const disablePrint =
      collectPrint &&
      printItems.every(item => collectPrintTypes[item.type] || !item.checked) &&
      collectPrintTypes.order;

    return (
      <div className={`${prefixCls}-backdrop`} ref={r => (this.wrap = r)} onClick={this.onClickBackdrop}>
        <div className={prefixCls}>
          <Icon iconType="icon-close" onClick={this.props.close} />
          <div className="lace">
            {Array.from({ length: 20 }, (item, i) => (
              <div key={i} />
            ))}
          </div>
          <div className="preview-content">
            <div className="order-header">
              <h3>{window.admin_company_info.company_name}</h3>
              <div className="overview">
                <div>
                  <span className="field-name">开单日期：</span>
                  <span className="blue">{billingDate && billingDate.split(' ')[0]}</span>
                </div>
                <div>
                  <span className="field-name">发站：</span>
                  <span className="blue">{startInfo && startInfo.show_val}</span>
                </div>
                <div>
                  <span className="field-name">到站：</span>
                  <span className="blue">{arrInfo && arrInfo.show_val}</span>
                </div>
                <div className="order-num">
                  <span className="field-name">运单号：</span>
                  <span className="red">{orderNum}</span>
                </div>
              </div>
              <div className="op-bar">
                {!odLinkId && showSavePrint && (
                  <ButtonIcon
                    iconType="icon-print-out"
                    data-path="co_save_print"
                    disabled={disablePrint}
                    tips={disablePrint ? disablePrintTip : undefined}
                    onClick={() => onClickBtn('co_save_print')}
                  >
                    保存并打印({shortcutsDisplay.co_save_print})
                  </ButtonIcon>
                )}
                {!odLinkId && showSave && (
                  <ButtonIcon iconType="icon-save" data-path="co_save" onClick={() => onClickBtn('co_save')}>
                    保存({shortcutsDisplay.co_save})
                  </ButtonIcon>
                )}
              </div>
              {QRCodeDataURL && (
                <div className="qr-code">
                  <img src={QRCodeDataURL} />
                  扫一扫查货
                </div>
              )}
            </div>
            <div className="order-body">
              <div className="cmm left">
                <img src={logo} />
                <span className="text1">物流管理系统</span>
                <span className="text2">www.chemanman.com</span>
              </div>
              <div className="order-info">
                <div className="person">
                  <div className="card consignor">
                    <div className="card-header">发货人信息</div>
                    <div className="card-body">
                      <div>
                        <span className="field-name">发货人：</span>
                        <span className="blue">{corName}</span>
                      </div>
                      <div>
                        <span className="field-name">手&nbsp;&nbsp;&nbsp;机：</span>
                        <span className="blue">{corMobile}</span>
                      </div>
                      <div>
                        <span className="field-name">地&nbsp;&nbsp;&nbsp;址：</span>
                        <span className="blue">{corAddrInfo && corAddrInfo.show_val}</span>
                      </div>
                    </div>
                  </div>
                  <div className="card consignee">
                    <div className="card-header">收货人信息</div>
                    <div className="card-body">
                      <div>
                        <span className="field-name">收货人：</span>
                        <span className="blue">{ceeName}</span>
                      </div>
                      <div>
                        <span className="field-name">手&nbsp;&nbsp;&nbsp;机：</span>
                        <span className="blue">{ceeMobile}</span>
                      </div>
                      <div>
                        <span className="field-name">地&nbsp;&nbsp;&nbsp;址：</span>
                        <span className="blue">{ceeAddrInfo && ceeAddrInfo.show_val}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lr-wrap">
                  <div className="left">
                    <div className="card goods">
                      <div className="card-header">货物信息</div>
                      <table className="fn-table-a">
                        <tbody>
                          <tr>
                            <td>货物名称</td>
                            <td>包装</td>
                            <td>件数</td>
                            <td>重量({weightUnit})</td>
                            <td>体积</td>
                          </tr>
                          {Array.from({ length: 3 }, (item, i) => (
                            <tr>
                              <td>{getGoodsFieldValue(goods, i, 'name')}</td>
                              <td>{getGoodsFieldValue(goods, i, 'pkg')}</td>
                              <td>{getGoodsFieldValue(goods, i, 'num')}</td>
                              <td>{getGoodsFieldValue(goods, i, 'weight')}</td>
                              <td>{getGoodsFieldValue(goods, i, 'volume')}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="card extra">
                      <div className="card-header">其他信息</div>
                      <table className="fn-table-a">
                        <tbody>
                          <tr>
                            <td>
                              <div>
                                <span className="field-name">送货方式：</span>
                                <span className="blue field-content">
                                  {deliveryModeEnum &&
                                    (deliveryModeEnum.find(item => item.key === deliveryMode) || {}).name}
                                </span>
                              </div>
                            </td>
                            <td>
                              <div>
                                <span className="field-name">回单：</span>
                                <span className="blue field-content">{receiptN}</span>份
                              </div>
                            </td>
                            <td>
                              <div>
                                <span className="field-name">回单要求：</span>
                                <span className="blue field-content">{receiptRequire}</span>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div>
                                <span className="field-name">上门提货：</span>
                                <span className="field-content">
                                  <PureCheckbox checked={pickup} />
                                  <span className={pickup ? 'blue' : undefined}>是</span>
                                </span>
                              </div>
                            </td>
                            <td>
                              <div>
                                <span className="field-name">等通知放货：</span>
                                <span className="field-content">
                                  <PureCheckbox checked={noticeDelivery} />
                                  <span className={noticeDelivery ? 'blue' : undefined}>是</span>
                                </span>
                              </div>
                            </td>
                            <td>
                              <div>
                                <span className="field-name">运单投保：</span>
                                <span className="field-content">
                                  <PureCheckbox checked={insure} />
                                  <span className={insure ? 'blue' : undefined}>是</span>
                                </span>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="3" className="remark">
                              备注：{remark}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="right">
                    <div className="card fee">
                      <div className="card-header">运费信息</div>
                      <table className="fn-table-a">
                        <tbody>
                          <tr>
                            <td>
                              <div>
                                <span className="field-name">运费：</span>
                                <span className="blue">{coFreightF}</span>元
                              </div>
                            </td>
                            <td>
                              <div>
                                <span className="field-name">提货费：</span>
                                <span className="blue">{coPickupF}</span>元
                              </div>
                            </td>
                            <td>
                              <div>
                                <span className="field-name">送货费：</span>
                                <span className="blue">{coDeliveryF}</span>元
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div>
                                <span className="field-name">声明价值：</span>
                                <span className="blue">{declaredValue}</span>元
                              </div>
                            </td>
                            <td>
                              <div>
                                <span className="field-name">保价费：</span>
                                <span className="blue">{coInsurance}</span>元
                              </div>
                            </td>
                            <td>
                              <div>
                                <span className="field-name">装卸费：</span>
                                <span className="blue">{coHandlingF}</span>元
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div>
                                <span className="field-name">手续费：</span>
                                <span className="blue">{coDeliveryFee}</span>元
                              </div>
                            </td>
                            <td>
                              <div>
                                <span className="field-name">垫付费：</span>
                                <span className="blue">{coPayAdv}</span>元
                              </div>
                            </td>
                            <td>
                              <div>
                                <span className="field-name">其他费：</span>
                                <span className="blue">{coMiscF}</span>元
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="3">
                              <span className="uppercase-fee">
                                <span className="field-name">代收货款：</span>
                                <span className="red">{coDelivery}</span>元{this.formatCurrency(coDelivery)}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="3">
                              <span className="uppercase-fee">
                                <span className="field-name">运费合计：</span>
                                <span className="red">{totalPrice}</span>元{this.formatCurrency(totalPrice)}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="3" className="pay">
                              <span className="field-name">付款方式：</span>
                              {payModeEnum.map(
                                item =>
                                  data[keyMap[item.key]] && (
                                    <span key={item.key}>
                                      {item.name}
                                      <span className="blue">{data[keyMap[item.key]]}</span>元；
                                    </span>
                                  ),
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="sign">
                      <div className="card cor-sign">
                        <div className="card-header">发货人签字</div>
                        <div className="card-body">
                          本运单填写无误，并已仔细阅读背书条款，自愿遵守所有条款(易碎品破损自负)
                          <span className="date">年&nbsp;&nbsp;&nbsp;&nbsp;月&nbsp;&nbsp;&nbsp;&nbsp;日</span>
                        </div>
                      </div>
                      <div className="card cee-sign">
                        <div className="card-header">收货人签字</div>
                        <div className="card-body">
                          <span className="date">年&nbsp;&nbsp;&nbsp;&nbsp;月&nbsp;&nbsp;&nbsp;&nbsp;日</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cmm right">
                <img src={logo} />
                <span className="text1">物流管理系统</span>
              </div>
              <div className="install-tel">
                安装电话<div className="text3">4008275656</div>
                <div className="text4">第二联</div>:客户联
              </div>
            </div>
            <div className="order-footer">
              <div className="tel field-name">
                发站电话：{startPoint[telMap[window.company_setting.query_phone.enalbed]]}
                <br />
                到站电话：{arrPoint[telMap[window.company_setting.query_phone.enalbed]]}
              </div>
              <div className="address field-name">
                发站地址：{startPoint.address && startPoint.address.show_val}
                {startPoint.address_remark} <br />
                到站地址：{arrPoint.address && arrPoint.address.show_val}
                {arrPoint.address_remark}
              </div>
              <div className="creator">
                <span className="field-name">开单员：</span>
                <span className="blue">{orderCreatorName}</span>
              </div>
            </div>
          </div>
          <div className="lace">
            {Array.from({ length: 20 }, (item, i) => (
              <div key={i} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}
