/**
 * Created by wangnaihe on 2017/6/19.
 */
import React from 'react';
import { PureInput as Input, Icon, Select, PopUp } from 'components';
import { showInfo, buildTipMixin, ot } from 'utils';
import { FLOAT, NUMBER, ERROR } from 'constants';
import PriceSnapshotIcon from 'components/commoncomponents/PriceSnapshotIcon';
import DistributeModal from './distribute';
import { required } from '../../tool';
import calcCommission from '../../public/calcCommission';
import { SELECT_COR_SUG } from '../../constant';
import { sugCorCee, formatSugData } from '../../person/tool';
import { convertSugToMemberData, formatVipSug, validateDelivery, isNoService } from './tool';
import { prefixCls } from './index.scss';

const serviceNameMap = { co_delivery: '代收货款', co_delivery_freight: '代收运费' };

const getNoServiceTip = (point, service) =>
  buildTipMixin(
    `目的网点${point.short_name} 不支持${serviceNameMap[service]}业务，如需修改，请联系管理员修改组织架构。`,
    { type: 'info' },
  );

const coDeliveryFeeTip = buildTipMixin(
  <div>
    提示：
    <br />
    请先填写代收货款，才可编辑货款手续费！
  </div>,
  { type: 'info' },
);

function renderCollectTo(page) {
  const showDistributeModal = () => new PopUp(DistributeModal, { page }).show();

  return function () {
    const { store } = page;
    const state = store.getState();
    const {
      commissionSetting,
      maxCoDeliveryFee,
      coDeliveryFeeCalcIcon,
      freeDelivery,
      coDeliveryRatio,
      arrPoint,
      transMode,
      route,
    } = state;
    const { useJAVAPrice, priceSnapshot, priceSnapshotDelete, odLinkId } = state;
    const { coDelivery, coDeliveryShow, coDeliveryRequired, coDeliveryEditable } = state;
    const {
      coDeliveryReleaseDays,
      coDeliveryReleaseDaysShow,
      coDeliveryReleaseDaysRequired,
      coDeliveryReleaseDaysEditable,
    } = state;
    const { coDeliveryReleaseDaysEnum, coDeliveryReleaseDaysSe } = state;
    const { coDeliveryFee, coDeliveryFeeShow, coDeliveryFeeRequired, coDeliveryFeeEditable } = state;
    const { coDeliveryFreight, coDeliveryFreightShow, coDeliveryFreightRequired, coDeliveryFreightEditable } = state;
    const { memberCode, memberCodeShow, memberCodeEditable, memberCodeRequired, memberCodeEnum } = state;
    const { memberCodeType, corHeaders, autoShowDistribute, showDistributeIcon, vipPos, isCMBCOrLSMember } = state;

    const { set } = page;

    const sug = (key, type, keyword, select) => {
      sugCorCee(true, type, keyword, state, select.fetch).then(res => page.setSug(key, formatSugData(res.res)));
    };
    const maxDeliveryFee = val =>
      maxCoDeliveryFee && +val > +maxCoDeliveryFee ? `${ot('货款手续费')}上限${maxCoDeliveryFee}元！` : '';

    const calcCoDeliverFee = () => {
      const coDeliverFeeEle = page.getEle('co_delivery_fee');
      delete coDeliverFeeEle.dataset.isEdited;
      const effectValues = { coDelivery, coDeliveryRatio, arrPoint, transMode, route, coDeliveryReleaseDays };
      const newCoDeliverFee = calcCommission(effectValues, commissionSetting, freeDelivery, maxCoDeliveryFee);
      newCoDeliverFee !== coDeliveryFee && set('coDeliveryFee', newCoDeliverFee ? +newCoDeliverFee.toFixed(2) : '');
    };

    const selectSug = (key, data) => {
      store.dispatch({ type: SELECT_COR_SUG, payload: { data, key } });
    };
    const sugVip = (key, urlKey, keyword, select) => {
      if (keyword.length < 6) return;
      const url = '/Table/Suggest/memberSug';
      const req = { type: state.basicSetting.member_code.checked === 'ls' ? 2 : 1, query: keyword };
      const conf = { method: 'POST', body: { req } };
      select.fetch(url, conf, 'json', { focus: `cor_${urlKey}` }).then(res => page.setSug(key, formatVipSug(res.res)));
    };

    const selectVip = (key, data) => (data ? selectSug(key, data) : set(key, convertSugToMemberData(data)));

    const memberCodeCheck = e => {
      if (!(memberCode && memberCode.member_code)) {
        showInfo(ERROR, `请从下拉里选择${ot('会员号')}`);
      }
    };
    const showCollect = !!(
      showDistributeIcon ||
      coDeliveryShow ||
      coDeliveryReleaseDaysShow ||
      coDeliveryFeeShow ||
      coDeliveryFreightShow ||
      (memberCodeShow && vipPos === 'botm')
    );

    return (
      showCollect && (
        <div className={prefixCls}>
          <h3>代收款{showDistributeIcon && <Icon iconType="icon-pay-way" onClick={showDistributeModal} />}</h3>
          {coDeliveryShow && (
            <div>
              <label className={`fn-label${required(coDeliveryRequired)}`}>{ot('代收货款')}</label>
              <Input
                data-path="co_delivery"
                value={coDelivery}
                required={coDeliveryRequired}
                disabled={!coDeliveryEditable || isNoService(arrPoint, 'co_delivery')}
                onChange={e => set('coDelivery', e.target.value)}
                onBlur={e => autoShowDistribute && +e.target.value && showDistributeModal()}
                customValidity={() => validateDelivery(state)}
                pattern={FLOAT}
                {...(isNoService(arrPoint, 'co_delivery') ? getNoServiceTip(arrPoint, 'co_delivery') : undefined)}
              />
            </div>
          )}
          {coDeliveryReleaseDaysShow && (
            <div>
              <label className={`fn-label${required(coDeliveryReleaseDaysRequired)}`}>{ot('发放天数')}</label>
              {coDeliveryReleaseDaysSe && (
                <Input
                  data-path="co_delivery_release_days"
                  value={coDeliveryReleaseDays}
                  required={coDeliveryReleaseDaysRequired}
                  disabled={!coDeliveryReleaseDaysEditable}
                  onChange={e => set('coDeliveryReleaseDays', e.target.value)}
                  pattern={NUMBER}
                />
              )}
              {!coDeliveryReleaseDaysSe && (
                <Select
                  data-path="co_delivery_release_days"
                  value={coDeliveryReleaseDays}
                  required={coDeliveryReleaseDaysRequired}
                  data={coDeliveryReleaseDaysEnum}
                  disabled={!coDeliveryReleaseDaysEditable}
                  filter={false}
                  clear
                  onChange={val => set('coDeliveryReleaseDays', val)}
                />
              )}
            </div>
          )}
          {coDeliveryFeeShow && (
            <div>
              <label className={`fn-label${required(coDeliveryFeeRequired)}`}>{ot('货款手续费', '手续费')}</label>
              <Input
                data-path="co_delivery_fee"
                value={coDeliveryFee}
                required={coDeliveryFeeRequired}
                disabled={!(coDeliveryFeeEditable && +coDelivery > 0)}
                onChange={e => set('coDeliveryFee', e.target.value)}
                pattern={FLOAT}
                customValidity={maxDeliveryFee}
                {...(+coDelivery > 0 ? undefined : coDeliveryFeeTip)}
              >
                {coDeliveryFeeCalcIcon && (
                  <i className="fn-icon fn-icon-calculate input-icon" onClick={calcCoDeliverFee} />
                )}
                {!!(
                  coDeliveryFee &&
                  useJAVAPrice &&
                  commissionSetting.checked === 'price' &&
                  (priceSnapshot.co_delivery_fee || !priceSnapshotDelete.co_delivery_fee)
                ) && (
                  <PriceSnapshotIcon
                    className="input-icon"
                    data={priceSnapshot.co_delivery_fee}
                    fee="co_delivery_fee"
                    type="yd"
                    bizID={odLinkId}
                  />
                )}
              </Input>
            </div>
          )}
          {coDeliveryFreightShow && (
            <div>
              <label className={`fn-label${required(coDeliveryFreightRequired)}`}>{ot('代收运费')}</label>
              <Input
                data-path="co_delivery_freight"
                value={coDeliveryFreight}
                required={coDeliveryFreightRequired}
                disabled={!coDeliveryFreightEditable || isNoService(arrPoint, 'co_delivery_freight')}
                onChange={e => set('coDeliveryFreight', e.target.value)}
                pattern={FLOAT}
                {...(isNoService(arrPoint, 'co_delivery_freight')
                  ? getNoServiceTip(arrPoint, 'co_delivery_freight')
                  : undefined)}
              />
            </div>
          )}
          {memberCodeShow && vipPos === 'botm' && (
            <div>
              <label className={`fn-label${required(memberCodeRequired)}`}>{ot('会员号')}</label>
              <Select
                data-path="member_code"
                required={memberCodeRequired}
                value={memberCode}
                data={memberCodeEnum}
                disabled={!memberCodeEditable}
                header={corHeaders.member_code}
                compare="id"
                placeholder=""
                format={memberCodeType}
                map={false}
                clear
                onBlur={memberCodeCheck}
                onChange={val => (isCMBCOrLSMember ? selectVip : selectSug)('memberCode', val)}
                filter={(keyword, select) =>
                  (isCMBCOrLSMember ? sugVip : sug)('memberCode', 'member_code', keyword, select)
                }
              />
            </div>
          )}
        </div>
      )
    );
  };
}

export default renderCollectTo;
